import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CURATION_SCORE_OPTIONS, LIMITS, routesList } from "../../../config";
import { fetchData } from "../../../config/service";
import toast from "react-hot-toast";
import { Alert, Loader, Pagination } from "../../../components";
import { Button, Popover, Select, Spin, Tooltip, Typography } from "antd";
import dayjs from "dayjs";
import MakeCallModal from "../../../components/make-call-modal";
import WhatsappTemplateModal from "../../customers/modals/whatsapp-template";
import EmailTemplateModal from "../../../components/email-template-modal";
import DebouncedSelect from "../../../components/debounced-select";

export default function CallRequests() {
  const [loading, setLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [limit, setLimit] = useState(LIMITS[0]);
  const [data, setData] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [statusList, setStatusList] = useState([]);
  const [editionsList, setEditionsList] = useState([]);

  const [filterData, setFilterData] = useState({
    score: null,
    edition: null,
    project: null,
    call_status: 1,
  });

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selected, setSelected] = useState(null);
  const [callStatusFlag, setCallStatusFlag] = useState(false);

  const [projectListLoading, setProjectListLoading] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const [isMakeCallModalOpen, setIsMakeCallModalOpen] = useState(false);
  const [whatsappTemplateModal, setWhatsAppTemplateModal] = useState(false);
  const [emailTemplateModal, setEmailTemplateModal] = useState(false);
  const [loadeMoredata, setLoadeMoredata] = useState(false);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
      setActivePage(1);
    }, 700);

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    // getEditions();
    getStatus();
    getProjectList();
  }, []);

  useEffect(() => {
    getData();

    // window.addEventListener("scroll", handleScroll);

    // return () => {
    //   window.removeEventListener("scroll", handleScroll);
    // };
  }, [limit, activePage, debouncedSearchTerm, filterData]);

  const getStatus = () => {
    fetchData(
      `api/accounts/user-choices/?type=user_status_choices`,
      "get"
    ).then((res) => {
      if (res.success) {
        setStatusList(res["user_status_choices"]);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getEditions = () => {
    fetchData(`api/editions/editions-select/`, "get").then((res) => {
      if (res.success) {
        const data = res.data.map((e) => {
          return { label: e.name, value: e.id };
        });
        setEditionsList(data);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getProjectList = () => {
    let param = "";

    let offset = (activePage - 1) * limit;
    param += `?limit=${100}&offset=${offset}`;

    setProjectListLoading(true);

    fetchData(`api/project/${param}`).then((res) => {
      if (res.success) {
        const data = res?.data.map((e) => {
          return {
            label: e?.name,
            value: e?.id,
          };
        });
        setProjectList(data);
      } else {
        setProjectList([]);
        toast.error(res.message);
      }
    });
  };

  const getData = () => {
    if (!data?.length > 0) {
      setLoading(true);
    }

    // const offsetValue = debouncedSearchTerm ? 0 : (activePage - 1) * limit;
    const offsetValue = (activePage - 1) * limit;

    fetchData(
      `api/call/?limit=${limit}&offset=${offsetValue}&search=${encodeURIComponent(
        debouncedSearchTerm || ""
      )}&score_gt=${filterData?.score || ""}&edition=${
        filterData?.edition || ""
      }&booking__project=${filterData?.project || ""}&call_status=${
        filterData?.call_status || ""
      }`,
      "get"
    ).then((res) => {
      setLoading(false);
      if (res.success) {
        if (debouncedSearchTerm) {
          if (offsetValue >= 10) {
            setData((prevData) => [...prevData, ...res.data]);
          } else {
            setData(res.data);
          }
        } else {
          if (offsetValue >= 10) {
            setData((prevData) => [...prevData, ...res.data]);
          } else {
            setData(res.data);
          }
        }

        // if (debouncedSearchTerm) {
        //   setData(res.data);
        // } else if (!data?.length > 0) {
        //   setData(res.data);
        // } else {
        //   setData((prevData) => {
        //     // Combine previous records with new records
        //     const combinedData = [...(prevData || []), ...(res.data || [])];

        //     // Remove duplicates based on value (ID)
        //     const uniqueData = Array.from(
        //       new Set(combinedData.map((item) => item.id))
        //     ).map((id) => combinedData.find((item) => item.id === id));

        //     return uniqueData;
        //   });
        // }

        setTotalRecords(res.count);
      } else {
        toast.error(res.message);
      }

      setLoading(false);
      setLoadeMoredata(false);
    });
  };

  const setFilterDataValue = (key, value) => {
    const common_data = {
      ...filterData,
      [key]: value,
    };
    setFilterData(common_data);
    setActivePage(1);
  };

  const deleteItem = () => {
    fetchData(`api/call/${selected.id}`, "delete").then((res) => {
      toast.dismiss();
      if (res.success) {
        setLoading(true);
        getData();
        toast.success("Record deleted successfully");
      } else {
        toast.error(res.message);
      }
    });
  };

  const handleCallStatusUpdate = async (id) => {
    const body = {
      call_status: 2,
    };

    fetchData(`api/call/${id}/`, "PATCH", body).then((res) => {
      toast.dismiss();
      if (res.success) {
        setLoading(true);
        getData();
        toast.success("Record updated successfully");
      } else {
        toast.error(res.message);
      }
    });
  };

  const scoreOptions = [
    // { value: 0, name: "0" },
    { value: 1, name: "1" },
    { value: 2, name: "2" },
    { value: 3, name: "3" },
    { value: 3.5, name: "3.5" },
    { value: 4, name: "4" },
    { value: 4.5, name: "4.5" },
    { value: 5, name: "5" },
  ];

  const callStatusOptions = [
    { value: 1, label: "Pending" },
    { value: 2, label: "Request Sent" },
    { value: 3, label: "Call Booked" },
  ];

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    const bottom = Math.abs(scrollHeight - (scrollTop + clientHeight)) < 1.5;

    // console.log(bottom, "bottom");

    if (bottom && !loadeMoredata && data?.length < totalRecords) {
      setLoadeMoredata(true);
      setActivePage((prevPage) => prevPage + 1);
      // setLimit((prev) => prev + 10);
    }
  };

  return (
    <div>
      <div className="row g-5 g-xxl-5">
        <div className="col-xxl-12">
          <p className="mb-1">
            Step 1: <b>Click on Whatsapp Icon</b>
          </p>
          <p className="mb-1">
            Step 2: <b>Paste the template message on Whatsapp</b>
          </p>
          <p className="mb-0">
            Step 3: <b>Come back here and press tick icon</b>
          </p>
          {/* <p><b>Click on Whatsapp Icon</b></p> */}
        </div>
        <div className="col-xxl-12">
          <div className="d-flex flex-wrap gap-4">
            {/* <div class="position-relative ">
                                          <i
                                              class="ki-outline ki-magnifier search-icon fs-2 text-gray-500 position-absolute top-50 translate-middle-y ms-5"></i>
                                          <input type="text" data-kt-table-widget-4="search"
                                              class="form-control text-dark w-250px fs-7 ps-12 all-search"
                                              name="all_search" placeholder="Search Hotel here...">
                                      </div> */}
            <div className="position-relative ">
              <i className="ki-outline ki-magnifier search-icon fs-2 text-gray-500 position-absolute top-50 translate-middle-y ms-5" />
              <input
                type="text"
                data-kt-table-widget-4="search"
                className="form-control text-dark w-250px fs-7 ps-12 all-search"
                name="all_search"
                placeholder="Search customer here..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className="position-relative ">
              <Select
                value={filterData?.score}
                showSearch
                optionFilterProp="label"
                onChange={(value) => setFilterDataValue("score", value)}
                placeholder="Select Score"
                style={{ width: "200px" }}
                options={CURATION_SCORE_OPTIONS}
                // options={scoreOptions.map((h) => ({
                //   label: h.name,
                //   value: h.value,
                // }))}
                allowClear
                onClear={() => {
                  setFilterDataValue("score", null);
                }}
                size="large"
              />
            </div>
            <div className="position-relative ">
              {/* <Select
                value={filterData?.edition}
                showSearch
                optionFilterProp="label"
                onChange={(value) => setFilterDataValue("edition", value)}
                placeholder="Select Edition"
                style={{ width: "200px" }}
                options={editionsList}
                allowClear
                onClear={() => {
                  setFilterDataValue("edition", null);
                }}
                size="large"
              /> */}

              <DebouncedSelect
                apiEndpoint="api/editions/editions-select/"
                value={filterData?.edition}
                onChange={(value) => setFilterDataValue("edition", value)}
                placeholder="Select Edition"
                labelFormatter={(item) => item.name}
                size="large"
                style={{ width: "200px" }}
                allowClear
                onClear={() => {
                  setFilterDataValue("edition", null);
                }}
              />
            </div>
            <div className="position-relative ">
              <Select
                value={filterData?.project}
                showSearch
                optionFilterProp="label"
                onChange={(value) => setFilterDataValue("project", value)}
                placeholder="Select Project"
                style={{ width: "200px" }}
                options={projectList}
                allowClear
                onClear={() => {
                  setFilterDataValue("project", null);
                }}
                size="large"
              />
            </div>
            <div className="position-relative ">
              <Select
                value={filterData?.call_status}
                showSearch
                optionFilterProp="label"
                onChange={(value) => setFilterDataValue("call_status", value)}
                placeholder="Select Call Status"
                style={{ width: "200px" }}
                options={callStatusOptions}
                // allowClear
                // onClear={() => {
                //   setFilterDataValue("call_status", null);
                // }}
                size="large"
              />
            </div>

            {/* <div className="position-relative ">
              <select
                className="form-select fs-7 min-w-150px"
                data-control="select2"
                data-placeholder="Select Category"
              >
                <option />
                <option value={1}>BHX</option>
                <option value={1}>20k - 30k</option>
                <option value={1}>30k - 40k</option>
                <option value={2}>50k - 60k</option>
              </select>
            </div> */}
          </div>
        </div>

        <div
          style={{
            height: "475px", // Fixed height for the scrollable container
            overflowY: "auto",
            marginBottom: "16px",
          }}
          className="row row g-5 g-xxl-5"
          onScroll={handleScroll} // Attach the scroll event handler
        >
          {loading ? (
            <div className="table-responsive">
              <Loader cols={1} width={550} />
            </div>
          ) : (
            <>
              {data?.length > 0 ? (
                data.map((user) => (
                  <div className="col-md-6" key={user.id}>
                    <div className="card">
                      <div className="card-body p-5 d-flex flex-stack">
                        <div className="d-flex align-items-start">
                          <div className="symbol symbol-circle symbol-70px overflow-hidden me-4">
                            {user.user.photo ? (
                              <img src={user.user.photo} alt="" />
                            ) : (
                              <span className="symbol-label fs-2x text-primary">
                                {user.user.first_name.charAt(0)}
                              </span>
                            )}
                          </div>
                          {/* User details */}
                          <div className="d-flex flex-column">
                            <Link
                              to={`${routesList[
                                "customer_details"
                              ].path.replace(":id", user.user.uuid)}`}
                              className="text-gray-800 text-hover-primary mb-1 fw-bolder fs-5 min-w-200px"
                            >
                              {`${user.user.first_name} ${user.user.last_name}`}
                            </Link>
                            <div className="text-gray-600 fs-8">
                              <strong>Phone:</strong>{" "}
                              <a href="#" className="fw-bold">
                                {user.user.phone}
                              </a>
                            </div>
                            <div className="text-gray-600 fs-8">
                              <strong>Attended By:</strong>{" "}
                              {user.attended_by
                                ? `${user.attended_by.first_name} ${user.attended_by.last_name}`
                                : "Not assigned"}
                            </div>
                            <div className="d-flex align-items-center text-gray-600 fs-8">
                              <strong>Edition:</strong>
                              <Typography.Text
                                className="text-gray-600 fs-8 "
                                ellipsis
                                style={{ maxWidth: "170px" }}
                              >
                                {user.edition_name}
                              </Typography.Text>
                            </div>
                            {/* Display User Score */}
                            <div className="text-gray-600 fs-8">
                              <strong>Score:</strong>{" "}
                              <span
                                className={`fw-bold badge ${
                                  user.user.score > 0
                                    ? "badge-success"
                                    : "badge-danger"
                                }`}
                              >
                                {user.user.score
                                  ? user.user.score.toFixed(1)
                                  : "0"}
                              </span>
                            </div>
                            {/* Display Timeline using Day.js */}
                            <div className="text-gray-600 fs-8">
                              <strong>Last Updated:</strong>{" "}
                              <span>
                                {dayjs(user.updated_at).format("DD-MM-YYYY")}{" "}
                                {/* Example: "2 days ago" */}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="text-end mb-10">
                            <div
                              className={`fs-7 fw-semibold badge badge-outline ${
                                user?.call_status == 1
                                  ? "badge-warning"
                                  : user?.call_status == 2
                                  ? "badge-success"
                                  : user?.call_status == 3
                                  ? "badge-primary"
                                  : "badge-light"
                              }`}
                            >
                              {user?.call_status_label}
                            </div>

                            {/* <a
                            href="#"
                            className="btn btn-icon box-shadow-lg border bg-light btn-active-color-primary btn-sm me-3"
                          >
                            <i className="ki-outline ki-eye fs-2 text-gray-800" />
                          </a>
                          <a
                            href="#"
                            className="btn btn-icon box-shadow-lg border bg-light btn-active-color-primary btn-sm"
                          >
                            <i className="ki-outline ki-information-2 fs-2 text-gray-800" />
                          </a> */}
                          </div>
                          <div className="d-flex flex-shrink-0 justify-content-end">
                            <div>
                              {user?.user.phone ? (
                                <Popover
                                  content={
                                    <>
                                      <div>
                                        <Button
                                          type="link"
                                          className="text-dark fw-bold"
                                          onClick={() => {
                                            setWhatsAppTemplateModal(true);
                                            setSelected(user);
                                          }}
                                        >
                                          WhatsApp Template
                                        </Button>
                                        <br />
                                        <Button
                                          type="link"
                                          className="text-dark fw-bold"
                                          href={`https://wa.me/${user?.user?.phone}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          Open in WhatsApp
                                        </Button>
                                      </div>
                                    </>
                                  }
                                  trigger="hover"
                                >
                                  <div className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3">
                                    <i
                                      className="bi bi-whatsapp fs-4 text-success"
                                      style={{ cursor: "pointer" }}
                                    />
                                  </div>
                                </Popover>
                              ) : (
                                <div
                                  className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                                  disabled
                                  style={{
                                    cursor: "not-allowed",
                                    opacity: "0.5",
                                  }}
                                >
                                  <i className="bi bi-whatsapp fs-4 text-success" />
                                </div>
                              )}
                            </div>

                            <div className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3">
                              <i
                                className="bi bi-telephone fs-4 text-info"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setSelected(user);
                                  setIsMakeCallModalOpen(true);
                                }}
                              />
                            </div>

                            {user?.user.email && user?.edition ? (
                              <div
                                className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                                onClick={() => {
                                  setEmailTemplateModal(true);
                                  setSelected(user);
                                }}
                              >
                                <i
                                  className="bi bi-envelope fs-2 text-danger"
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                            ) : (
                              <Tooltip title="Please select edition to send mail">
                                {" "}
                                <div
                                  className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                                  disabled
                                  style={{
                                    cursor: "not-allowed",
                                    opacity: "0.5",
                                  }}
                                >
                                  <i className="bi bi-envelope fs-2 text-danger" />
                                </div>
                              </Tooltip>
                            )}

                            <a
                              className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                              onClick={() => {
                                handleCallStatusUpdate(user.id);
                              }}
                              style={{
                                cursor:
                                  user.call_status == 2
                                    ? "not-allowed"
                                    : "pointer",
                              }}
                            >
                              {user.call_status == 2 ? (
                                <Tooltip title="Call Done">
                                  <i className="ki-solid ki-check-circle fs-2 text-success" />
                                </Tooltip>
                              ) : (
                                <Tooltip title="Click to Mark Call Done">
                                  <i className="ki-outline ki-check-circle fs-2 text-success" />
                                </Tooltip>
                              )}
                            </a>
                            <Tooltip title="Click to Delete">
                              <div
                                className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm"
                                onClick={() => {
                                  setShowDeleteModal(true);
                                  setSelected(user);
                                }}
                              >
                                <i className="ki-outline ki-trash fs-2 text-danger" />
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="row g-5 mb-0 gx-md-10">
                  <div className="col-md-12 text-center">
                    <div className="mt-0">
                      <img
                        src="/media/no-data-found.png"
                        className="mw-25"
                        alt="No Data Found"
                      />
                    </div>
                    <h1 className="mt-4">No Data Found</h1>
                  </div>
                </div>
              )}

              {loadeMoredata && (
                <div style={{ textAlign: "center", padding: "20px" }}>
                  <Spin />
                </div>
              )}
              {data?.length > 0 && (
                <>
                  {data?.length >= totalRecords && !loadeMoredata && (
                    <div className="fs-1 text-center text-muted mt-">
                      You've reached the end of the list
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>

      <Alert
        show={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
        }}
        // onSuccess={() => {
        //   getData();
        // }}
        onAction={() => {
          toast.loading("Please wait...");
          deleteItem();
        }}
      />

      {isMakeCallModalOpen && (
        <MakeCallModal
          show={isMakeCallModalOpen}
          onHide={() => {
            setIsMakeCallModalOpen(false);
          }}
          data={{
            to_number: selected?.user?.phone,
          }}
        />
      )}

      {whatsappTemplateModal && (
        <WhatsappTemplateModal
          show={whatsappTemplateModal}
          onHide={() => {
            setWhatsAppTemplateModal(false);
          }}
          data={{
            user_data: selected?.user,
          }}
        />
      )}

      {emailTemplateModal && (
        <EmailTemplateModal
          show={emailTemplateModal}
          onHide={() => {
            setEmailTemplateModal(false);
          }}
          data={{
            user_data: selected?.user,
            edition: selected?.edition,
          }}
        />
      )}
    </div>
  );
}
