import React, { useEffect, useState } from "react";
import { Spin, Button, Tooltip, message, Modal } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { fetchData } from "../../../../config/service";
import PolicyModal from "../lib/policy-modal";
import toast from "react-hot-toast";

export default function CancellationPolicy(props) {
  // const { loading, policy, fetchPolicy, setLoading } = props;
  const [policy, setPolicy] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedModalData, setSelectedModalData] = useState(null);

  useEffect(() => {
    fetchPolicy();
  }, []);

  const fetchPolicy = async () => {
    setLoading(true);
    fetchData("api/policy/policies?policy_choices=2")
      .then((res) => {
        if (res.success) {
          console.log(res, "res");
          if (res["0"]) {
            setPolicy(res["0"]);
          } else {
            setPolicy({});
          }
        } else {
          message.error(res.message);
        }
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };
  const handleEdit = () => {
    // message.info("Edit functionality coming soon!");
    setSelectedModalData(policy);
    setShowModal(true);
  };

  const handleDelete = async () => {
    toast.loading("Please Wait");
    fetchData(`api/policy/policies/${policy?.id}`, "delete").then((res) => {
      if (res.success) {
        toast.success(res.message || "Record Deleted Succefully");
        fetchPolicy();
      } else {
        toast.error(res.message);
      }
    });
  };

  if (loading) {
    return <Spin size="large" className="d-block mx-auto mt-5" />;
  }

  if (!policy) {
    return (
      <p className="text-danger text-center mt-4">
        No cancellation policy available.
      </p>
    );
  }

  return (
    <div className="mb-20 pb-lg-20 position-relative p-4 rounded">
      {/* Edit & Delete Buttons */}
      <div className="position-absolute top-0 end-0 mt-2 me-3">
        {Object.keys(policy).length > 0 &&
        policy["cancellation_policy"].length > 0 ? (
          <>
            <div>
              <Tooltip title="Edit">
                <Button
                  type="text"
                  icon={<EditOutlined className="text-primary fs-4" />}
                  onClick={handleEdit}
                />
              </Tooltip>
              <Tooltip title="Delete">
                <Button
                  type="text"
                  icon={<DeleteOutlined className="text-danger fs-4" />}
                  onClick={handleDelete}
                />
              </Tooltip>
            </div>
          </>
        ) : (
          <>
            <div>
              <Tooltip title="Add">
                <Button
                  type="text"
                  icon={<PlusCircleOutlined className="text-primary fs-4" />}
                  onClick={() => {
                    setShowModal(true);
                  }}
                  // disabled={true}
                />
              </Tooltip>
            </div>
          </>
        )}
      </div>

      {!(
        Object.keys(policy).length > 0 &&
        policy["cancellation_policy"].length > 0
      ) && (
        <>
          <p className="fw-semibold text-gray-800 text-center fs-2">
            No policy available. Please add a cancellation policy.
          </p>
        </>
      )}

      {/* Cancellation Policy */}
      {policy.cancellation_policy && policy.cancellation_policy.length > 0 && (
        <>
          <h3 className="fw-semibold text-gray-800">Cancellation Policy:</h3>
          <ul className="fs-6 fw-normal text-gray-700 mb-4">
            {policy.cancellation_policy.map((term, index) => (
              <li key={index} className="mb-2">
                {term}
              </li>
            ))}
          </ul>
        </>
      )}

      <PolicyModal
        show={showModal}
        data={selectedModalData}
        handleClose={() => setShowModal(false)}
        onSuccess={() => {
          fetchPolicy();
        }}
        type="cancellation"
      />
    </div>
  );
}
