import React, { useEffect, useState } from "react";
import { LIMITS, routesList } from "../../../config";
import { fetchData } from "../../../config/service";
import toast from "react-hot-toast";
import { Loader, Pagination } from "../../../components";
import ChangeEditionBookingStatusModal from "./modals/status";
import moment from "moment";
import { DatePicker, Select } from "antd";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import DebouncedSelect from "../../../components/debounced-select";

export default function ChangeEdition() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [limit, setLimit] = useState(LIMITS[0]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [statusModal, setStatusModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [filterData, setFilterData] = useState({
    start_date: dayjs().subtract(30, "days").format("YYYY-MM-DD"),
    end_date: dayjs().endOf("month").format("YYYY-MM-DD"),
    project: "",
    status: "",
    moved_from: "",
    moved_to: "",
  });
  const [editionsStatusOptions, setEditionsStatusOptions] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);
 

  useEffect(() => {
    getData();
  }, [filterData]);

  useEffect(() => {
    getEditionsStatus();
    getProjectOptions();
  }, []);

  const getData = () => {
    setLoading(true);
    fetchData(
      `api/booking/send-request-to-change-booking/?limit=${limit}&offset=${
        (activePage - 1) * limit
      }&start_date=${filterData?.start_date || ""}&end_date=${
        filterData?.end_date || ""
      }&project=${filterData?.project}&status=${
        filterData?.status || ""
      }&moved_from_edition=${filterData?.moved_from || ""}&moved_to_edition=${
        filterData?.moved_to || ""
      }
      `,
      "get"
    ).then((res) => {
      setLoading(false);
      if (res.success) {
        setData(res.data);
        setTotalRecords(res.count);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getEditionsStatus = () => {
    let param = "";

    let offset = (activePage - 1) * limit;
    param += `?limit=${limit}&offset=${offset}`;

    fetchData(`api/editions/editions-choices/`).then((res) => {
      if (res.success) {
        const data = res["edition_status_choices"]?.map((e) => {
          return {
            label: e?.display_name,
            value: e?.value,
          };
        });
        setEditionsStatusOptions(data);
      } else {
        setEditionsStatusOptions([]);
        toast.error(res.message);
      }
    });
  };

  const getProjectOptions = () => {
    let param = "";

    let offset = (activePage - 1) * limit;
    param += `?limit=${100}&offset=${offset}`;

    fetchData(`api/project/${param}`).then((res) => {
      if (res.success) {
        const data = res?.data.map((e) => {
          return {
            label: e?.name,
            value: e?.id,
          };
        });
        setProjectOptions(data);
      } else {
        setProjectOptions([]);
        toast.error(res.message);
      }
    });
  };

  const rangePresets = [
    {
      label: "Last 7 Days",
      value: [dayjs().subtract(7, "days"), dayjs()],
    },
    {
      label: "Last 30 Days",
      value: [dayjs().subtract(30, "days"), dayjs().endOf("month")],
    },
    {
      label: "Last 90 Days",
      value: [dayjs().subtract(90, "days"), dayjs()],
    },
    {
      label: "Last 1 Year",
      value: [dayjs().subtract(1, "year"), dayjs()],
    },
  ];

  return (
    <div className="row g-5 g-xxl-10">
      <div className="col-xxl-12">
        {/*begin::Tables Widget 9*/}
        <div className="card card-xxl-stretch mb-5 mb-xl-8">
          {/*begin::Header*/}
          <div className="card-header">
            <div className="d-flex flex-stack flex-wrap gap-4">
              <div className="position-relative ">
                <DatePicker.RangePicker
                  value={[
                    dayjs(filterData.start_date, "YYYY-MM-DD"),
                    dayjs(filterData.end_date, "YYYY-MM-DD"),
                  ]}
                  presets={rangePresets}
                  format="DD-MM-YYYY"
                  onChange={(date, dateString) => {
                    // console.log(date, dateString, "date");

                    const utcDateString1 =
                      date && date[0]
                        ? date[0].format("YYYY-MM-DD")
                        : dayjs().subtract(30, "days").format("YYYY-MM-DD");

                    const utcDateString2 =
                      date && date[1]
                        ? date[1].format("YYYY-MM-DD")
                        : dayjs().endOf("month").format("YYYY-MM-DD");

                    setFilterData((prevData) => ({
                      ...prevData,
                      start_date: utcDateString1,
                      end_date: utcDateString2,
                    }));
                  }}
                  style={{ width: "300px" }}
                  size="large"
                />
              </div>

              <Select
                showSearch
                name="project"
                value={filterData?.project || null}
                onChange={(value) => {
                  setFilterData((prevState) => ({
                    ...prevState,
                    project: value,
                  }));
                }}
                placeholder="Select Project"
                className="fs-7 w-150px"
                style={{ width: "100%" }}
                dropdownStyle={{ zIndex: 1100 }}
                size="large"
                options={projectOptions}
                allowClear
                onClear={() =>
                  setFilterData((prevState) => ({
                    ...prevState,
                    project: "",
                  }))
                }
              ></Select>

              <Select
                showSearch
                name="status"
                value={filterData?.status || null}
                onChange={(value) => {
                  setFilterData((prevState) => ({
                    ...prevState,
                    status: value,
                  }));
                }}
                placeholder="Select Status"
                className="fs-8 me-3 w-150px"
                dropdownStyle={{ zIndex: 1100 }}
                size="large"
                options={editionsStatusOptions}
                allowClear
                onClear={() =>
                  setFilterData((prevState) => ({
                    ...prevState,
                    status: "",
                  }))
                }
              ></Select>

              <DebouncedSelect
                apiEndpoint="api/editions/editions-select/"
                value={filterData?.moved_from || null}
                onChange={(value) => {
                  setFilterData((prevState) => ({
                    ...prevState,
                    moved_from: value,
                  }));
                }}
                placeholder="Select Moved From Edition"
                labelFormatter={(item) => item.ip}
                size="large"
                style={{ width: "200px" }}
                allowClear
                onClear={() => {
                  setFilterData((prevState) => ({
                    ...prevState,
                    moved_from: "",
                  }));
                }}
              />

              <DebouncedSelect
                apiEndpoint="api/editions/editions-select/"
                value={filterData?.moved_to || null}
                onChange={(value) => {
                  setFilterData((prevState) => ({
                    ...prevState,
                    moved_to: value,
                  }));
                }}
                placeholder="Select Moved To Edition"
                labelFormatter={(item) => item.ip}
                size="large"
                style={{ width: "200px" }}
                allowClear
                onClear={() => {
                  setFilterData((prevState) => ({
                    ...prevState,
                    moved_to: "",
                  }));
                }}
              />
            </div>
            <div className="card-toolbar"></div>
          </div>
          {/*end::Header*/}
          {/*begin::Body*/}
          <div className="card-body py-2 pb-7">
            {loading ? (
              <div className="table-responsive">
                <Loader cols={7} width={250} />
              </div>
            ) : (
              <div>
                {data?.length > 0 ? (
                  <>
                    <div className="table-responsive tbl-sales">
                      {/*begin::Table*/}
                      <table
                        className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 border-top-d"
                        id
                      >
                        <thead>
                          <tr className="fw-bolder text-gray-900 bg-light-primary">
                            {/* <th class="min-w-75px">Sr. No.</th> */}
                            <th className="min-w-125px">User</th>
                            <th className="min-w-70px">Days</th>
                            <th className="min-w-75px">Paid On</th>
                            <th className="min-w-100px">Edition Changed on</th>
                            <th className="min-w-100px">Old Edition</th>
                            <th className="min-w-100px">New Edition</th>
                            <th className="min-w-75px">Moved By</th>
                            <th className="min-w-75px">Status</th>
                            <th className="min-w-50px">Action</th>
                          </tr>
                        </thead>
                        <tbody className="fw-semibold fs-7">
                          {data.map((item, index) => (
                            <tr key={item.id}>
                              <td>
                                <div className="d-flex align-items-center">
                                  <div className="symbol symbol-circle symbol-40px overflow-hidden me-3">
                                    <img
                                      src={
                                        item.user.photo || "/media/blank.png"
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <div className="d-flex flex-column">
                                    <Link
                                      to={`${routesList[
                                        "customer_details"
                                      ].path.replace(":id", item.user.uuid)}`}
                                      className="text-gray-800 text-hover-primary mb-1 fw-bolder fs-6 min-w-125px"
                                    >
                                      {`${item.user.first_name} ${item.user.last_name}`}
                                    </Link>
                                  </div>
                                </div>
                              </td>
                              <td>{item.days}</td>{" "}
                              {/* Assuming "Days" is static or calculated elsewhere */}
                              <td>
                                {item.paid_on
                                  ? moment(item.paid_on).format("DD MMM YYYY")
                                  : "N/A"}
                              </td>
                              <td>
                                <span className="text-orange fw-bold">
                                  {moment(item.updated_at).format(
                                    "DD MMM YYYY"
                                  )}{" "}
                                </span>
                              </td>
                              <td>{item.old_edition.name}</td>
                              <td>
                                <span className="text-primary fw-bolder">
                                  {item.new_edition.name}
                                </span>
                              </td>
                              <td>{item.moved_by || "N/A"}</td>
                              <td>
                                <span
                                  className={`badge py-3 px-4 fs-7 badge-light-${
                                    [2, 4, 5].includes(item?.status)
                                      ? "success"
                                      : item?.status == 1
                                      ? "warning"
                                      : "danger"
                                  }`}
                                >
                                  {item.status_label}
                                </span>
                              </td>
                              <td>
                                <div className="d-flex flex-shrink-0">
                                  <div
                                    className={`btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3 ${
                                      [2, 4, 5].includes(item?.status)
                                        ? "disabled"
                                        : ""
                                    }`}
                                    onClick={() => {
                                      if (![2, 4, 5].includes(item?.status)) {
                                        setStatusModal(!statusModal);
                                        setModalData(item);
                                      }
                                    }}
                                    style={{
                                      pointerEvents: [2, 4, 5].includes(
                                        item?.status
                                      )
                                        ? "none"
                                        : "auto",
                                    }}
                                  >
                                    <i className="ki-outline ki-pencil fs-2 text-dark" />
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {/*end::Table*/}
                    </div>

                    <div className="separator separator-dashed border-gray-200" />
                    <Pagination
                      setLimit={setLimit}
                      limit={limit}
                      data={data}
                      activePage={activePage}
                      totalRecords={totalRecords}
                      onChange={(pageNumber) => {
                        setLoading(true);
                        setActivePage(pageNumber);
                      }}
                    />
                  </>
                ) : (
                  <div className="row g-5 mb-0 gx-md-10">
                    <div className="col-md-12 text-center">
                      <div className="mt-0">
                        <img
                          src="/media/no-data-found.png"
                          className="mw-25"
                          alt="No Data Found"
                        />
                      </div>
                      <h1 className="mt-4">No Data Found</h1>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          {/*begin::Body*/}
        </div>
        {/*end::Tables Widget 9*/}
      </div>
      {statusModal && (
        <ChangeEditionBookingStatusModal
          show={statusModal}
          onHide={() => {
            setStatusModal(false);
            setLoading(true);
            getData();
          }}
          data={modalData}
        />
      )}
    </div>
  );
}
