import React, { useEffect, useRef, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { MenuItem } from "../components";
import { routesList } from "../config";
import MobileMenuItem from "../components/MobileMenuItem";
import { useDispatch, useSelector } from "react-redux";
import { handleLogout } from "../redux/slice";

const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  const [isOpenSidebar, setOpenSidebar] = useState(false);
  // console.log(location.pathname);
  const [currentPathname, setCurrentPathname] = useState("");

  const menuItemRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const [transform, setTransform] = useState({ x: 0, y: 0 });
  const [activeKey, setActiveKey] = useState(null);

  useEffect(() => {
    handleMouseLeave();
    setCurrentPathname(location.pathname);
    setOpenSidebar(false);
    if (location.pathname?.split("/")?.[1]) {
      location.pathname?.split("/")?.[1] === "manage"
        ? setActiveKey("0")
        : location.pathname?.split("/")?.[1] === "customers"
        ? setActiveKey("1")
        : location.pathname?.split("/")?.[1] === "bookings"
        ? setActiveKey("2")
        : location.pathname?.split("/")?.[1] === "settings"
        ? setActiveKey("3")
        : setActiveKey(null);
    } else {
      setActiveKey("-1");
    }
  }, [location]);

  useEffect(() => {
    if (isOpenSidebar) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [isOpenSidebar]);

  const handleMouseEnter = () => {
    const menuItem = menuItemRef.current;
    if (menuItem) {
      const { left, bottom } = menuItem.getBoundingClientRect();
      setTransform({ x: left, y: bottom });
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  // const activeTab = Object.values
  const getNameByPath = (targetPath) => {
    for (const key in routesList) {
      const route = routesList[key];

      if (route.path.includes(":")) {
        // Create a regular expression to match dynamic segments
        const regex = new RegExp(`^${route.path.replace(/:\w+/g, "[^/]+")}$`); // Match any non-slash characters for dynamic segments
        if (regex.test(targetPath)) {
          return route.name;
        }
      } else if (route.path === targetPath) {
        // Match static paths directly
        return route.name;
      }
    }

    return null; // Return null if no match is found
  };

  // Usage
  const activeTab = getNameByPath(location.pathname);

  const onLogout = () => {
    navigate("/login");
    dispatch(handleLogout());
  };

  // console.log(user, "user");
  return (
    <div>
      <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
        {/*begin::Page*/}
        <div
          className="app-page flex-column flex-column-fluid"
          id="kt_app_page"
        >
          {/*begin::Header*/}
          <div id="kt_app_header" className="app-header">
            {/*begin::Header container*/}
            <div
              className="app-container container-xxl d-flex align-items-stretch justify-content-between"
              id="kt_app_header_container"
            >
              {/*begin::Header mobile toggle*/}
              <div
                className="d-flex align-items-center d-lg-none ms-n2 me-2"
                title="Show sidebar menu"
              >
                <div
                  className="btn btn-icon btn-active-color-primary w-35px h-35px active"
                  id="kt_app_header_menu_toggle"
                  onClick={() => setOpenSidebar(true)}
                >
                  <i className="ki-outline ki-abstract-14 fs-2" />
                </div>
              </div>
              {/*end::Header mobile toggle*/}
              {/*begin::Logo*/}
              <div className="d-none d-lg-flex align-items-center justify-content-start me-lg-13">
                <Link
                  to={routesList["dashboard"].path}
                  className="text-dark d-flex align-items-center "
                >
                  <img
                    alt="Logo"
                    src="/media/icon-bhx.png"
                    className="h-20px h-lg-50px theme-light-show "
                  />
                  {/* <img alt="Logo" src="/media/icon-bhx.png" class="h-20px h-lg-50px theme-dark-show" /> */}
                  <span className="text-gray-800 ms-2 fs-1 fw-bold d-none d-md-block">
                    Experience Co.
                  </span>
                </Link>
              </div>
              {/*end::Logo*/}
              {/*begin::Header wrapper*/}
              <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
                {/*begin::Menu wrapper*/}
                <div className="d-flex align-items-stretch">
                  {/*begin::Menu holder drawer drawer-start drawer-on*/}
                  <div
                    className={`app-header-menu app-header-mobile-drawer align-items-stretch ${
                      isOpenSidebar
                        ? "drawer drawer-start drawer-on fadeInSidebar"
                        : ""
                    } `}
                    style={{ width: isOpenSidebar ? "250px" : "" }}
                  >
                    {/*begin::Menu*/}
                    <div className="menu menu-rounded menu-column menu-lg-row menu-active-bg menu-title-gray-600 menu-state-gray-900 menu-arrow-gray-500 fw-semibold fw-semibold fs-6 align-items-stretch my-5 my-lg-0 px-2 px-lg-0">
                      {isOpenSidebar ? (
                        <MobileMenuItem
                          routesList={routesList}
                          pathname={currentPathname}
                          activeKey={activeKey}
                          setActiveKey={setActiveKey}
                        />
                      ) : (
                        <>
                          {/* Dashboard page */}
                          {[1, 2, 3, 4, 6, 10].includes(user?.user_type) && (
                            <div
                              className={`menu-item  me-0 me-lg-2 ${
                                location.pathname ===
                                  routesList["dashboard"].path && "here"
                              } `}
                            >
                              <Link
                                to={routesList["dashboard"].path}
                                className="menu-link "
                              >
                                <span className="menu-icon me-0 mx-0">
                                  <i className="ki-outline ki-home" />
                                </span>
                              </Link>
                            </div>
                          )}

                          {/* Editions Page */}
                          {[1, 2, 3, 4, 6, 8, 9, 10].includes(
                            user?.user_type
                          ) && (
                            <MenuItem
                              iconClass="ki-outline ki-abstract-26"
                              title="Manage Editions"
                              includes="/manage"
                            >
                              <div>
                                <div className="menu-item">
                                  <Link
                                    className="menu-link"
                                    to={routesList["projects"].path}
                                  >
                                    <span className="menu-icon">
                                      <i className="ki-outline ki-cube-3 fs-1" />
                                    </span>
                                    <span
                                      className={`menu-title ${
                                        routesList?.["projects"]?.path ===
                                        currentPathname
                                          ? "text-primary "
                                          : "text-gray-700"
                                      }`}
                                    >
                                      Projects
                                    </span>
                                  </Link>
                                </div>

                                <div className="menu-item">
                                  <Link
                                    className="menu-link"
                                    to={routesList["manage_editions"].path}
                                  >
                                    <span className="menu-icon">
                                      <i className="ki-outline ki-abstract-26 fs-1" />
                                    </span>
                                    <span
                                      className={`menu-title ${
                                        routesList?.["manage_editions"]
                                          ?.path === currentPathname ||
                                        currentPathname.includes(
                                          "/manage/editions/handle"
                                        )
                                          ? "text-primary "
                                          : "text-gray-700"
                                      }`}
                                    >
                                      Manage Edition
                                    </span>
                                  </Link>
                                </div>

                                <div className="menu-item">
                                  <Link
                                    className="menu-link"
                                    to={routesList["manage_addons"].path}
                                  >
                                    <span className="menu-icon">
                                      <i className="ki-outline ki-element-plus fs-1" />
                                    </span>
                                    <span
                                      className={`menu-title ${
                                        routesList?.["manage_addons"]?.path ===
                                        currentPathname
                                          ? "text-primary "
                                          : "text-gray-700"
                                      }`}
                                    >
                                      Manage Add-ons
                                    </span>
                                  </Link>
                                </div>

                                <div className="menu-item">
                                  <Link
                                    className="menu-link"
                                    to={routesList["manage_hotels"].path}
                                  >
                                    <span className="menu-icon">
                                      <i className="ki-outline ki-delivery-door fs-1" />
                                    </span>
                                    <span
                                      className={`menu-title ${
                                        routesList?.["manage_hotels"]?.path ===
                                        currentPathname
                                          ? "text-primary "
                                          : "text-gray-700"
                                      }`}
                                    >
                                      Manage Hotels
                                    </span>
                                  </Link>
                                </div>

                                <div className="menu-item">
                                  <Link
                                    className="menu-link"
                                    to={routesList["manage_destinations"].path}
                                  >
                                    <span className="menu-icon">
                                      <i className="ki-outline ki-geolocation-home fs-1" />
                                    </span>
                                    <span
                                      className={`menu-title ${
                                        routesList?.["manage_destinations"]
                                          ?.path === currentPathname
                                          ? "text-primary "
                                          : "text-gray-700"
                                      }`}
                                    >
                                      Manage Destinations
                                    </span>
                                  </Link>
                                </div>

                                {/* <div className="menu-item">
                                  <Link
                                    className="menu-link"
                                    to={routesList["manage_experiences"].path}
                                  >
                                    <span className="menu-icon">
                                      <i className="ki-outline ki-notepad-bookmark fs-1" />
                                    </span>
                                    <span
                                      className={`menu-title ${
                                        routesList?.["manage_experiences"]
                                          ?.path === currentPathname
                                          ? "text-primary "
                                          : "text-gray-700"
                                      }`}
                                    >
                                      Addon Experiences
                                    </span>
                                  </Link>
                                </div> */}
                              </div>
                            </MenuItem>
                          )}

                          {/* Users Page */}
                          {[1, 2, 3, 4, 6, 10].includes(user.user_type) && (
                            <MenuItem
                              iconClass="ki-outline ki-calendar-tick"
                              title="Users"
                              includes={"/customers"}
                            >
                              <div>
                                <div className="menu-item">
                                  <Link
                                    className="menu-link"
                                    to={routesList["customers"].path}
                                  >
                                    <span className="menu-icon">
                                      <i className="ki-outline ki-calendar-tick fs-1" />
                                    </span>
                                    <span
                                      className={`menu-title ${
                                        routesList?.["customers"]?.path ===
                                          currentPathname ||
                                        currentPathname.includes(
                                          "/customers/details"
                                        )
                                          ? "text-primary "
                                          : "text-gray-700"
                                      }`}
                                    >
                                      Users
                                    </span>
                                  </Link>
                                </div>

                                <div className="menu-item">
                                  <Link
                                    className="menu-link"
                                    to={routesList["curation_central"].path}
                                  >
                                    <span className="menu-icon">
                                      <i className="ki-outline ki-scroll fs-1" />
                                    </span>
                                    <span
                                      className={`menu-title ${
                                        routesList?.["curation_central"]
                                          ?.path === currentPathname
                                          ? "text-primary "
                                          : "text-gray-700"
                                      }`}
                                    >
                                      Curation Central
                                    </span>
                                  </Link>
                                </div>
                              </div>
                            </MenuItem>
                          )}

                          {/* <div
                            className={`menu-item menu-lg-down-accordion menu-sub-lg-down-indention me-0 me-lg-2 ${
                              (location.pathname ===
                                routesList["customers"].path ||
                                location.pathname.startsWith(
                                  "/customers/details/"
                                ) ||
                                location.pathname.startsWith(
                                  "/curation-central/"
                                )) &&
                              "here"
                            }`}
                          >
                            <Link
                              to={routesList["customers"].path}
                              className="menu-link"
                            >
                              <span className="menu-icon">
                                <i className="ki-outline ki-profile-user" />
                              </span>
                              <span className={`menu-title `}>Customers</span>
                              <span className="menu-arrow d-lg-none" />
                            </Link>
                          </div> */}

                          {/* Booking page  */}
                          {[1, 2, 3, 4, 6, 8, 10].includes(user.user_type) && (
                            <MenuItem
                              iconClass="ki-outline ki-calendar-tick"
                              title="Bookings"
                              includes="/bookings"
                            >
                              <div>
                                {/* Show all menu items for non-payments-only users */}
                                {![8, 9].includes(user.user_type) && (
                                  <div className="menu-item">
                                    <Link
                                      className="menu-link"
                                      to={routesList["bookings"].path}
                                    >
                                      <span className="menu-icon">
                                        <i className="ki-outline ki-calendar-tick fs-1" />
                                      </span>
                                      <span
                                        className={`menu-title ${
                                          routesList?.["bookings"]?.path ===
                                            currentPathname ||
                                          currentPathname.includes(
                                            "/bookings/bookings-detail-page"
                                          )
                                            ? "text-primary "
                                            : "text-gray-700"
                                        }`}
                                      >
                                        Bookings
                                      </span>
                                    </Link>
                                  </div>
                                )}

                                {/* Payments menu item visible to all valid user types */}
                                {}
                                <div className="menu-item">
                                  <Link
                                    className="menu-link"
                                    to={routesList["payments"].path}
                                  >
                                    <span className="menu-icon">
                                      <i className="ki-outline ki-cheque fs-1" />
                                    </span>
                                    <span
                                      className={`menu-title ${
                                        routesList?.["payments"]?.path ===
                                        currentPathname
                                          ? "text-primary "
                                          : "text-gray-700"
                                      }`}
                                    >
                                      Payments
                                    </span>
                                  </Link>
                                </div>

                                {/* Rest of the menu items only visible to non-payments-only users */}
                                {![8, 9].includes(user.user_type) && (
                                  <>
                                    <div className="menu-item">
                                      <Link
                                        className="menu-link"
                                        to={routesList["call_requests"].path}
                                      >
                                        <span className="menu-icon">
                                          <i className="ki-outline ki-address-book fs-1" />
                                        </span>
                                        <span
                                          className={`menu-title ${
                                            routesList?.["call_requests"]
                                              ?.path === currentPathname
                                              ? "text-primary "
                                              : "text-gray-700"
                                          }`}
                                        >
                                          Call Requests
                                        </span>
                                      </Link>
                                    </div>

                                    <div className="menu-item">
                                      <Link
                                        className="menu-link"
                                        to={routesList["call_recordings"].path}
                                      >
                                        <span className="menu-icon">
                                          <i className="ki-outline ki-call fs-1" />
                                        </span>
                                        <span
                                          className={`menu-title ${
                                            routesList?.["call_recordings"]
                                              ?.path === currentPathname
                                              ? "text-primary "
                                              : "text-gray-700"
                                          }`}
                                        >
                                          Call Recordings
                                        </span>
                                      </Link>
                                    </div>

                                    <div className="menu-item">
                                      <Link
                                        className="menu-link"
                                        to={routesList["visa_center"].path}
                                      >
                                        <span className="menu-icon">
                                          <i className="ki-outline ki-airplane fs-1" />
                                        </span>
                                        <span
                                          className={`menu-title ${
                                            routesList?.["visa_center"]
                                              ?.path === currentPathname
                                              ? "text-primary "
                                              : "text-gray-700"
                                          }`}
                                        >
                                          Visa Center
                                        </span>
                                      </Link>
                                    </div>

                                    <div className="menu-item">
                                      <Link
                                        className="menu-link"
                                        to={routesList["onboarding_calls"].path}
                                      >
                                        <span className="menu-icon">
                                          <i className="ki-outline ki-call fs-1" />
                                        </span>
                                        <span
                                          className={`menu-title ${
                                            routesList?.["onboarding_calls"]
                                              ?.path === currentPathname
                                              ? "text-primary "
                                              : "text-gray-700"
                                          }`}
                                        >
                                          Onboarding calls
                                        </span>
                                      </Link>
                                    </div>

                                    <div className="menu-item">
                                      <Link
                                        className="menu-link"
                                        to={routesList["room_manager"].path}
                                      >
                                        <span className="menu-icon">
                                          <i className="ki-outline ki-security-user fs-1" />
                                        </span>
                                        <span
                                          className={`menu-title ${
                                            routesList?.["room_manager"]
                                              ?.path === currentPathname
                                              ? "text-primary "
                                              : "text-gray-700"
                                          }`}
                                        >
                                          Room Manager
                                        </span>
                                      </Link>
                                    </div>

                                    <div className="menu-item">
                                      <Link
                                        className="menu-link"
                                        to={routesList["change_edition"].path}
                                      >
                                        <span className="menu-icon">
                                          <i className="ki-outline ki-update-file fs-1" />
                                        </span>
                                        <span
                                          className={`menu-title ${
                                            routesList?.["change_edition"]
                                              ?.path === currentPathname
                                              ? "text-primary "
                                              : "text-gray-700"
                                          }`}
                                        >
                                          Change Edition
                                        </span>
                                      </Link>
                                    </div>
                                  </>
                                )}
                              </div>
                            </MenuItem>
                          )}

                          {/* Events (tribe) Page */}
                          {[1, 2, 3, 4, 6, 10].includes(user?.user_type) && (
                            <div
                              className={`menu-item  mx-0 mx-lg-0 ${
                                location.pathname ===
                                  routesList["tribe"].path && "here"
                              } `}
                            >
                              <Link
                                to={routesList["tribe"].path}
                                className="menu-link"
                              >
                                <span className="menu-icon me-2 mx-0">
                                  <i className="ki-outline ki-people" />{" "}
                                </span>
                                <span className="menu-title">Events</span>
                              </Link>
                            </div>
                          )}

                          {/* Setting(more) Page */}
                          {[1, 2, 3, 4, 6, 10].includes(user?.user_type) && (
                            <MenuItem
                              iconClass="ki-outline ki-setting-2"
                              title="More"
                              includes={"settings"}
                            >
                              <div>
                                {/* <div className="menu-item">
                                 <Link
                                   className="menu-link"
                                   to={routesList["settings"].path}
                                 >
                                   <span className="menu-icon">
                                     <i className="ki-outline ki-setting-2 fs-1" />
                                   </span>
                                   <span
                                     className={`menu-title ${
                                       routesList?.["settings"]?.path ===
                                       currentPathname
                                         ? "text-primary "
                                         : "text-gray-700"
                                     }`}
                                   >
                                     Settings
                                   </span>
                                 </Link>
                               </div> */}

                                {user?.user_type == 1 && (
                                  <>
                                    <div className="menu-item">
                                      <Link
                                        className="menu-link"
                                        to={routesList["manage_employee"].path}
                                      >
                                        <span className="menu-icon">
                                          <i className="ki-outline ki-people fs-1" />
                                        </span>
                                        <span
                                          className={`menu-title ${
                                            routesList?.["manage_employee"]
                                              ?.path === currentPathname
                                              ? "text-primary "
                                              : "text-gray-700"
                                          }`}
                                        >
                                          Manage Team
                                        </span>
                                      </Link>
                                    </div>
                                  </>
                                )}

                                {/* <div className="menu-item">
                                 <Link
                                   className="menu-link"
                                   to={routesList["project_settings"].path}
                                 >
                                   <span className="menu-icon">
                                     <i className="ki-outline ki-faceid fs-1" />
                                   </span>
                                   <span
                                     className={`menu-title ${
                                       routesList?.["project_settings"]?.path ===
                                       currentPathname
                                         ? "text-primary "
                                         : "text-gray-700"
                                     }`}
                                   >
                                     Project Settings
                                   </span>
                                 </Link>
                               </div> */}

                                <div className="menu-item">
                                  <Link
                                    className="menu-link"
                                    to={routesList["change_password"].path}
                                  >
                                    <span className="menu-icon">
                                      <i className="ki-outline ki-faceid fs-1" />
                                    </span>
                                    <span
                                      className={`menu-title ${
                                        routesList?.["change_password"]
                                          ?.path === currentPathname
                                          ? "text-primary "
                                          : "text-gray-700"
                                      }`}
                                    >
                                      Change Password
                                    </span>
                                  </Link>
                                </div>

                                <div className="menu-item">
                                  <Link
                                    className="menu-link"
                                    to="/settings/visa_guideline"
                                  >
                                    <span className="menu-icon">
                                      {" "}
                                      <i className="ki-outline ki-notepad fs-1"></i>
                                    </span>
                                    <span
                                      className={`menu-title ${
                                        routesList?.["visa_guideline"]?.path ===
                                        currentPathname
                                          ? "text-primary "
                                          : "text-gray-700"
                                      }`}
                                    >
                                      Visa Guidelines
                                    </span>
                                  </Link>
                                </div>

                                {/* <div className="menu-item">
                                  <Link
                                    className="menu-link"
                                    to="/settings/support_tickets"
                                  >
                                    <span className="menu-icon">
                                      {" "}
                                      <i class="ki-outline ki-book fs-1"></i>
                                    </span>
                                    <span
                                      className={`menu-title ${
                                        routesList?.["support_tickets"]
                                          ?.path === currentPathname
                                          ? "text-primary "
                                          : "text-gray-700"
                                      }`}
                                    >
                                      Support Tickets
                                    </span>
                                  </Link>
                                </div> */}

                                <div className="menu-item">
                                  <Link
                                    className="menu-link"
                                    to="/settings/faqs"
                                  >
                                    <span className="menu-icon">
                                      <i className="ki-outline ki-message-question fs-1" />
                                    </span>
                                    <span
                                      className={`menu-title ${
                                        routesList?.["faqs"]?.path ===
                                        currentPathname
                                          ? "text-primary "
                                          : "text-gray-700"
                                      }`}
                                    >
                                      FAQs
                                    </span>
                                  </Link>
                                </div>

                                {/* {(user?.user_type == 5 ||
                                 user?.user_type == 3) && (
                                 <>
                                   <div className="menu-item">
                                     <Link
                                       className="menu-link"
                                       to="/settings/business-metrics"
                                     >
                                       <span className="menu-icon">
                                         <i className="ki-outline ki-graph-up fs-1" />
                                       </span>
                                       <span
                                         className={`menu-title ${
                                           routesList?.["business_metrics"]
                                             ?.path === currentPathname
                                             ? "text-primary "
                                             : "text-gray-700"
                                         }`}
                                       >
                                         Business Metrics
                                       </span>
                                     </Link>
                                   </div>
                                 </>
                               )} */}

                                {user?.user_type !== 10 && (
                                  <>
                                    <div className="menu-item">
                                      <Link
                                        className="menu-link"
                                        to="/settings/business-metrics"
                                      >
                                        <span className="menu-icon">
                                          <i className="ki-outline ki-graph-up fs-1" />
                                        </span>
                                        <span
                                          className={`menu-title ${
                                            routesList?.["business_metrics"]
                                              ?.path === currentPathname
                                              ? "text-primary "
                                              : "text-gray-700"
                                          }`}
                                        >
                                          Business Metrics
                                        </span>
                                      </Link>
                                    </div>

                                    <div className="menu-item">
                                      <Link
                                        className="menu-link"
                                        to="/settings/ad-metrics"
                                      >
                                        <span className="menu-icon">
                                          <i className="ki-outline ki-chart-pie-4 fs-1" />
                                        </span>
                                        <span
                                          className={`menu-title ${
                                            routesList?.["ad_metrics"]?.path ===
                                            currentPathname
                                              ? "text-primary "
                                              : "text-gray-700"
                                          }`}
                                        >
                                          Ad Metrics
                                        </span>
                                      </Link>
                                    </div>
                                  </>
                                )}

                                {/* {(user?.user_type == 5 ||
                                 user?.user_type == 6) && (
                                 <>
                                   <div className="menu-item">
                                     <Link
                                       className="menu-link"
                                       to="/settings/ad-metrics"
                                     >
                                       <span className="menu-icon">
                                         <i className="ki-outline ki-chart-pie-4 fs-1" />
                                       </span>
                                       <span
                                         className={`menu-title ${
                                           routesList?.["ad_metrics"]?.path ===
                                           currentPathname
                                             ? "text-primary "
                                             : "text-gray-700"
                                         }`}
                                       >
                                         Ad Metrics
                                       </span>
                                     </Link>
                                   </div>
                                 </>
                               )} */}

                                <div className="menu-item">
                                  <Link
                                    className="menu-link"
                                    to="/settings/feedback/:id"
                                  >
                                    <span className="menu-icon">
                                      <i className="ki-outline ki-sms fs-1" />
                                    </span>
                                    <span
                                      className={`menu-title ${
                                        routesList?.["feedback"]?.path ===
                                        currentPathname
                                          ? "text-primary "
                                          : "text-gray-700"
                                      }`}
                                    >
                                      Feedback
                                    </span>
                                  </Link>
                                </div>

                                <div className="menu-item">
                                  <Link
                                    className="menu-link"
                                    to="/settings/hot-leads"
                                  >
                                    <span className="menu-icon">
                                      <i class="ki-outline ki-user-square fs-1 "></i>
                                    </span>
                                    <span
                                      className={`menu-title ${
                                        routesList?.["hot_leads"]?.path ===
                                        currentPathname
                                          ? "text-primary "
                                          : "text-gray-700"
                                      }`}
                                    >
                                      Hot Leads
                                    </span>
                                  </Link>
                                </div>

                                <div className="menu-item">
                                  <Link
                                    className="menu-link"
                                    to="/settings/crm"
                                  >
                                    <span className="menu-icon">
                                      <i class="ki-outline ki-badge fs-1 "></i>
                                    </span>
                                    <span
                                      className={`menu-title ${
                                        routesList?.["crm"]?.path ===
                                        currentPathname
                                          ? "text-primary "
                                          : "text-gray-700"
                                      }`}
                                    >
                                      CRM
                                    </span>
                                  </Link>
                                </div>

                                <div className="menu-item">
                                  <Link className="menu-link" to="settings/utm">
                                    <span className="menu-icon">
                                      <i className="ki-outline ki-social-media  fs-1" />
                                    </span>
                                    <span
                                      className={`menu-title ${
                                        routesList?.["utm"]?.path ===
                                        currentPathname
                                          ? "text-primary "
                                          : "text-gray-700"
                                      }`}
                                    >
                                      UTM
                                    </span>
                                  </Link>
                                </div>

                                <div className="menu-item">
                                  <Link
                                    className="menu-link"
                                    to="/settings/policy"
                                  >
                                    <span className="menu-icon">
                                      <i className="ki-outline ki-file-left fs-1" />
                                    </span>
                                    <span
                                      className={`menu-title ${
                                        routesList?.["refund_policy"]?.path ===
                                        currentPathname
                                          ? "text-primary "
                                          : "text-gray-700"
                                      }`}
                                    >
                                      Policy
                                    </span>
                                  </Link>
                                </div>

                                {/* <div className="menu-item">
                                  <Link
                                    className="menu-link"
                                    to="/settings/refund-policy"
                                  >
                                    <span className="menu-icon">
                                      <i className="ki-outline ki-file-left fs-1" />
                                    </span>
                                    <span
                                      className={`menu-title ${
                                        routesList?.["refund_policy"]?.path ===
                                        currentPathname
                                          ? "text-primary "
                                          : "text-gray-700"
                                      }`}
                                    >
                                      Refund Policy
                                    </span>
                                  </Link>
                                </div>

                                <div className="menu-item">
                                  <Link
                                    className="menu-link"
                                    to="/settings/cancellation-policy"
                                  >
                                    <span className="menu-icon">
                                      <i className="ki-outline ki-file-deleted fs-1" />
                                    </span>
                                    <span
                                      className={`menu-title ${
                                        routesList?.["cancellation_policy"]
                                          ?.path === currentPathname
                                          ? "text-primary "
                                          : "text-gray-700"
                                      }`}
                                    >
                                      Cancellation Policy
                                    </span>
                                  </Link>
                                </div> */}
                              </div>
                            </MenuItem>
                          )}
                        </>
                      )}
                    </div>
                    {/*end::Menu*/}
                  </div>
                  {/*end::Menu holder*/}
                </div>
                {/*end::Menu wrapper*/}
                {/*begin::Navbar*/}
                <div className="app-navbar flex-shrink-0 position-relative">
                  {/*begin::User menu*/}
                  <div
                    className="app-navbar-item"
                    id="kt_header_user_menu_toggle"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    ref={menuItemRef}
                  >
                    {/*begin::Menu wrapper*/}
                    <div
                      className={`d-flex align-items-center border border-dashed border-gray-300 rounded p-2 ${
                        isHovered ? "show menu-dropdown" : ""
                      }`}
                    >
                      {/*begin::User*/}
                      <div className="cursor-pointer symbol me-3 symbol-35px symbol-lg-45px">
                        <img
                          alt="Logo"
                          src={user?.photo ? user.photo : "/media/blank.png"}
                        />
                      </div>
                      {/*end::User*/}
                      {/*begin:dark*/}
                      <div className="me-4">
                        <span className="text-gray-900 text-hover-primary fs-6 fw-bold">
                          {user?.first_name}
                        </span>
                        {/* <a
                          href
                          className="text-gray-500 text-hover-primary fs-7 fw-bold d-block"
                        >
                          @jacobjs
                        </a> */}
                      </div>
                      {/*end:Info*/}
                      <i className="ki-outline ki-down fs-2 text-gray-500 pt-1" />
                    </div>
                    {/*begin::User account menu*/}
                    <div
                      className={`menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px ${
                        isHovered ? "show" : ""
                      }`}
                      style={{
                        zIndex: 107,
                        // position: "fixed",
                        position: "absolute",
                        top: 95,
                        right: 0,
                        // inset: "0px 0px auto auto",
                        // margin: "0px",
                        // transform: `translate3d(${transform.x}px, ${transform.y}px, 0px)`,
                      }}
                    >
                      {/*begin::Menu item*/}
                      <div className="menu-item px-3">
                        <div className="menu-content d-flex align-items-center px-3">
                          {/*begin::Avatar*/}
                          <div className="symbol symbol-50px me-5">
                            <img
                              alt="Logo"
                              src={
                                user?.photo ? user.photo : "/media/blank.png"
                              }
                            />
                          </div>
                          {/*end::Avatar*/}
                          {/*begin::Username*/}
                          <div className="d-flex flex-column">
                            <div className="fw-bold d-flex align-items-center fs-5">
                              {user?.first_name}
                            </div>
                            <span
                              // href="#"
                              className="fw-semibold text-muted text-hover-primary fs-7"
                            >
                              {user?.phone}
                            </span>
                          </div>
                          {/*end::Username*/}
                        </div>
                      </div>
                      {/*end::Menu item*/}
                      {/*begin::Menu separator*/}
                      <div className="separator my-2" />
                      {/*end::Menu separator*/}
                      {/*begin::Menu item*/}
                      <div className="menu-item px-5 mb-3">
                        <Link
                          to={routesList["my_profile"].path}
                          className="menu-link px-5"
                        >
                          My Profile
                        </Link>
                      </div>
                      {/*end::Menu item*/}
                      {/*begin::Menu separator*/}
                      {/*end::Menu item*/}
                      {/*begin::Menu item*/}
                      <div className="menu-item px-5">
                        <span
                          onClick={onLogout}
                          className="menu-link px-5 bg-primary text-gray-100 justify-content-center cursor-pointer"
                        >
                          Sign Out
                        </span>
                      </div>
                      {/*end::Menu item*/}
                    </div>
                    {/*end::User account menu*/}
                    {/*end::Menu wrapper*/}
                  </div>
                  {/*end::User menu*/}
                  {/*begin::Sidebar menu toggle*/}
                  {/*end::Sidebar menu toggle*/}
                </div>
                {/*end::Navbar*/}
              </div>
              {/*end::Header wrapper*/}
            </div>
            {/*end::Header container*/}
          </div>
          {/*end::Header*/}
          {/*begin::Wrapper*/}
          <div
            className="app-wrapper flex-column flex-row-fluid"
            id="kt_app_wrapper"
          >
            {/*begin::Toolbar*/}
            <div
              id="kt_app_toolbar"
              className="app-toolbar pt-4 pt-lg-7 mb-n2 mb-lg-n3"
            >
              {/*begin::Toolbar container*/}
              <div
                id="kt_app_toolbar_container"
                className="app-container container-xxl d-flex flex-stack flex-row-fluid"
              >
                {/*begin::Toolbar container*/}
                <div className="d-flex flex-stack flex-row-fluid ">
                  {/*begin::Toolbar container*/}
                  <div className="d-flex flex-column flex-row-fluid">
                    {/*begin::Toolbar wrapper*/}
                    {/*begin::Breadcrumb*/}
                    <ul className="breadcrumb breadcrumb-separatorless fw-semibold mb-1 mb-lg-3 me-2 fs-7">
                      {/*begin::Item*/}
                      <li className="breadcrumb-item text-gray-700 fw-bold lh-1">
                        <Link
                          to={routesList["dashboard"].path}
                          className=" text-hover-primary"
                        >
                          Home
                        </Link>
                      </li>
                      {/*end::Item*/}
                      {/*begin::Item*/}
                      <li className="breadcrumb-item">
                        <i className="ki-outline ki-right fs-7 text-gray-700 mx-n1" />
                      </li>
                      {/*end::Item*/}
                      {/*begin::Item*/}
                      <li className="breadcrumb-item text-gray-700 fw-bold lh-1">
                        {activeTab}
                      </li>
                      {/*end::Item*/}
                    </ul>
                    {/*end::Breadcrumb*/}
                    {/*begin::Page title*/}
                    <div className="page-title d-flex align-items-center me-3">
                      {/*begin::Title*/}
                      <h1 className="page-heading d-flex text-gray-900 fw-bold fs-1 flex-column justify-content-center my-0">
                        {activeTab}
                      </h1>
                      {/*end::Title*/}
                    </div>
                    {/*end::Page title*/}
                  </div>
                  {/*end::Toolbar container*/}
                  {/*begin::Actions*/}
                  <div className="d-flex align-items-center gap-3">
                    {/*begin::Primary button*/}
                    {/* <a href="#" class="btn btn-flex btn-primary px-7" data-bs-toggle="modal" data-bs-target="#kt_modal_invite_friends">
									<i class="ki-outline ki-plus-square fs-2"></i>Add Edition</a> */}
                    {/*end::Primary button*/}
                  </div>
                  {/*end::Actions*/}
                </div>
                {/*end::Toolbar container*/}
              </div>
              {/*end::Toolbar container*/}
            </div>
            {/*end::Toolbar*/}
            {/*begin::Wrapper container*/}
            <div className="app-container container-xxl d-flex">
              {/*begin::Main*/}
              <div
                className="app-main flex-column flex-row-fluid"
                id="kt_app_main"
              >
                {/*begin::Content wrapper*/}{" "}
                {isOpenSidebar && (
                  <div
                    onClick={() => setOpenSidebar(false)}
                    style={{
                      background: "#00000070",
                      position: "absolute",
                      inset: 0,
                      zIndex: "100",
                    }}
                  />
                )}
                <div className="d-flex flex-column flex-column-fluid">
                  {/*begin::Content*/}{" "}
                  <div id="kt_app_content" className="app-content">
                    <Outlet />
                    {/* {children} */}
                  </div>
                  {/*end::Content*/}
                </div>
                {/*end::Content wrapper*/}
                {/*begin::Footer*/}
                <div id="kt_app_footer" className="app-footer pb-3">
                  <div className="app-container container-xxl d-flex flex-column flex-md-row flex-center flex-md-stack">
                    {/*begin::Copyright*/}
                    <div className="text-dark order-2 order-md-1">
                      <span className="fw-semibold me-.0">Copyright © </span>
                      <a
                        href
                        target="_blank"
                        className="text-primary fw-bolder text-hover-primary"
                      >
                        Experience Co.
                      </a>
                      <span className="fw-semibold me-1">
                        {" "}
                        {`${new Date().getFullYear()}. All rights reserved.`}
                      </span>
                    </div>
                    {/*end::Copyright*/}
                    {/*begin::Menu*/}
                    <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
                      <li className="menu-item">
                        <a href target="_blank" className="menu-link px-2">
                          About
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href target="_blank" className="menu-link px-2">
                          Support
                        </a>
                      </li>
                    </ul>
                    {/*end::Menu*/}
                  </div>
                </div>
                {/*end::Footer*/}
              </div>
              {/*end:::Main*/}
            </div>
            {/*end::Wrapper container*/}
          </div>
          {/*end::Wrapper*/}
        </div>
        {/*end::Page*/}
      </div>
    </div>
  );
};

export default Layout;
