import { Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { fetchData } from "../../../../config/service";
import toast from "react-hot-toast";

const PolicyModal = ({ show, handleClose, data, type, onSuccess }) => {
  const [formData, setFormData] = useState({
    refund_terms: [""],
    reschedule_terms: [""],
    other_relevant_terms: [""],
    cancellation_policy: [""],
  });
  const [sending, setSending] = useState(false);

  useEffect(() => {
    if (show) {
      if (data?.id) {
        // If data has an ID, populate fields based on the type
        if (type === "refund") {
          setFormData({
            refund_terms: data?.refund_terms || [""],
            reschedule_terms: data?.reschedule_terms || [""],
            other_relevant_terms: data?.other_relevant_terms || [""],
          });
        } else if (type === "cancellation") {
          setFormData({
            cancellation_policy: data?.cancellation_policy || [""],
          });
        }
      } else {
        // If no ID, show empty fields for the selected type
        if (type === "refund") {
          setFormData({
            refund_terms: [""],
            reschedule_terms: [""],
            other_relevant_terms: [""],
          });
        } else if (type === "cancellation") {
          setFormData({
            cancellation_policy: [""],
          });
        }
      }
    }
  }, [show, data, type]); // Dependency array updated

  const addNewField = (section) => {
    setFormData({
      ...formData,
      [section]: [...formData[section], ""],
    });
  };

  const handleChange = (section, index, value) => {
    const updatedTerms = [...formData[section]];
    updatedTerms[index] = value;
    setFormData({
      ...formData,
      [section]: updatedTerms,
    });
  };

  const removeField = (section, index) => {
    const updatedTerms = formData[section].filter((_, idx) => idx !== index);
    setFormData({
      ...formData,
      [section]: updatedTerms.length > 0 ? updatedTerms : [""], // Ensure at least one empty field remains
    });
  };

  const handleSubmit = () => {
    const method = data?.id ? "put" : "post";
    const path = data?.id
      ? `api/policy/policies/${data?.id}/`
      : "api/policy/policies/";

    setSending(true);
    fetchData(path, method, {
      ...formData,
      policy_choices: type === "refund" ? 1 : 2,
    }).then((res) => {
      setSending(false);
      if (res.success) {
        toast.success(res.message || "Record Saved Succefully");
        handleClose();
        onSuccess();
      } else {
        toast.error(res.message);
      }
    });
  };

  const renderSection = (title, section) => (
    <div>
      <label className="fs-3 fw-bold mb-4">{title}</label>
      {formData[section].map((term, index) => (
        <div className="row mb-3 align-items-center" key={index}>
          <div className="col-md-11">
            <textarea
              rows={2}
              value={term}
              onChange={(e) => handleChange(section, index, e.target.value)}
              className="form-control"
            />
          </div>
          <div className="col-md-1 d-flex align-items-center mt-8">
            {index === 0 ? (
              <Tooltip title="Add New Field">
                <div
                  className="ms-2 mb-5 cursor-pointer"
                  onClick={() => addNewField(section)}
                >
                  <i className="ki-outline fs-1 ki-plus-square text-success"></i>
                </div>
              </Tooltip>
            ) : (
              <Tooltip title="Remove Field">
                <div
                  className="ms-2 mb-5 cursor-pointer"
                  onClick={() => removeField(section, index)}
                >
                  <i className="ki-outline fs-1 ki-minus-square text-danger"></i>
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <div className="modal-header">
        <h5 className="modal-title">Policy Terms</h5>
        <button
          type="button"
          className="btn-close"
          onClick={handleClose}
          aria-label="Close"
        ></button>
      </div>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="modal-body">
          <div className="container-fluid">
            <div className="row">
              {type === "refund" && (
                <>
                  {renderSection("Refund Terms", "refund_terms")}
                  {renderSection("Reschedule Terms", "reschedule_terms")}
                  {renderSection(
                    "Other Relevant Terms",
                    "other_relevant_terms"
                  )}
                </>
              )}

              {type === "cancellation" && (
                <>
                  {renderSection("Cancellation Policy", "cancellation_policy")}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleClose}
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary fs-7"
            onClick={handleSubmit}
            disabled={sending}
          >
            Save Changes
            {sending && (
              <span className="spinner-border spinner-border-sm align-middle ms-2" />
            )}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default PolicyModal;
