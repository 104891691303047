import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import { fetchData } from "../../../../../config/service";

export default function ContentModal({
  show,
  onHide,
  contentModalDetails,
  setContentModalDetails,
}) {
  const [sending, setSending] = useState(false);
  const [userId, setUserId] = useState("");

  //   useEffect(() => {
  //     if (data) {
  //       setUserId(data?.uuid);
  //       setContentModalDetails({
  //         transport_mode: data?.transport_mode || "",
  //         no_of_breakfast: data?.no_of_breakfast || "",
  //         no_of_meals: data?.no_of_meals || "",
  //       });
  //     }
  //   }, [data]);

  const onSubmitContent = async () => {
    onHide();
    // setSending(true);

    // const body = {
    //   transport_mode: contentModalDetails.transport_mode,
    //   no_of_breakfast: contentModalDetails.no_of_breakfast,
    //   no_of_meals: contentModalDetails.no_of_meals,
    // };

    // fetchData(`api/booking/${userId}/`, "PATCH", body).then((res) => {
    //   setSending(false);
    //   if (res.success) {
    //     onHide();
    //     toast.success("Success");
    //   } else {
    //     toast.error(res.message);
    //   }
    // });
  };

  const setContentModalDetailsValue = (key, value) => {
    setContentModalDetails({
      ...contentModalDetails,
      [key]: value,
    });
  };

  return (
    <Modal show={show} onHide={onHide} size="md" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="fw-bold fs-6 mb-n3">Update Content Details</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">
          <label className="form-label fw-semibold">Transport Mode</label>
          <input
            type="text"
            className="form-control"
            value={contentModalDetails.transport_mode}
            onChange={(e) =>
              setContentModalDetailsValue("transport_mode", e.target.value)
            }
            placeholder="Enter Transport Mode"
          />
        </div>
        <div className="mb-3">
          <label className="form-label fw-semibold">Number of Breakfasts</label>
          <input
            type="number"
            className="form-control"
            value={contentModalDetails.no_of_breakfast}
            onChange={(e) =>
              setContentModalDetailsValue("no_of_breakfast", e.target.value)
            }
            placeholder="Enter Number of Breakfasts"
          />
        </div>
        <div className="mb-3">
          <label className="form-label fw-semibold">Number of Meals</label>
          <input
            type="number"
            className="form-control"
            value={contentModalDetails.no_of_meals}
            onChange={(e) =>
              setContentModalDetailsValue("no_of_meals", e.target.value)
            }
            placeholder="Enter Number of Meals"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-light-danger fs-7"
          onClick={onHide}
        >
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary fs-7"
          onClick={onSubmitContent}
          disabled={sending}
        >
          Submit
          {sending && (
            <span className="spinner-border spinner-border-sm align-middle ms-2" />
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
