import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import { fetchData } from "../../../../config/service";
import { Select } from "antd";

export default function FAQModal({
  show,
  data,
  editionsList,
  onHide,
  onSuccess,
}) {
  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState(true);

  const [faqData, setFaqData] = useState({
    question: "",
    answer: "",
    editions: null,
  });

  useEffect(() => {
    if (data?.id) {
      setFaqData({
        question: data?.question,
        answer: data?.answer,
        editions: data?.editions,
      });
    }
  }, [data]);

  const onSubmitUser = async () => {
    const { editions, ...restOfFaqData } = faqData;

    const method = data?.id ? "put" : "post";
    const path = data?.id ? `api/faqs/${data?.id}/` : "api/faqs/";

    const body = {
      ...restOfFaqData,
      ...(editions != null ? { editions } : {}),
    };

    fetchData(path, method, body).then((res) => {
      toast.remove();
      if (res.success) {
        console.log(res);
        onHide();
        onSuccess();
        toast.success("Success");
      } else {
        toast.error(res.message);
      }
    });
  };

  const setFaqDataValue = (key, value) => {
    const common_data = {
      ...faqData,
      [key]: value,
    };
    setFaqData(common_data);
  };

  return (
    <Modal show={show} onHide={onHide} size="md" centered backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>ADD New FAQs</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row g-5 mb-0 align-items-center">
          <div>
            <label htmlFor="" className=" fs-6 fw-bold mb-2">
              Edition{" "}
              <small className="text-muted">
                (select edition to set FAQ edition wise)
              </small>
            </label>
            <Select
              mode="multiple"
              value={faqData?.editions}
              showSearch
              optionFilterProp="label"
              onChange={(value) => setFaqDataValue("editions", value)}
              placeholder="Select Editions"
              style={{ width: "100%" }}
              options={editionsList}
              dropdownStyle={{ zIndex: 1100 }}
              allowClear
              onClear={() => {
                setFaqDataValue("editions", null);
              }}
              size="large"
            />
          </div>
          <div className="col-md-12 ">
            <label htmlFor="" className=" fs-6 fw-bold mb-2">
              Question
            </label>
            <div className="">
              <input
                type="text"
                className="form-control fs-7"
                value={faqData?.question || ""}
                onChange={(e) => setFaqDataValue("question", e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-12 ">
            <label htmlFor="" className=" fs-6 fw-bold mb-2">
              Answer
            </label>
            <div className="input-group ">
              <textarea
                name=""
                id=""
                cols={30}
                rows={4}
                className="form-control fs-7"
                placeholder="Write here..."
                value={faqData?.answer || ""}
                onChange={(e) => setFaqDataValue("answer", e.target.value)}
              />
            </div>
          </div>
          {/* <div class="col-md-12">
                              <button class="btn btn-light-primary border border-gray-300 fw-bold fs-7"><i class="ki-outline ki-plus-square fs-2"></i> Add</button>
                          </div> */}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-light-danger fs-7"
          onClick={onHide}
        >
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary fs-7"
          onClick={() => {
            toast.loading("Saving...");
            onSubmitUser();
          }}
          disabled={sending}
        >
          Submit
          {sending && (
            <span className="spinner-border spinner-border-sm align-middle ms-2" />
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
