import React, { act, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { routesList } from "../../../config";
import { get } from "jquery";
import { fetchData } from "../../../config/service";
import toast from "react-hot-toast";
import { Select } from "antd";
import DebouncedSelect from "../../../components/debounced-select";
import { Loader } from "../../../components";
import { UserCards } from "./lib/room-manager-card";

export default function RoomManager() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  // Set default active tab to 1
  const [activeTab, setActiveTab] = useState(1);
  const [editionsList, setEditionsList] = useState([]);
  const [selectedEdition, setSelectedEdition] = useState(null);
  const [filterData, setFilterData] = useState({
    edition: null,
  });
  const [roomMangerLoading, setRoomMangerLoading] = useState(false);
  const [roomManagerList, setRoomManagerList] = useState([]);
  const [notesOptions, setNotesOptions] = useState([]);

  useEffect(() => {
    getNotesOptions();
    // getEditions();
  }, []);

  useEffect(() => {
    if (activeTab == 1 && filterData?.edition) {
      getData();
    } else if (filterData?.edition) {
      getRoomMangerList();
    }
  }, [filterData, activeTab]);

  const getNotesOptions = () => {
    fetchData(`api/note/notes-enum/`, "get").then((res) => {
      if (res.success) {
        setNotesOptions(res["note_choices"]);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getEditions = () => {
    fetchData(`api/editions/editions-select/`, "get").then((res) => {
      if (res.success) {
        const data = res.data.map((e) => {
          return { label: e.name, value: e.id };
        });
        // setSelectedEdition(data[0]?.value);
        // setEditionsList(data);
        if (activeTab == 1) {
          getData(data[0]?.value);
        } else {
          getRoomMangerList(data[0]?.value);
        }
      } else {
        toast.error(res.message);
      }
    });
  };

  const getData = (edition) => {
    setLoading(true);
    fetchData(
      `api/room-manager/room-stats/${edition || filterData?.edition}`
    ).then((res) => {
      setLoading(false);
      if (res.success) {
        setData(res);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getRoomMangerList = (edition) => {
    setRoomMangerLoading(true);
    fetchData(
      `api/room-manager/${filterData?.edition || "678a025038dd8d71b553328e"}`
    ).then((res) => {
      setRoomMangerLoading(false);
      if (res.success) {
        setRoomManagerList(res.data);
      } else {
        toast.error(res.message);
      }
    });
  };

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const tabs = [
    { id: 1, title: "Overview" },
    { id: 2, title: "Room Manager" },
  ];

  const genderIcons = {
    Female: "bi-gender-female",
    Male: "bi-gender-male",
    "Queer/Nonconforming": "bi-gender-neuter",
    Trans: "bi-gender-trans",
    "Prefer not to say": "bi-person",
  };

  return (
    <div>
      {/*begin::Followers toolbar*/}
      <div className="d-flex flex-wrap flex-stack  mb-6 ">
        {/*begin::Title*/}
        <ul className="nav nav-tabs nav-pills flex-row border-0 flex-md-row me-5 mb-3 mb-md-0 fs-6 min-w-lg-200px">
          {tabs.map((tab) => (
            <li key={tab.id} className="nav-item me-0 mb-md-0 me-4">
              <div
                className={`nav-link px-10 bg-white btn btn-flex btn-active-primary btn-color-gray-800 btn-active-color-white ${
                  activeTab === tab.id ? "active" : ""
                }`}
                onClick={() => handleTabClick(tab.id)}
              >
                <span className="d-flex flex-column align-items-start">
                  <span className="fs-4 fw-bold">{tab.title}</span>
                </span>
              </div>
            </li>
          ))}
        </ul>
        {/*end::Title*/}
        {/*begin::Controls*/}
        <div className="d-flex my-0 gap-4">
          {/* <Select
            value={selectedEdition || filterData?.edition}
            onChange={(value) => {
              setFilterData((prevData) => ({
                ...prevData,
                edition: value,
              }));
              setSelectedEdition(null);
            }}
            placeholder="Select Edition"
            style={{ width: 200 }}
            options={editionsList}
            size="large"
          /> */}

          <DebouncedSelect
            apiEndpoint="api/editions/editions-select/"
            value={selectedEdition || filterData?.edition}
            onChange={(value) => {
              setFilterData((prevState) => ({
                ...prevState,
                edition: value,
              }));
            }}
            placeholder="Select Edition"
            labelFormatter={(item) => item.ip}
            size="large"
            style={{ width: 200 }}
            selectFirstOption={true} // Enable auto-selection of first option
          />
        </div>
        {/*end::Controls*/}
      </div>
      {/*end::Followers toolbar*/}
      {/*begin:::Tab content*/}
      <div className="tab-content" id="myTabContent">
        {activeTab == 1 && (
          <>
            {/*begin:::Tab pane*/}
            <div
              // className="tab-pane fade show active"
              id="kt_vtab_pane_4"
              role="tabpanel"
              style={{ filter: `${loading ? "blur(5px)" : ""}` }}
            >
              {/*begin::Card*/}
              <div className="card card-flush mb-6 mb-xl-6">
                {/*begin::Card header*/}
                <div className="card-header">
                  {/*begin::Card title*/}
                  <div className="card-title flex-column">
                    <h2 className="mb-1">Overview</h2>
                  </div>
                  {/*end::Card title*/}
                  {/*begin::Card toolbar*/}
                  <div className="card-toolbar"></div>
                  {/*end::Card toolbar*/}
                </div>
                {/*end::Card header*/}
                {/*begin::Card body*/}
                <div className="card-body p-9 pt-2">
                  <div className=" row g-5 gx-10">
                    <div className="col-md-6">
                      <div className="d-flex flex-stack bdr-left-user1 position-relative rounded px-4 py-3 mb-3 bg-light">
                        <div className="d-flex align-items-center me-5">
                          <div className="me-5">
                            <a className="text-gray-800 fw-normal text-hover-primary fs-6">
                              Median Age
                            </a>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <span className="text-gray-800 fw-bold fs-4 me-3">
                            {data?.median_age || 0}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex flex-stack bdr-left-user1 position-relative rounded px-4 py-3 mb-3 bg-light">
                        <div className="d-flex align-items-center me-5">
                          <div className="me-5">
                            <a className="text-gray-800 fw-normal text-hover-primary fs-6">
                              Total Paid
                            </a>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <span className="text-gray-800 fw-bold fs-4 me-3">
                            {data?.total_paid || 0}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex flex-stack bdr-left-user1 position-relative rounded px-4 py-3 mb-3 bg-light">
                        <div className="d-flex align-items-center me-5">
                          <div className="me-5">
                            <a className="text-gray-800 fw-normal text-hover-primary fs-6">
                              Total Full Paid
                            </a>
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <span className="text-gray-800 fw-bold fs-4 me-3">
                            {data?.total_full_paid || 0}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <a className="fw-bold fs-6">
                          Preferences not filled by{" "}
                          {data?.preference_pending || 0} users
                        </a>
                      </div>
                      <div>
                        <a className="fw-bold fs-6">
                          Visa Slip not filled by {data?.visa_pending || 0}{" "}
                          users
                        </a>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="separator separator-dashed my-3" />
                    </div>

                    <div className="col-md-6">
                      {data?.top_priority &&
                      Object.keys(data?.top_priority).length > 0 ? (
                        <>
                          {Object.entries(data?.top_priority).map(
                            ([goal, count]) => (
                              <div
                                key={goal}
                                className="d-flex flex-stack bdr-left-user1 position-relative rounded px-4 py-3 mb-3 bg-light"
                              >
                                <div className="d-flex align-items-center me-5">
                                  <div className="me-5">
                                    <a
                                      href="#"
                                      className="text-gray-800 fw-normal text-hover-primary fs-6"
                                    >
                                      {goal}
                                    </a>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <span className="text-gray-800 fw-bold fs-4 me-3">
                                    {count}
                                  </span>
                                </div>
                              </div>
                            )
                          )}
                        </>
                      ) : (
                        <>
                          <div>No Data Available</div>
                        </>
                      )}
                    </div>

                    <div className="col-md-6">
                      {data?.gender_breakdown &&
                      Object.keys(data?.gender_breakdown).length > 0 ? (
                        <>
                          {Object.entries(data?.gender_breakdown).map(
                            ([gender, count]) => (
                              <div
                                key={gender}
                                className="d-flex flex-stack bdr-left-user1 position-relative rounded px-4 py-3 mb-3 bg-light"
                              >
                                <div className="d-flex align-items-center me-5">
                                  <div className="symbol symbol-30px symbol-circle me-3">
                                    <i
                                      className={`bi ${genderIcons[gender]} text-gray-900 fs-1`}
                                    />
                                  </div>
                                  <div className="me-5">
                                    <a
                                      href="#"
                                      className="text-gray-800 fw-normal text-hover-primary fs-6"
                                    >
                                      {gender}
                                    </a>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <span className="text-gray-800 fw-bold fs-4 me-3">
                                    {count}
                                  </span>
                                </div>
                              </div>
                            )
                          )}
                        </>
                      ) : (
                        <>
                          <div>No Data Available</div>
                        </>
                      )}
                    </div>

                    <div className="col-md-12">
                      <div className="separator separator-dashed my-3" />
                    </div>

                    <div className="row">
                      {data?.preference_breakdown &&
                      Object.keys(data?.preference_breakdown).length > 0 ? (
                        <>
                          {Object.entries(data?.preference_breakdown).map(
                            ([category, breakdown]) => (
                              <div className="col-md-4 my-5" key={category}>
                                {/* Category Title */}
                                <h3 className="fw-bold mb-6">
                                  {category
                                    .replace(/_/g, " ")
                                    .replace(/\b\w/g, (l) => l.toUpperCase())}
                                </h3>
                                <div className="m-0">
                                  {/* Iterate through each breakdown item */}
                                  {Object.entries(breakdown).map(
                                    ([key, value]) => (
                                      <div key={key}>
                                        <div className="d-flex flex-stack">
                                          <div className="d-flex flex-stack flex-row-fluid d-grid gap-2">
                                            <div className="me-5">
                                              <a
                                                href="#"
                                                className="text-gray-700 fw-semibold text-hover-primary fs-6"
                                              >
                                                {key}
                                              </a>
                                            </div>
                                            <div className="d-flex align-items-center">
                                              <span className="fw-bold fs-5 me-3 d-block text-gray-800">
                                                {value} Residents
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="separator separator-dashed my-3" />
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            )
                          )}
                        </>
                      ) : (
                        <div>No data Available</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card card-flush mb-6 mb-xl-9">
                {/*begin::Card header*/}
                <div className="card-header">
                  {/*begin::Card title*/}
                  <div className="card-title flex-column">
                    <h2 className="mb-1">Room Overview</h2>
                  </div>
                  {/*end::Card title*/}
                  {/*begin::Card toolbar*/}
                  <div className="card-toolbar"></div>
                  {/*end::Card toolbar*/}
                </div>
                {/*end::Card header*/}
                {/*begin::Card body*/}
                <div className="card-body p-9 pt-2">
                  <div className=" row g-5 gx-10">
                    <div className="col-md-4 ">
                      <h3 className="fw-bold mb-6">
                        Room - 17 Residents{" "}
                        <a
                          className="ms-3"
                          data-bs-toggle="modal"
                          data-bs-target="#kt_modal_1"
                          href
                        >
                          <i className="ki-outline ki-pencil text-primary fs-4" />
                        </a>
                      </h3>
                      <div className="m-0"></div>
                    </div>
                    <div className="col-md-4 ">
                      <h3 className="fw-bold mb-6">
                        Room 6 - 1 Residents{" "}
                        <a
                          className="ms-3"
                          data-bs-toggle="modal"
                          data-bs-target="#kt_modal_1"
                          href
                        >
                          <i className="ki-outline ki-pencil text-primary fs-4" />
                        </a>
                      </h3>
                      <div className="m-0">
                        <div className="d-flex align-items-center">
                          <div className="symbol symbol-circle symbol-40px overflow-hidden me-3">
                            <img src="/media/blank.png" alt="" />
                          </div>
                          <div className="d-flex flex-column">
                            <a
                              href
                              className="text-gray-800 fs-6 mb-0 fw-bolder"
                            >
                              Kaushik Kumbhani
                            </a>
                          </div>
                        </div>
                        <div className="separator separator-dashed my-3" />
                        <div className="d-flex align-items-center">
                          <div className="symbol symbol-circle symbol-40px overflow-hidden me-3">
                            <img src="/media/blank.png" alt="" />
                          </div>
                          <div className="d-flex flex-column">
                            <a
                              href
                              className="text-gray-800 fs-6 mb-0 fw-bolder"
                            >
                              Jay Ahya
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 ">
                      <h3 className="fw-bold mb-6">
                        Room 20 - 1 Residents{" "}
                        <a
                          className="ms-3"
                          data-bs-toggle="modal"
                          data-bs-target="#kt_modal_1"
                          href
                        >
                          <i className="ki-outline ki-pencil text-primary fs-4" />
                        </a>
                      </h3>
                      <div className="m-0">
                        <div className="d-flex align-items-center">
                          <div className="symbol symbol-circle symbol-40px overflow-hidden me-3">
                            <img src="/media/blank.png" alt="" />
                          </div>
                          <div className="d-flex flex-column">
                            <a
                              href
                              className="text-gray-800 fs-6 mb-0 fw-bolder"
                            >
                              Sanjay Reddy
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*end:::Tab pane*/}
          </>
        )}

        {activeTab == 2 && (
          <>
            {/*begin:::Tab pane*/}
            <div role="tabpanel">
              <div className="d-flex flex-wrap flex-stack mb-6 ">
                {/*begin::Title*/}
                <h3 className="text-gray-800 fw-bold my-0">
                  Room Manager /
                  <span className="fs-6 text-primary fw-semibold ms-1">
                    Showing {roomManagerList?.length || 0} users
                  </span>
                </h3>
                {/*end::Title*/}
                {/*begin::Controls*/}
                <div className="d-flex my-0 gap-4"></div>
                {/*end::Controls*/}
              </div>
              <UserCards
                data={roomManagerList}
                loading={roomMangerLoading}
                notesOptions={notesOptions}
                getRoomMangerList={getRoomMangerList}
              />
              {/* <div className="card card-flush mb-6 mb-xl-6">
              
                <div className="card-body p-4 pt-2">
                 
                  <div className="table-responsive tbl-sales">
                   
                    <table
                      className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 border-top-d"
                      id
                    >
                      <thead>
                        <tr className="fw-bolder text-gray-900 bg-white">
                          <th className="min-w-250px">Name</th>
                          <th className="min-w-80px">Score</th>
                          <th className="min-w-75px">Age</th>
                          <th className="min-w-75px">Designation</th>
                          <th className="min-w-100px">Food</th>
                          <th className="min-w-85px">Drinking</th>
                          <th className="min-w-100px">Smoking</th>
                          <th className="min-w-100px">Sleep</th>
                          <th className="min-w-100px text-end">Room</th>
                        </tr>
                      </thead>
                      <tbody className=" fw-semibold fs-7">
                        <tr className>
                          <td className="bdr-left-user">
                            <div className="d-flex  align-items-center ps-3">
                              <div className="d-flex flex-column">
                                <Link
                                  to={routesList["customer_details"].path}
                                  className="text-primary mb-1 fw-bolder fs-5"
                                >
                                  Sahithi Reddy
                                </Link>
                                <span className="text-gray-600">
                                  Check-in Time: Oct 2, 2023 2:00 pm
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>4</td>
                          <td>29</td>
                          <td>Business Owner</td>
                          <td>&lt; 3 Non Veg</td>
                          <td>I drink ocassionally</td>
                          <td>I smoke ocassionally</td>
                          <td>A Night Watchman</td>
                          <td>
                            <div className="d-flex flex-shrink-0 justify-content-end align-items-center">
                              <i className="ki-outline ki-notification-2 fs-1 text-gray-800" />
                              <a
                                href="#"
                                className="btn btn-icon bg-white btn-active-color-primary btn-sm ms-2"
                                data-bs-toggle="modal"
                                data-bs-target="#kt_modal_add_customer"
                              >
                                <i className="ki-outline ki-pencil fs-2 text-info" />
                              </a>
                             
                              <a
                                href="#"
                                data-bs-toggle="modal"
                                data-bs-target="#kt_modal_download"
                                className="btn p-0 btn-icon  btn-active-color-primary btn-sm"
                              >
                                <img
                                  src="/media/csv.png"
                                  className="h-20px"
                                  alt=""
                                />
                              </a>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  
                  </div>
                 
                  <div className=" rounded p-4 bg-light">
                    <div className>
                      <div className="d-flex flex-stack">
                        <div>
                          <div className>
                            <div className="fw-semibold text-gray-800 fs-7">
                              {" "}
                              Special Request:{" "}
                              <span className="fw-bolder">None</span>
                            </div>
                            <div className="fw-semibold text-gray-800 fs-7">
                              {" "}
                              Primary Agenda:{" "}
                              <span className="fw-bolder">-</span>
                            </div>
                          </div>
                        </div>
                        <div className="fw-semibold text-gray-800 fs-7">
                          Coming for sure
                        </div>
                        <div className="fw-semibold text-gray-800 fs-7">
                          Pune
                        </div>
                        <div className="fw-semibold text-gray-800 fs-7">
                          0-500000
                        </div>
                        <div className="fw-semibold text-gray-800 fs-7">
                          S2: Review
                        </div>
                        <div className="d-flex flex-shrink-0 justify-content-end">
                          <div className="min-w-250px  text-end me-5">
                            <div className="fs-7 text-gray-900 fw-bold">
                              <span className="me-4">See Contact</span>{" "}
                              <span>Passport Details</span>
                            </div>
                            <div className="fs-7 text-gray-600">
                              Remind to Share Preference
                            </div>
                          </div>
                          <a
                            href="#"
                            className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                          >
                            <i className="lab la-linkedin fs-1 text-gray-800" />
                          </a>
                          <a
                            href="#"
                            className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                          >
                            <i className="ki-outline ki-instagram fs-2 text-danger" />
                          </a>
                          <a
                            href="#"
                            className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                          >
                            <i className="ki-outline ki-address-book fs-2 text-primary" />
                          </a>
                          <a
                            href="#"
                            data-bs-toggle="modal"
                            data-bs-target="#kt_modal_2"
                            className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                          >
                            <i className="ki-outline ki-airplane fs-2 text-success" />
                          </a>
                          <a
                            id="kt_drawer_example_toggle"
                            href="#"
                            className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-0"
                          >
                            <i className="ki-outline ki-notepad fs-2 text-gray-800" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </>
        )}
      </div>
      {/*begin::Row*/}
    </div>
  );
}
