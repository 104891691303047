import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import { fetchData } from "../../../config/service";
import { Select } from "antd";

export default function ChangeAmountModal({
  show,
  statusList,
  onHide,
  data,
  onSuccess,
  paymentData,
}) {
  const [sending, setSending] = useState(false);
  const [userId, setUserId] = useState("");
  const [customerData, setCustomerData] = useState({
    payment_type: null,
    payment_for_type: null,
    refund_reason: "",
    amount: null,
  });

  const [paymentForListLoading, setPaymentForListLoading] = useState(false);
  const [paymentForList, setPaymentForList] = useState([]);

  useEffect(() => {
    if (data) {
      getPaymentForList(data?.id);
    }
  }, [data]);

  useEffect(() => {
    if (paymentData?.id) {
      console.log(paymentData, "paymentData");
      setCustomerData({
        ...customerData,
        payment_type: paymentData?.payment_for,
        // payment_for_type: paymentData?.payment_for_type,
        amount: paymentData?.amount,
      });
    }
  }, [paymentData?.id]);

  const getPaymentForList = async (bookingId) => {
    setPaymentForListLoading(true);
    fetchData(
      `api/payment/add-payment-for/?booking_id=${bookingId}`,
      "get"
    ).then((res) => {
      setPaymentForListLoading(false);
      if (res.success) {
        const options = Object.entries(res)
          .filter(([key, value]) => key !== "success")
          .map(([key, value]) => ({
            label: value,
            value: key,
          }));
        setPaymentForList(options);
      } else {
        toast.error(res.message);
      }
    });
  };

  const onSubmitUser = async () => {
    setSending(true);

    const body = {
      booking_id: data?.id,
      amount: customerData.amount,
      payment_type: customerData.payment_type,
      ...(customerData.refund_reason
        ? { refund_reason: customerData.refund_reason }
        : {}),
      ...(paymentData?.id ? { payment_id: paymentData?.id } : {}),
    };

    // console.log(body, "body");

    fetchData(`api/payment/add-refund-payment/`, "post", body).then((res) => {
      setSending(false);
      if (res.success) {
        onHide();
        onSuccess();
        toast.success("Success");
      } else {
        toast.error(res.message);
      }
    });
  };

  const setCustomerDataValue = (key, value) => {
    const common_data = {
      ...customerData,
      [key]: value,
    };

    setCustomerData(common_data);
  };

  return (
    <Modal show={show} onHide={onHide} size="md" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="fw-bold fs-6 mb-n3">
            Add Amount <span className="text-primary"></span>
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row g-9 align-items-center">
          {/* <div className="col-12">
            <label>
              <span className="required fw-semibold fs-5">
                Select Payment Type
              </span>
            </label>

            <Select
              options={[
                {
                  value: "add",
                  label: "Add Payment",
                },
                {
                  value: "refund",
                  label: "Refund Refund",
                },
              ]}
              value={customerData?.payment_type}
              onChange={(e) => setCustomerDataValue("payment_type", e)}
              placeholder="Select Payment Type"
              dropdownStyle={{ zIndex: 1111 }}
              style={{ width: "100%", marginTop: "10px" }}
              size="large"
              // allowClear
              // onClear={() => setCustomerDataValue("payment_type", null)}
            />
          </div> */}

          <div className="col-12">
            <label>
              <span className="required fw-semibold fs-5">
                Select Payment Type
              </span>
            </label>

            <Select
              options={paymentForList}
              value={customerData?.payment_type}
              onChange={(e) => setCustomerDataValue("payment_type", e)}
              placeholder="Select Payment Type"
              dropdownStyle={{ zIndex: 1111 }}
              style={{ width: "100%", marginTop: "10px" }}
              size="large"
              // allowClear
              // onClear={() => setCustomerDataValue("payment_type", null)}
            />
          </div>

          {customerData?.payment_type === "refund" && (
            <div className="col-12">
              <label>
                <span className="required fw-semibold fs-5">Add Reason</span>
              </label>

              <textarea
                className="form-control"
                placeholder="Enter Reason"
                rows="3"
                value={customerData?.refund_reason}
                onChange={(e) =>
                  setCustomerDataValue("refund_reason", e.target.value)
                }
              ></textarea>
            </div>
          )}

          <div className="col-12">
            <label>
              <span className="required fw-semibold fs-5">Amount</span>
            </label>

            <input
              type="number"
              className="form-control"
              placeholder="Enter Amount"
              value={customerData?.amount || ""}
              onChange={(e) => setCustomerDataValue("amount", e.target.value)}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-light-danger fs-7"
          onClick={onHide}
        >
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary fs-7"
          onClick={() => {
            onSubmitUser();
          }}
          disabled={sending}
        >
          Submit
          {sending && (
            <span className="spinner-border spinner-border-sm align-middle ms-2" />
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
