import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { fetchData } from "../../../../../config/service";
import { LIMITS } from "../../../../../config";
import { Loader, Pagination } from "../../../../../components";
const tab3Data = [
  {
    user: {
      name: "Khushbu Khetan",
      avatar: "/media/img.jpg",
    },
    paidOn: "Jan 21, 2024",
    editionChanges: "-",
    score: 3.5,
    payments: {
      total: "₹1,25,000",
    },
    visaStatus: "-",
    flight: "-",
    preferences: true, // represents the check icon
    esStatus: "Move to other...",
    poc: "Ruchir",
  },
  // You can add more rows with similar structure here
];
const InventoryManager = ({ editionData, setCurrentCount, setAvgPrice }) => {
  const [activePage, setActivePage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [limit, setLimit] = useState(LIMITS[0]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData(editionData?.id);
  }, [editionData?.id]);

  const getData = (id) => {
    let param = "";

    let offset = (activePage - 1) * limit;
    param += `?limit=${limit}&offset=${offset}&edition_id=${id}`;

    setLoading(true);
    fetchData(`api/editions/statistic/inventory-manager/${param}`, "get").then(
      (res) => {
        setLoading(false);
        if (res.success) {
          setCurrentCount(res.current_count);
          setAvgPrice(res.average_price);
          console.log(res, "res");

          setData(res?.data);
          setTotalRecords(res.count ? res.count - 1 : 0);
        } else {
          setData([]);
          toast.error(res.message);
        }
      }
    );
  };

  return (
    <div>
      <div className="table-responsive tbl-sales">
        {loading ? (
          <div className="table-responsive">
            <Loader cols={7} width={250} />
          </div>
        ) : (
          <div>
            {data?.length > 0 ? (
              <>
                <div className="table-responsive tbl-sales">
                  {/*begin::Table*/}

                  <table
                    className="table align-middle table-row-dashed table-row-gray-300 gy-4 gs-4 border-top-d"
                    id
                  >
                    <thead>
                      <tr className="fw-bolder text-gray-900 bg-light">
                        <th className="min-w-100px">User</th>
                        <th className="min-w-100px">Paid on</th>
                        <th className="min-w-125px">Edition Changes</th>
                        <th className="min-w-100px">Score</th>
                        <th className="min-w-150px">Payments</th>
                        <th className="min-w-100px">Visa Status</th>
                        <th className="min-w-100px">Flight</th>
                        <th className="min-w-125px">Preferences</th>
                        <th className="min-w-125px">ES Status</th>
                        <th className="min-w-125px">POC</th>
                        {/* <th className="min-w-100px">Action</th> */}
                      </tr>
                    </thead>
                    <tbody className="fw-semibold fs-7">
                      {data.map((row, index) => {
                        return (
                          <tr key={index}>
                            <td className="bdr-left-user">
                              <div className="d-flex align-items-center">
                                <div className="symbol symbol-30px overflow-hidden me-3">
                                  <img src={row?.user?.photo} alt={row.user} />
                                </div>
                                <div className="d-flex flex-column text-start">
                                  <a
                                    href="#"
                                    className="text-primary mb-1 fw-bolder fs-6 min-w-150px"
                                  >
                                    {row.user?.first_name +
                                      " " +
                                      row.user?.last_name}
                                  </a>
                                </div>
                              </div>
                            </td>
                            <td>
                              {row.paid_on
                                ? new Date(row.paid_on).toLocaleDateString()
                                : "-"}
                            </td>
                            <td>{row.edition_changes}</td>
                            <td>{row.score}</td>
                            <td>
                              <div className="fw-bolder fs-6 text-gray-900">
                                <small>Total:</small>{" "}
                                {row.payments ? row.payments : "-"}
                              </div>
                            </td>
                            <td>{row.visa ? row.visa.status : "-"}</td>
                            <td>
                              {row.is_booked_flight ? "Booked" : "Not Booked"}
                            </td>
                            <td>
                              {row.preferences ? (
                                <i className="ki-outline ki-check fs-2 text-gray-800" />
                              ) : (
                                "-"
                              )}
                            </td>
                            <td>{row.esStatus || "-"}</td>
                            <td>
                              <div className="fw-bold fs-6">
                                {row.poc || "-"}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  {/*end::Table*/}
                </div>

                <div className="separator separator-dashed border-gray-200" />
                <Pagination
                  setLimit={setLimit}
                  limit={limit}
                  data={data}
                  activePage={activePage}
                  totalRecords={totalRecords}
                  onChange={(pageNumber) => {
                    setLoading(true);
                    setActivePage(pageNumber);
                  }}
                />
              </>
            ) : (
              <div className="row g-5 mb-0 gx-md-10">
                <div className="col-md-12 text-center">
                  <div className="mt-0">
                    <img
                      src="/media/no-data-found.png"
                      className="mw-25"
                      alt="No Data Found"
                    />
                  </div>
                  <h1 className="mt-4">No Data Found</h1>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default InventoryManager;
