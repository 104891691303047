import React, { useEffect, useState } from "react";
import { Tabs, Spin, Button, Tooltip, message, Modal } from "antd";
import RefundPolicy from "./refund-policy";
import CancellationPolicy from "./cancellation-policy";
import { fetchData } from "../../../config/service";

const Policy = () => {
  const [policy, setPolicy] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(1); // Default to refund policy

  // useEffect(() => {
  //   fetchPolicy(activeTab);
  // }, [activeTab]);

  const fetchPolicy = async (type) => {
    setLoading(true);
    fetchData(`api/policy/policies?policy_choices=${activeTab}`)
      .then((res) => {
        if (res.success) {
          console.log(res, "res");
          if (res["0"]) {
            setPolicy(res["0"]);
          } else {
            setPolicy({});
          }
        } else {
          message.error(res.message);
        }
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const items = [
    {
      key: "1",
      label: "Refund Policy",
      children: (
        <RefundPolicy
          loading={loading}
          fetchPolicy={fetchPolicy}
          setLoading={setLoading}
          policy={policy}
        />
      ),
    },
    {
      key: "2",
      label: "Cancellation Policy",
      children: (
        <CancellationPolicy
          loading={loading}
          fetchPolicy={fetchPolicy}
          setLoading={setLoading}
          policy={policy}
        />
      ),
    },
  ];

  return <Tabs defaultActiveKey="1" items={items} onChange={handleTabChange} />;
};

export default Policy;
