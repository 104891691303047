import React, { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import { Image, TimePicker, Tooltip } from "antd";
import { fetchData } from "../../../../config/service";
import dayjs from "dayjs";
import { Loader, Dropzone } from "../../../../components";
import DebouncedSelect from "../../../../components/debounced-select";

export default function FlightModal({
  show,
  statusList,
  onHide,
  onSuccess,
  data,
  onlyForAdd = false,
}) {
  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState("");
  const [customerData, setCustomerData] = useState({
    flying_from: null,
    landing_time: null,
  });
  const [flightData, setFlightData] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [pictures, setPictures] = useState([]);
  const [removedImages, setRemovedImages] = useState([]);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (data && !onlyForAdd) {
      console.log(data, "data");
      getFlightData();
    }
  }, [data]);

  const getFlightData = () => {
    setLoading(true);
    fetchData(
      `api/travel_info/flight-ticket/details/?edition=${data?.edition}&user=${data?.user?.uuid}`,
      "get"
    ).then((res) => {
      setLoading(false);
      if (res.success) {
        setFlightData(res);
      } else {
        toast.error(res.message);
      }
    });
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);

    // Check if any file exceeds 5MB
    const oversizedFiles = files.filter((file) => file.size > 5 * 1024 * 1024);

    if (oversizedFiles.length > 0) {
      toast.error("File size must be less than 5MB");

      // Reset file input field
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      return;
    }

    setSelectedFiles(files);

    // Create image previews
    const previews = files.map((file) => URL.createObjectURL(file));
    setPreviewImages(previews);
  };

  const handleRemoveImage = (index) => {
    const newFiles = [...selectedFiles];
    const newPreviews = [...previewImages];

    newFiles.splice(index, 1);
    newPreviews.splice(index, 1);

    setSelectedFiles(newFiles);
    setPreviewImages(newPreviews);
  };

  const onSubmit = async () => {
    setSending(true);
    // if (selectedFiles.length === 0) {
    //   toast.error("Please select files to upload.");
    //   return;
    // }

    // let formData = new FormData();

    // selectedFiles.forEach((file) => {
    //   formData.append("flight_ticket", file);
    // });
    // formData.append("edition", data?.edition);
    // formData.append("share_details", true);

    const body = {
      edition: data?.edition,
      share_details: true,
      flight_ticket: selectedFiles,
      flying_from: customerData?.flying_from,
      landing_date_time: customerData?.landing_time,
      user: data?.user?.uuid,
    };

    // let uploadedPictures = pictures.filter((item) => item.path);
    // if (pictures.length) {
    //   body.flight_ticket = pictures;
    // }

    // console.log(body, selectedFiles, "body");

    fetchData(
      `customer/api/travel_info/flight-ticket/details/?edition=${data?.edition}`,
      "post",
      body,
      "",
      true
    ).then((res) => {
      setSending(false);
      toast.remove();
      if (res.success) {
        onHide();
        onSuccess();
        toast.success("Flight Ticket Added Successfully");
      } else {
        toast.error(res.message);
      }
    });
  };

  const formatDate = (dateString) => {
    return dateString ? dayjs.utc(dateString).format("DD-MM-YYYY h:mm a") : "-";
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Flight Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Loader cols={2} />
        ) : (
          <>
            {flightData?.edition !== "" &&
            flightData?.flight_ticket?.length > 0 &&
            !onlyForAdd ? (
              <>
                <div className="card card-custom gutter-b">
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div className="symbol symbol-40 symbol-light-primary me-5">
                        <span className="symbol-label">
                          <i className="ki-outline ki-airplane text-primary fs-1"></i>
                        </span>
                      </div>
                      <div className="d-flex flex-column flex-grow-1">
                        <h2 className="font-weight-bold text-dark mb-1">
                          {flightData?.flying_from_airport_name || "N/A"}
                        </h2>
                        <div className="d-flex justify-content-start gap-2 align-items-center">
                          <div className="fw-semibold text-muted fs-6 mb-1">
                            {flightData?.share_details
                              ? "Details Shared"
                              : "Details Not Shared"}
                          </div>
                          <div>
                            {flightData?.share_details ? (
                              <Tooltip title="Details Shared">
                                <i className="ki-outline ki-check-circle text-success fs-3"></i>
                              </Tooltip>
                            ) : (
                              <Tooltip title="Details Not Shared">
                                <i className="ki-outline ki-minus-circle text-danger fs-3"></i>
                              </Tooltip>
                            )}
                          </div>
                        </div>
                        {/* <span className="text-muted font-weight-bold">
                        {formatDate(flightData?.landing_date_time)}
                      </span> */}
                      </div>
                    </div>
                    <div className="separator separator-solid my-7"></div>
                    <div className="d-flex align-items-center flex-wrap">
                      <div className="d-flex align-items-center flex-lg-fill me-5 my-1">
                        <span className="me-4">
                          <i className="ki-outline ki-pin fs-1 text-muted "></i>
                        </span>
                        <div className="d-flex flex-column text-dark-75">
                          <span className="fw-semibold text-muted">From:</span>
                          <span className="fw-bold text-gray-800">
                            {flightData?.flying_from_airport_name || "N/A"}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center flex-lg-fill me-5 my-1">
                        <span className="me-4">
                          <i className="ki-outline ki-calendar fs-1 text-muted"></i>
                        </span>
                        <div className="d-flex flex-column text-dark-75">
                          <span className="fw-semibold text-muted">
                            Landing Date:
                          </span>
                          <span className="fw-bold text-gray-800">
                            {formatDate(flightData?.landing_date_time)}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="separator separator-solid my-7"></div>

                    {/* <div className="d-flex justify-content-start gap-2 align-items-center">
                    <div className="fw-bold text-gray-800 fs-5 mb-1">
                      {flightData?.share_details
                        ? "Details Shared"
                        : "Details Not Shared"}
                    </div>
                    <div>
                      {flightData?.share_details ? (
                        <Tooltip title="Details Shared">
                          <i className="ki-outline ki-check-circle text-success fs-2"></i>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Details Not Shared">
                          <i className="ki-outline ki-minus-circle text-danger fs-2 "></i>
                        </Tooltip>
                      )}
                    </div>
                  </div>

                  <div className="separator separator-solid my-7"></div> */}

                    <h3 className="font-weight-bold text-dark mb-4">
                      Flight Tickets
                    </h3>
                    <div className="row">
                      {flightData?.flight_ticket?.length > 0 ? (
                        flightData.flight_ticket.map((ticket, index) => (
                          <div
                            key={ticket.id}
                            className="col-md-4 col-lg-3 mb-3"
                          >
                            <Image
                              src={ticket.url}
                              alt={`Flight Ticket ${index + 1}`}
                              className="w-100 rounded"
                              height={150}
                              width={150}
                            />
                          </div>
                        ))
                      ) : (
                        <div className="col-12">
                          <p className="text-muted">
                            No flight tickets available.
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="row">
                  {/* Flying From Selection - col-md-6 */}
                  <div className="col-md-6">
                    <label className="fs-6 fw-bold mb-2">Flying From</label>
                    <DebouncedSelect
                      apiEndpoint="api/project_settings/airport-names/"
                      value={customerData?.flying_from}
                      onChange={(value) =>
                        setCustomerData((prev) => ({
                          ...prev,
                          flying_from: value,
                        }))
                      }
                      placeholder="Select Flying From"
                      labelFormatter={(item) =>
                        item.airport_name + " - " + item.city_name
                      }
                      valueFormatter={(item) => item.id}
                      size="large"
                      // mode="multiple"
                      dropdownStyle={{ zIndex: 1100 }}
                    />
                  </div>

                  {/* Landing Time Picker - col-md-6 */}
                  <div className="col-md-6">
                    <label className="fs-6 fw-bold mb-2">Landing Time</label>
                    <TimePicker
                      className="fs-6 w-100 p-3 mb-2"
                      size="large"
                      value={
                        customerData.landing_time
                          ? dayjs(customerData.landing_time, "YYYY-MM-DD HH:mm")
                          : null
                      }
                      onChange={(time, timeString) => {
                        setCustomerData({
                          ...customerData,
                          landing_time: timeString,
                        });
                      }}
                      format="YYYY-MM-DD HH:mm"
                    />
                  </div>

                  {/* Image Upload Section - col-12 */}
                  <div className="col-12 mt-4">
                    <input
                      type="file"
                      multiple
                      onChange={handleFileChange}
                      accept=".png, .jpg, .jpeg"
                      className="form-control"
                    />

                    {previewImages.length > 0 && (
                      <div className="mt-3">
                        <h5>Preview:</h5>
                        <div className="row">
                          {previewImages.map((preview, index) => (
                            <div
                              key={index}
                              className="col-md-3 mb-2"
                              style={{ position: "relative" }}
                            >
                              <div className="image-preview">
                                <Image
                                  src={preview}
                                  alt={`Preview ${index + 1}`}
                                  className="w-100 rounded"
                                  height={100}
                                  width={100}
                                />
                                <i
                                  title="Remove Image"
                                  className="ki-outline ki-cross fs-2"
                                  onClick={() => handleRemoveImage(index)}
                                  style={{
                                    position: "absolute",
                                    top: "-8px",
                                    right: "75px",
                                    cursor: "pointer",
                                    zIndex: 2,
                                    backgroundColor: "white",
                                    borderRadius: "50%",
                                  }}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    {/* {selectedFiles.length > 0 && (
                      <button
                        type="button"
                        className="btn btn-primary mt-3"
                        onClick={onSubmit}
                        disabled={sending}
                      >
                        {sending ? "Uploading..." : "Upload Files"}
                      </button>
                    )} */}
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-light-danger fs-7"
          onClick={onHide}
        >
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary fs-7"
          onClick={() => {
            onSubmit();
          }}
          disabled={sending}
        >
          Submit
          {sending && (
            <span className="spinner-border spinner-border-sm align-middle ms-2" />
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
