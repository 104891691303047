import { useState } from "react";
import { Loader } from "../../../../components";
import NotesDrawer from "../../onboarding-calls/drawer/notes";
import FlightModal from "../../bookings-page/modals/flight";
import { Tooltip } from "antd";
import PassportCheckModal from "../../bookings-page/modals/passport-check";

const revenueValues = {
  1: "0 to 5L",
  2: "5L to 10L",
  3: "10L to 15L",
  4: "15L to 25L",
  5: "25L to 50L",
  6: "50L to 1Cr",
  7: "1Cr to 3Cr",
  8: "3Cr to 6Cr",
  9: "6Cr to 10Cr",
  10: "10Cr to 20Cr",
  11: "20Cr +",
};

export const UserCards = ({
  data,
  loading,
  notesOptions,
  getRoomMangerList,
}) => {
  const [selectedModalData, setSelectedModalData] = useState({});
  const [showNotesDrawer, setShowNotesDrawer] = useState(false);
  const [flightModal, setFlightModal] = useState(false);
  const [passportInfoModal, setPassportInfoModal] = useState(false);

  if (loading) {
    return <Loader col={2} />;
  }
  console.log(data, "usercards");

  return (
    <>
      {data.length > 0 ? (
        data.map((item) => {
          const birthDate = new Date(item?.user?.date_of_birth);
          const today = new Date();
          const age = today.getFullYear() - birthDate.getFullYear();

          return (
            <div
              key={item?.user?.uuid}
              className="card card-flush mb-6 mb-xl-6"
            >
              <div className="card-body p-4 pt-2">
                <div className="table-responsive tbl-sales">
                  <table className="table align-middle table-row-dashed table-row-gray-300 gy-4 gs-4 border-top-d">
                    <thead>
                      <tr className="fw-bolder text-gray-900 bg-white">
                        <th className="min-w-250px">Name</th>
                        <th className="min-w-80px">Score</th>
                        <th className="min-w-75px">Age</th>
                        <th className="min-w-75px">Designation</th>
                        <th className="min-w-100px">Food</th>
                        <th className="min-w-85px">Drinking</th>
                        <th className="min-w-100px">Smoking</th>
                        <th className="min-w-100px">Sleep</th>
                        <th className="min-w-100px text-end">Room</th>
                      </tr>
                    </thead>
                    <tbody className="fw-semibold fs-7">
                      <tr>
                        <td className="bdr-left-user">
                          <div className="d-flex align-items-center ps-3">
                            <div className="d-flex flex-column">
                              {/* <Link
                                  to={routesList["customer_details"].path}
                                  className="text-primary mb-1 fw-bolder fs-5"
                                >
                                  {`${item.user.first_name} ${item.user.last_name}`}
                                </Link> */}
                              <div className="text-primary mb-1 fw-bolder fs-5">
                                {`${item?.user?.first_name} ${item?.user?.last_name}`}
                              </div>
                              <span className="text-gray-600">
                                Check-in Time: {new Date().toLocaleDateString()}{" "}
                                2:00 pm
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>{item?.user?.score || 0}</td>
                        <td>{age}</td>
                        <td>{item?.user?.user_profile?.designation || "-"}</td>
                        <td>
                          {item?.preference && item?.preference?.foods
                            ? item?.preference?.foods.length > 0
                              ? item?.preference?.foods[0]?.label
                              : "-"
                            : "-"}
                        </td>
                        <td>
                          {item?.preference && item?.preference?.drinking
                            ? item?.preference?.drinking
                              ? item?.preference?.drinking?.label
                              : "-"
                            : "-"}
                        </td>
                        <td>
                          {item?.preference && item?.preference?.smoking
                            ? item?.preference?.smoking
                              ? item?.preference?.smoking?.label
                              : "-"
                            : "-"}
                        </td>
                        <td>
                          {item?.preference && item?.preference?.sleeping
                            ? item?.preference?.sleeping
                              ? item?.preference?.sleeping?.label
                              : "-"
                            : "-"}
                        </td>
                        <td>
                          <div className="d-flex flex-shrink-0 justify-content-end align-items-center">
                            <a
                              href="#"
                              className="btn btn-icon bg-white btn-active-color-primary btn-sm ms-2"
                              data-bs-toggle="modal"
                              data-bs-target="#kt_modal_add_customer"
                            >
                              <i className="ki-outline ki-pencil fs-2 text-info" />
                            </a>
                            <a
                              href="#"
                              data-bs-toggle="modal"
                              data-bs-target="#kt_modal_download"
                              className="btn p-0 btn-icon btn-active-color-primary btn-sm"
                            >
                              <img
                                src="/media/csv.png"
                                className="h-20px"
                                alt=""
                              />
                            </a>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="rounded p-4 bg-light">
                  <div className="d-flex flex-stack">
                    <div>
                      <div>
                        <div className="fw-semibold text-gray-800 fs-7">
                          Special Request:{" "}
                          <span className="fw-bolder">
                            {item?.preference?.special_note || "None"}
                          </span>
                        </div>
                        <div className="fw-semibold text-gray-800 fs-7">
                          Primary Agenda:{" "}
                          <span className="fw-bolder">
                            {item?.preference?.contribute || "-"}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="fw-semibold text-gray-800 fs-7">
                      {item?.booking_onboarding_call?.coming_label || "-"}
                    </div>
                    <div className="fw-semibold text-gray-800 fs-7">
                      {item?.user?.user_profile?.contact || "-"}
                    </div>
                    <div className="fw-semibold text-gray-800 fs-7">
                      {revenueValues[
                        item?.user?.user_work_details[0]?.revenue
                      ] || "-"}
                    </div>
                    <div className="fw-semibold text-gray-800 fs-7">
                      {item?.booking_onboarding_call?.visa_stage &&
                      item?.booking_onboarding_call?.visa_stage.length > 0
                        ? item?.booking_onboarding_call?.visa_stage[0] +
                          ":" +
                          item?.booking_onboarding_call?.visa_stage[1]
                        : "-"}
                    </div>
                    <div className="d-flex flex-shrink-0 justify-content-end">
                      {/* <div className="min-w-250px text-end me-5">
                          <div className="fs-7 text-gray-900 fw-bold">
                            <span className="me-4">See Contact</span>{" "}
                            <span>Passport Details</span>
                          </div>
                          <div className="fs-7 text-gray-600">
                            Remind to Share Preference
                          </div>
                        </div> */}
                      {item?.user?.user_profile?.linkedin_url && (
                        <a
                          href={item?.user?.user_profile?.linkedin_url}
                          target="_blank"
                          className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                        >
                          <i className="lab la-linkedin fs-1 text-gray-800" />
                        </a>
                      )}

                      {item?.user?.user_profile?.instagram_url && (
                        <a
                          href={item?.user?.user_profile?.instagram_url}
                          target="_blank"
                          className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                        >
                          <i className="ki-outline ki-instagram fs-2 text-danger" />
                        </a>
                      )}

                      {item?.booking_onboarding_call?.is_booked_flight && (
                        <Tooltip title="click to view flight details">
                          <button
                            type="link"
                            className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                            onClick={() => {
                              // const data ={edition_id: item?.edition, user_id: item?.user?.uuid};
                              setSelectedModalData({ ...item });
                              setFlightModal(!flightModal);
                            }}
                          >
                            <i className="ki-outline ki-airplane fs-2 text-success" />
                          </button>
                        </Tooltip>
                      )}

                      {item?.booking_onboarding_call?.is_passport_valid && (
                        <Tooltip title="click to view passport details">
                          <button
                            type="link"
                            className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                            onClick={() => {
                              setSelectedModalData({ ...item });
                              setPassportInfoModal(!passportInfoModal);
                            }}
                          >
                            <i className="ki-outline ki-password-check fs-2 text-primary" />
                          </button>
                        </Tooltip>
                      )}

                      {true && (
                        <button
                          id="kt_drawer_example_toggle"
                          type="link"
                          className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-0"
                          onClick={() => {
                            setSelectedModalData(item);
                            setShowNotesDrawer(!showNotesDrawer);
                          }}
                        >
                          <i className="ki-outline ki-notepad fs-2 text-gray-800" />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="row g-5 mb-0 gx-md-10">
          <div className="col-md-12 text-center">
            <div className="mt-0">
              <img
                src="/media/no-data-found.png"
                className="mw-25"
                alt="No Data Found"
              />
            </div>
            <h1 className="mt-4">No Data Found</h1>
          </div>
        </div>
      )}

      {showNotesDrawer && (
        <NotesDrawer
          show={showNotesDrawer}
          onHide={() => {
            setSelectedModalData({});
            setShowNotesDrawer(false);

            // setLoading(true);
            // getData();
          }}
          data={selectedModalData}
          notesOptions={notesOptions}
        />
      )}

      {flightModal && (
        <FlightModal
          show={flightModal}
          onHide={() => {
            setFlightModal(false);
            // setLoading(true);
            // getData();
          }}
          onSuccess={() => {
            getRoomMangerList();
          }}
          data={selectedModalData}
        />
      )}

      {passportInfoModal && (
        <PassportCheckModal
          show={passportInfoModal}
          onHide={() => {
            setPassportInfoModal(false);
            // setLoading(true);
            // getData();
          }}
          data={selectedModalData}
        />
      )}
    </>
  );
};
