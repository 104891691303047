import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import { DatePicker, Select, Checkbox, Input } from "antd";

import { fetchData } from "../../../../config/service";

const { Option } = Select;
export default function OnboardingDetail({
  show,
  statusList,
  onHide,
  onSuccess,
  data,
}) {
  const [sending, setSending] = useState(false);
  const [userId, setUserId] = useState("");

  const [formData, setFormData] = useState({
    coming: null,
    readLoveNotes: false,
    loveNoteReason: "",
    joinWhatsappGroup: false,
    secondPaymentDate: null,
    paymentStatus: null,
    passportValid: false,
    visaStatus: null,
    bookedFlight: false,
    travellingWithPartner: false,
    isOnboarding: false,
  });

  const onSubmitUser = async () => {
    const {
      coming,
      readLoveNotes,
      loveNoteReason,
      joinWhatsappGroup,
      secondPaymentDate,
      paymentStatus,
      passportValid,
      visaStatus,
      bookedFlight,
      travellingWithPartner,
      isOnboarding,
    } = formData;

    const body = {
      booking: data?.id,
      coming: coming ? 1 : 4,
      is_read_love_note: readLoveNotes ? true : false,
      love_note_reason: loveNoteReason,
      is_join_whatsapp_group: joinWhatsappGroup ? true : false,
      latest_payment_due_date: secondPaymentDate
        ? secondPaymentDate.format("YYYY-MM-DD HH:mm:ss")
        : "",
      payment_status: paymentStatus,
      is_passport_valid: passportValid ? true : false,
      is_booked_flight: bookedFlight ? true : false,
      travelling_with_partner: travellingWithPartner ? 1 : 0,
      is_onboarding: isOnboarding,
      visa_status: visaStatus,
      edition: data?.edition,
    };

    setSending(true);

    fetchData(
      `customer/api/booking/add-onboarding-call-details/`,
      "post",
      body
    ).then((res) => {
      toast.remove();
      setSending(false);
      if (res.success) {
        onHide();
        onSuccess();
        toast.success("Success");
      } else {
        toast.error(res.message);
      }
    });
  };

  const handleChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  return (
    <Modal show={show} onHide={onHide} size="md" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <h3 className="modal-title">
            {data?.user?.first_name + " " + data?.user?.last_name}
          </h3>
          <div className="fs-7">
            Phone:{" "}
            <a href="" className="fw-bold">
              {data?.user?.phone || "-"}
            </a>
          </div>
          <div className="fs-7">
            City:{" "}
            <a href="" className="fw-bold">
              {data?.user?.user_profile?.city_name || "-"}
            </a>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row g-5 mb-5 align-items-center">
          {/* Onboarding Call Section */}
          {/* <div className="col-md-12 fv-row">
            <form className="form" action="#" method="post">
              <label htmlFor="" className="fs-6 fw-bold mb-0">
                Onboarding Call
              </label>
              <div className="fv-row mt-3">
                <p className="mb-1 fw-bold">What to share?</p>
                <p className="mb-1">
                  Welcome them to Tribe, build their excitement. Tell them about
                  Vibe Tribe.
                </p>
              </div>
            </form>
          </div> */}

          {/* Form Fields */}
          <div className="col-md-12  mt-5">
            <label className="form-label fw-semibold">Onboarding Coming</label>
            <div className="me-3">
              <Select
                value={formData.coming}
                onChange={(value) => handleChange("coming", value)}
                style={{ width: "100%" }}
                dropdownStyle={{ zIndex: 1111 }}
                size="large"
                placeholder="Enter Onboarding Coming"
                options={[
                  { value: 1, label: "Coming" },
                  { value: 2, label: "Need Time" },
                  { value: 3, label: "Not sure" },
                  { value: 4, label: "Not Coming" },
                ]}
              ></Select>
              {/* <Checkbox
                checked={formData.coming}
                onChange={(e) => handleChange("coming", e.target.checked)}
              >
                Coming / Not Coming
              </Checkbox> */}
            </div>
          </div>

          <div className="col-md-12 fv-row">
            <div>
              <label htmlFor="" className="fs-6 fw-semibold mb-2">
                Second Payment Due Date
              </label>
              <DatePicker
                value={formData.secondPaymentDate}
                onChange={(date) => handleChange("secondPaymentDate", date)}
                style={{ width: "100%" }}
                size="large"
              />
            </div>
          </div>

          <div className="col-md-6 fv-row">
            <div>
              <label className="form-label fw-semibold"> Payment Status</label>
              <Select
                value={formData.paymentStatus}
                onChange={(value) => handleChange("paymentStatus", value)}
                style={{ width: "100%" }}
                dropdownStyle={{ zIndex: 1111 }}
                size="large"
                placeholder="Payment Status"
                options={[
                  { value: 1, label: "Doing" },
                  { value: 2, label: "Will Do" },
                  { value: 3, label: "Not Doing" },
                  { value: 4, label: "Done" },
                  { value: 5, label: "Payment extension" },
                ]}
              ></Select>
            </div>
          </div>

          <div className="col-md-6 ">
            <div>
              <label className="form-label fw-semibold"> Visa Status</label>
              <Select
                value={formData.visaStatus}
                onChange={(value) => handleChange("visaStatus", value)}
                style={{ width: "100%" }}
                size="large"
                placeholder="Visa Status"
                dropdownStyle={{ zIndex: 1111 }}
                options={[
                  { value: 1, label: "Applied,yet to get" },
                  { value: 2, label: "Not yet Applied" },
                  { value: 3, label: "It's Complicated" },
                  { value: 4, label: "Have it already" },
                ]}
              ></Select>
            </div>
          </div>

          <div className="col-md-12 fv-row">
            <label htmlFor="" className="fs-6 fw-semibold mb-2">
              Love Note Reason
            </label>
            <textarea
              className="form-control fs-7"
              placeholder="Love Note Reason"
              cols={30}
              rows={5}
              value={formData?.loveNoteReason}
              onChange={(e) => handleChange("loveNoteReason", e.target.checked)}
            />
          </div>

          <div className="col-md-6 mt-5">
            <div className="me-3">
              <Checkbox
                checked={formData.joinWhatsappGroup}
                onChange={(e) =>
                  handleChange("joinWhatsappGroup", e.target.checked)
                }
              >
                Join WhatsApp Group
              </Checkbox>
            </div>
          </div>

          <div className="col-md-6  mt-5">
            <div>
              <Checkbox
                checked={formData.readLoveNotes}
                onChange={(e) =>
                  handleChange("readLoveNotes", e.target.checked)
                }
              >
                Read Love Notes
              </Checkbox>
            </div>
          </div>
          <div className="col-md-6 ">
            <div>
              <Checkbox
                checked={formData.passportValid}
                onChange={(e) =>
                  handleChange("passportValid", e.target.checked)
                }
              >
                Passport is Valid
              </Checkbox>
            </div>
          </div>

          <div className="col-md-6 ">
            <div>
              <Checkbox
                checked={formData.bookedFlight}
                onChange={(e) => handleChange("bookedFlight", e.target.checked)}
              >
                Booked Flight
              </Checkbox>
            </div>
          </div>

          <div className="col-md-6">
            <Checkbox
              checked={formData.travellingWithPartner}
              onChange={(e) =>
                handleChange("travellingWithPartner", e.target.checked)
              }
            >
              Travelling with a Partner
            </Checkbox>
          </div>

          <div className="col-md-6">
            <Checkbox
              checked={formData.isOnboarding}
              onChange={(e) => handleChange("isOnboarding", e.target.checked)}
            >
              onboarding
            </Checkbox>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-light-danger fs-7"
          onClick={onHide}
        >
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary fs-7"
          onClick={() => {
            onSubmitUser();
          }}
          disabled={sending}
        >
          Submit
          {sending && (
            <span className="spinner-border spinner-border-sm align-middle ms-2" />
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
