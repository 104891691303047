import React, { useEffect, useState } from "react";
import { FAQModal } from "./modals";
import { fetchData } from "../../../config/service";
import toast from "react-hot-toast";
import { Loader } from "../../../components";
import { useSelector } from "react-redux";
import { Select } from "antd";

const collapsibleData = [
  {
    id: "kt_job_4_2",
    title: "Do I need a designer to use Admin Theme?",
    content:
      "First, a disclaimer – the entire process of writing a blog post often takes more than a couple of hours, even if you can type eighty words as per minute and your writing skills are sharp.",
  },
  {
    id: "kt_job_4_3",
    title: "What do I need to do to start selling?",
    content:
      "First, a disclaimer – the entire process of writing a blog post often takes more than a couple of hours, even if you can type eighty words as per minute and your writing skills are sharp.",
  },
  // Add more sections as needed
];

export default function FAQs() {
  const user = useSelector((state) => state.user);
  const [isOpenUserModal, setIsOpenUserModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [expandedId, setExpandedId] = useState(null);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState({
    edition: null,
  });

  const [editionsList, setEditionsList] = useState([]);
  const [selectedModalData, setSelectedModalData] = useState(null);

  useEffect(() => {
    getEditions();
  }, []);

  useEffect(() => {
    getData();
  }, [filterData]);

  const getEditions = () => {
    fetchData(`api/editions/editions-select/`, "get").then((res) => {
      if (res.success) {
        const data = res.data.map((e) => {
          return { label: e.name, value: e.id };
        });
        setEditionsList(data);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getData = () => {
    setLoading(true);

    let url = "api/faqs/?";

    // Check if edition has a value other than null/undefined
    if (filterData?.edition) {
      url += `editions=${filterData.edition}`;
    } else {
      url += "type=1";
    }

    fetchData(url, "get").then((res) => {
      setLoading(false);
      if (res.success) {
        setData(res.data);
      } else {
        toast.error(res.message);
      }
    });
  };

  // Delete a specific FAQ by id
  const handleDelete = (id) => {
    // console.log(id, "id");

    fetchData(`api/faqs/${id}`, "delete").then((res) => {
      toast.remove();
      if (res.success) {
        setLoading(true);
        getData();
      }
    });
  };

  const toggleCollapse = (id) => {
    setExpandedId((prevId) => (prevId === id ? null : id));
  };

  return (
    <div>
      <div className="row g-6 mb-6 g-xl-9 mb-xl-9">
        {/*begin::Followers*/}
        {/*begin::Col*/}
        <div className="col-md-12 col-xxl-12">
          {/*begin::FAQ card*/}
          <div className="card">
            {/*begin::Body*/}
            <div className="card-body p-10 p-lg-15 ">
              {/*begin::Classic content*/}
              <div className="mb-8 ">
                {/*begin::Intro*/}
                <div className="mb-15  d-flex flex-stack">
                  <div>
                    {/*begin::Title*/}
                    <h4 className="fs-1 text-gray-800 w-bolder mb-0">
                      Frequesntly Asked Questions
                    </h4>
                    {/*end::Title*/}
                  </div>

                  <div>
                    <Select
                      value={filterData?.edition}
                      showSearch
                      optionFilterProp="label"
                      onChange={(value) =>
                        setFilterData({ ...filterData, edition: value })
                      }
                      placeholder="Select Edition"
                      style={{ width: "200px" }}
                      options={editionsList}
                      allowClear
                      onClear={() => {
                        setFilterData({ ...filterData, edition: null });
                      }}
                      size="large"
                    />

                    <button
                      className="btn btn-primary px-8 ms-5"
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_add_qa"
                      onClick={() => {
                        setIsOpenUserModal(true);
                      }}
                    >
                      Add a FAQs
                    </button>
                  </div>
                </div>
                {/*end::Intro*/}
                {/*begin::Row*/}
                <div className="row mb-12">
                  {/*begin::Col*/}
                  <div className="col-md-12 pe-md-10 mb-10 mb-md-0">
                    {loading ? (
                      <div className="table-responsive">
                        <Loader cols={1} width={550} />
                      </div>
                    ) : (
                      <>
                        {data?.length > 0 ? (
                          data.map((item) => (
                            <div key={item.id} className="m-0">
                              {/* Begin::Heading */}
                              <div
                                className="d-flex justify-content-between align-items-center collapsible py-3 toggle"
                                onClick={() => toggleCollapse(item.id)}
                              >
                                <div>
                                  <div className="d-flex align-items-center ">
                                    {/* Begin::Icon */}
                                    <div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
                                      {expandedId === item.id ? (
                                        <i className="ki-duotone ki-minus-square text-primary fs-1">
                                          <span className="path1" />
                                          <span className="path2" />
                                        </i>
                                      ) : (
                                        <i className="ki-duotone ki-plus-square fs-1">
                                          <span className="path1" />
                                          <span className="path2" />
                                          <span className="path3" />
                                        </i>
                                      )}
                                    </div>
                                    {/* End::Icon */}
                                    {/* Begin::Title */}
                                    <h4 className="text-gray-700 fw-bold cursor-pointer mb-0">
                                      {item?.question}
                                    </h4>
                                    {/* End::Title */}
                                  </div>
                                </div>

                                {/* Delete Button for admin */}
                                {user?.user_type === 1 && (
                                  <div className="d-flex align-items-center justify-content-start">
                                    <i
                                      className="ki-outline ki-pencil fs-2 ms-3 text-primary cursor-pointer"
                                      onClick={(e) => {
                                        setSelectedModalData(item);
                                        setIsOpenUserModal(true);
                                      }}
                                    ></i>

                                    <i
                                      className="ki-outline ki-trash fs-2 ms-3 text-danger cursor-pointer"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        toast.loading("Deleting...");
                                        handleDelete(item.id);
                                      }}
                                    ></i>
                                  </div>
                                )}
                              </div>
                              {/* End::Heading */}
                              {/* Begin::Body */}
                              <div
                                className={`collapse ${
                                  expandedId === item.id ? "show" : ""
                                } fs-6 ms-1`}
                              >
                                {/* Begin::Text */}
                                <div className="mb-4 text-gray-600 fw-semibold fs-6 ps-10">
                                  {item?.answer}
                                </div>
                                {/* End::Text */}
                              </div>
                              {/* End::Body */}
                              {/* Begin::Separator */}
                              <div className="separator separator-dashed" />
                              {/* End::Separator */}
                            </div>
                          ))
                        ) : (
                          <div className="row g-5 mb-0 gx-md-10">
                            <div className="col-md-12 text-center">
                              <div className="mt-0">
                                <img
                                  src="/media/no-data-found.png"
                                  className="mw-25"
                                  alt="No Data Found"
                                />
                              </div>
                              <h1 className="mt-4">No Data Found</h1>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>

                  {/*end::Col*/}
                </div>
                {/*end::Row*/}
              </div>
              {/*end::Classic content*/}
            </div>
            {/*end::Body*/}
          </div>
          {/*end::FAQ card*/}
        </div>
        {/*end::Col*/}
      </div>

      {isOpenUserModal && (
        <FAQModal
          show={isOpenUserModal}
          editionsList={editionsList}
          data={selectedModalData}
          onHide={() => {
            setIsOpenUserModal(false);
          }}
          // data={user_data}
          // userRoles={userRoles}
          onSuccess={() => {
            setLoading(true);
            getData();
          }}
        />
      )}
    </div>
  );
}
