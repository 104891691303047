import React, { useEffect, useState } from "react";
import { fetchData } from "../../../config/service";
import toast from "react-hot-toast";
import {
  Button,
  DatePicker,
  message,
  Popover,
  Select,
  Spin,
  Tooltip,
} from "antd";
import { Loader, Pagination } from "../../../components";
import { formatNumber, LIMITS, routesList } from "../../../config";
import dayjs from "dayjs";
import VisaStageModal from "./modals/visa-stage";
import Overview from "./tabs/overview";
import CrossCheck from "./tabs/cross-check";
import Active from "./tabs/active";
import Completed from "./tabs/completed";
import Ongoing from "./tabs/ongoing";
import FlightModal from "../bookings-page/modals/flight";
import NotesDrawer from "../onboarding-calls/drawer/notes";
import PassportCheckModal from "../bookings-page/modals/passport-check";
import MakeCallModal from "../../../components/make-call-modal";
import WhatsappTemplateModal from "../../customers/modals/whatsapp-template";
import DebouncedSelect from "../../../components/debounced-select";
import { Link } from "react-router-dom";
import EditableField from "../../../components/editable-field";

export default function VisaCenter() {
  // State to keep track of active tab
  const [activeTab, setActiveTab] = useState(1);
  const [loading, setLoading] = useState(false);
  const [ongoingData, setOngoingData] = useState([]);
  const [editionsList, setEditionsList] = useState([]);
  const [filterData, setFilterData] = useState({
    // edition: "66e947c95d2d24c2274b73e1",
    edition: null,
    visa_stage: null,
    visa_status: null,
  });

  const [activePage, setActivePage] = useState(1);
  const [chartLoading, setChartLoading] = useState(false);

  const [totalRecords, setTotalRecords] = useState(0);
  const [limit, setLimit] = useState(LIMITS[0]);
  const [visaStageModal, setVisaStageModal] = useState(false);
  const [selectedOngoingData, setSelectedOngoingData] = useState(null);

  const [dashboardData, setDashBoardData] = useState([]);
  const [dashboardLoading, setDashboardLoading] = useState(false);
  const [visaChoices, setVisaChoices] = useState({});

  const [editionListLoading, setEditionListLoading] = useState(false);

  const [editionActivePage, setEditionActivePage] = useState(1);
  const [editionSearchTerm, setEditionSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] =
    useState(editionSearchTerm);

  const [flightModal, setFlightModal] = useState(false);

  const [passportInfoModal, setPassportInfoModal] = useState(false);
  const [paymentDrawer, setPaymentDrawer] = useState(false);

  const [selectedModalData, setSelectedModalData] = useState({});
  const [showNotesDrawer, setShowNotesDrawer] = useState(false);
  const [notesOptions, setNotesOptions] = useState([]);
  const [visaRelatedFlag, setVisaRelatedFlag] = useState(false);
  const [isMakeCallModalOpen, setIsMakeCallModalOpen] = useState(false);
  const [whatsappTemplateModal, setWhatsAppTemplateModal] = useState(false);

  useEffect(() => {
    // getEditions();
    getVisaChoices();
    getNotesOptions();
  }, []);

  useEffect(() => {
    // getEditions();
  }, [editionActivePage, debouncedSearchTerm]);

  useEffect(() => {
    getData();
  }, [filterData]);

  useEffect(() => {
    getDashboardData();
  }, [filterData?.edition]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(editionSearchTerm);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [editionSearchTerm]);

  const getEditions = () => {
    const newSearch = debouncedSearchTerm;

    const limitNew = newSearch ? 50 : limit;
    const offsetNew = newSearch ? 0 : (editionActivePage - 1) * limit;

    setEditionListLoading(true);
    fetchData(
      `api/editions/editions-select/?is_international=1&offset=${offsetNew}&limit=${limitNew}&search=${encodeURIComponent(
        newSearch || ""
      )}`,
      "get"
    ).then((res) => {
      setEditionListLoading(false);
      if (res.success) {
        const data = res.data.map((e) => {
          return { label: e.name, value: e.id };
        });
        setEditionsList(data);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getData = () => {
    setLoading(true);
    fetchData(
      `customer/api/visa/visa-information-admin-list/?visa_stage=${
        filterData?.visa_stage || ""
      }&visa_status=${filterData?.visa_status || ""}&limit=${limit}&offset=${
        (activePage - 1) * limit
      }&edition=${filterData?.edition || ""}`,
      "get"
    )
      .then((res) => {
        setOngoingData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setOngoingData([]);
        setLoading(false);
        toast.error(err.message);
      });
  };

  const getDashboardData = () => {
    setDashboardLoading(true);
    fetchData(
      `customer/api/visa/visa-center/?edition_id=${filterData?.edition || ""}`
    ).then((res) => {
      setDashboardLoading(false);
      if (res.success) {
        setDashBoardData(res);
      } else {
        setDashBoardData([]);
        toast.error(res.message);
      }
    });
  };

  const getVisaChoices = () => {
    setLoading(true);
    fetchData(`customer/api/visa/visa-choices/`, "get").then((res) => {
      setLoading(false);
      if (res.success) {
        setVisaChoices(res);
      } else {
        setVisaChoices({});
        toast.error(res.message);
      }
    });
  };

  const getNotesOptions = () => {
    fetchData(`api/note/notes-enum/`, "get").then((res) => {
      if (res.success) {
        setNotesOptions(res["note_choices"]);
      } else {
        toast.error(res.message);
      }
    });
  };

  const handleAppoinmentDate = async (user_id, value) => {
    message.loading("Please wait...");
    const body = {
      preferred_appointment_date: value,
    };

    fetchData(
      `customer/api/visa/visa-information-update/${user_id}/`,
      "put",
      body
    ).then((res) => {
      message.destroy();
      setLoading(false);
      if (res.success) {
        setLoading(true);
        getData();
        toast.success(res.message || "Success");
      } else {
        toast.error(res.message);
      }
    });
  };

  // Function to handle tab click
  const handleTabClick = (id) => {
    if (id == 2 || id == 3) {
      if (id == 3) {
        setFilterData((prevData) => ({
          ...prevData,
          visa_status: 14,
        }));
        setActiveTab(id);
      } else {
        setFilterData((prevData) => ({
          ...prevData,
          visa_status: null,
        }));
        setActiveTab(id);
      }
    } else {
      setActiveTab(id);
    }
  };

  const tabs = [
    { id: 1, title: "Overview", content: "This is the Overview content." },
    { id: 2, title: "Ongoing", content: "This is the Ongoing content." },
    { id: 3, title: "Completed", content: "This is the Completed content." },
    // { id: 4, title: "Active", content: "This is the Active content." },
    // {
    //   id: 5,
    //   title: "Cross Check",
    //   content: "This is the Cross Check content.",
    // },
  ];

  console.log(selectedModalData, "selectedModalData");
  return (
    <div>
      <div className="row g-5 g-xxl-5 mb-10 align-items-center">
        <div className="col-xxl-7">
          <div className="d-flex flex-wrap gap-3">
            {/* <div class="position-relative ">
                                          <i
                                              class="ki-outline ki-magnifier search-icon fs-2 text-gray-500 position-absolute top-50 translate-middle-y ms-5"></i>
                                          <input type="text" data-kt-table-widget-4="search"
                                              class="form-control text-dark w-250px fs-7 ps-12 all-search"
                                              name="all_search" placeholder="Search Hotel here...">
                                      </div> */}

            <div className="position-relative ">
              {/* <Select
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                value={filterData?.edition}
                showSearch
                onSearch={(value) => {
                  setEditionSearchTerm(value);
                }}
                optionFilterProp="label"
                onChange={(value) => {
                  setFilterData((prevState) => ({
                    ...prevState,
                    edition: value,
                  }));
                }}
                placeholder="Select Edition"
                style={{ width: 280 }}
                options={editionsList}
                allowClear
                onClear={() => {
                  setFilterData((prevState) => ({
                    ...prevState,
                    edition: null,
                  }));
                  setEditionActivePage(1);
                }}
                onPopupScroll={(e) => {
                  const bottom =
                    Math.abs(
                      e.target.scrollHeight -
                        (e.target.scrollTop + e.target.clientHeight)
                    ) < 1.5;

                  if (bottom) {
                    setEditionListLoading(true);
                    setEditionActivePage((prevState) => prevState + 1);
                  }
                }}
                notFoundContent={
                  <>
                    <div className="d-flex justify-content-center align-items-center">
                      {editionListLoading ? (
                        <Spin size="small" />
                      ) : (
                        "No Data Found"
                      )}
                    </div>
                  </>
                }
                size="large"
                loading={editionListLoading}
              ></Select> */}

              <DebouncedSelect
                apiEndpoint="api/editions/editions-select"
                value={filterData?.edition}
                onChange={(value) => {
                  setFilterData((prevState) => ({
                    ...prevState,
                    edition: value,
                  }));
                }}
                placeholder="Select Edition"
                labelFormatter={(item) => item.name}
                size="large"
                style={{ width: 280 }}
                allowClear
                onClear={() => {
                  setFilterData((prevState) => ({
                    ...prevState,
                    edition: null,
                  }));
                }}
                additionalParams={{
                  is_international: 1,
                }}
              />

              {/* <Select
                value={filterData?.edition}
                onChange={(value) => {
                  setFilterData((prevData) => ({
                    ...prevData,
                    edition: value,
                  }));
                }}
                placeholder="Select Edition"
                style={{ width: 280 }}
                options={editionsList}
                allowClear
                size="large"
              /> */}
            </div>

            {activeTab == 2 && (
              <>
                <div className="position-relative ">
                  <Select
                    value={filterData?.visa_stage}
                    onChange={(value) => {
                      setFilterData((prevData) => ({
                        ...prevData,
                        visa_stage: value,
                      }));
                    }}
                    placeholder="Select Visa Stage"
                    style={{ width: 170 }}
                    options={visaChoices["visa_stage_choices"]?.map((role) => {
                      return {
                        label: role?.display_name,
                        value: role?.value,
                      };
                    })}
                    allowClear
                    size="large"
                  />
                </div>
                <div className="position-relative ">
                  <Select
                    value={filterData?.visa_status}
                    onChange={(value) => {
                      setFilterData((prevData) => ({
                        ...prevData,
                        visa_status: value,
                      }));
                    }}
                    placeholder="Select Visa Status"
                    style={{ width: 190 }}
                    options={visaChoices["visa_status_Choices"]?.map((role) => {
                      return {
                        label: role?.display_name,
                        value: role?.value,
                      };
                    })}
                    allowClear
                    size="large"
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className={`col-xxl-5`}>
          <div className="d-flex gap-3">
            {/* <div className="alert alert-dismissible bg-light-primary border border-primary border-dashed d-flex flex-column flex-sm-row w-100 p-3 align-items-center mb-0 py-2">
              <i className="ki-duotone ki-phone fs-1 text-primary me-4 mb-sm-0">
                <span className="path1" />
                <span className="path2" />
                <span className="path3" />
              </i>
              <div className="d-flex flex-column">
                <span className="fs-7 fw-bold">Apps</span>
                <h5 className="mb-0 fs-6 text-primary">
                  {dashboardData?.total_visa || 0}
                </h5>
              </div>
            </div> */}
            <div className="alert alert-dismissible bg-light-info border border-info border-dashed d-flex flex-column flex-sm-row w-100 p-3 align-items-center mb-0 py-2">
              <i className="ki-duotone ki-document fs-1 text-info me-4 mb-sm-0">
                <span className="path1" />
                <span className="path2" />
                <span className="path3" />
              </i>
              <div className="d-flex flex-column">
                <span className="fs-7 fw-bold">Visa Slips</span>
                <h5 className="mb-0 fs-6 text-info">
                  {dashboardData?.total_visa || 0}
                </h5>
              </div>
            </div>
            <div className="alert alert-dismissible bg-light-success border border-success border-dashed d-flex flex-column flex-sm-row w-100 p-3 align-items-center mb-0 py-2">
              <i className="ki-duotone ki-calendar-tick fs-1 text-success me-4 mb-sm-0">
                <span className="path1" />
                <span className="path2" />
                <span className="path3" />
                <span className="path4" />
                <span className="path5" />
                <span className="path6" />
              </i>
              <div className="d-flex flex-column">
                <span className="fs-7 fw-bold">Bookings</span>
                <h5 className="mb-0 fs-6 text-success">
                  {dashboardData?.booking_count || 0}
                </h5>
              </div>
            </div>
            <div className="alert alert-dismissible bg-light-dark border border-dark border-dashed d-flex flex-column flex-sm-row w-100 p-3 align-items-center mb-0 py-2">
              <i className="ki-duotone ki-abstract-26 fs-1 text-dark me-4 mb-sm-0">
                <span className="path1" />
                <span className="path2" />
              </i>
              <div className="d-flex flex-column">
                <span className="fs-7 fw-bold">Editions</span>
                <h5 className="mb-0 fs-6 text-dark">
                  {dashboardData?.edition_count || 0}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*end::Row*/}
      <div className="row g-5 g-xxl-5">
        <div className="col-md-12 ">
          {/* Tabs Navigation */}
          <ul className="nav nav-custom nav-pills fs-4 fw-semibold mb-0">
            {tabs.map((tab) => (
              <li className="nav-item cursor-pointer" key={tab.id}>
                <div
                  className={`nav-link text-gray-800 text-active-light fs-6 fw-bold py-3  ${
                    activeTab === tab.id ? "active" : ""
                  }`}
                  onClick={(e) => {
                    handleTabClick(tab.id);
                  }}
                >
                  {tab.title}
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="col-md-12">
          {/*begin:::Tab content*/}
          <div className="tab-content" id="myTabContent">
            {activeTab === 1 && (
              <>
                <Overview
                  dashboardLoading={dashboardLoading}
                  dashboardData={dashboardData}
                />{" "}
                {/*begin:::Tab pane*/}
              </>
            )}

            {activeTab == 2 && (
              <>
                {/*begin:::Tab pane*/}
                <div>
                  {/*begin::Card*/}
                  <div className="card card-flush mb-6 mb-xl-9 ">
                    {/*begin::Card header*/}
                    <div className="card-header">
                      {/*begin::Card title*/}
                      <div className="card-title flex-column">
                        <h2 className="mb-1">
                          {ongoingData?.length > 0 ? ongoingData.length : 0}{" "}
                          Visa Entries
                        </h2>
                      </div>
                      {/*end::Card title*/}
                      {/*begin::Card toolbar*/}
                      <div className="card-toolbar"></div>
                      {/*end::Card toolbar*/}
                    </div>
                    {/*end::Card header*/}
                    {/*begin::Card body*/}
                    {/*begin::Body*/}

                    <div className="card-body py-2 pb-7">
                      {/*begin::Table container*/}
                      {loading ? (
                        <div className="table-responsive">
                          <Loader cols={7} width={250} />
                        </div>
                      ) : (
                        <div>
                          {ongoingData?.length > 0 ? (
                            <>
                              {/*begin::Table container*/}
                              {/*begin::Table container*/}
                              <div className="table-responsive tbl-sales">
                                {/*begin::Table*/}
                                <table
                                  className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 border-top-d"
                                  id
                                >
                                  <thead>
                                    <tr className="fw-bolder text-gray-900 bg-light-primary">
                                      <th class="min-w-75px">Sr. No.</th>
                                      <th className="min-w-150px">Name</th>
                                      <th className="min-w-100px">Days Left</th>
                                      <th className="min-w-100px">Edition</th>
                                      <th className="min-w-150px">Status</th>
                                      <th className="min-w-150px">
                                        Visa Stage
                                      </th>
                                      <th className="min-w-150px">
                                        Appoinment
                                      </th>
                                      <th className="min-w-150px">
                                        Visa Status Upd.
                                      </th>
                                      <th className="min-w-100px">Fee</th>
                                      <th className="min-w-100px">Services</th>
                                      <th className="min-w-100px">Action</th>
                                    </tr>
                                  </thead>
                                  <tbody className=" fw-semibold fs-7">
                                    {ongoingData?.map((item, index) => {
                                      const currentDate = dayjs();

                                      const days =
                                      item?.edition_start_date
                                        ? dayjs(
                                            item?.edition_start_date
                                          ).diff(currentDate, "day")
                                        : 0;

                                      const visaStatusUpdated =
                                        item?.visa_status_updated_at
                                          ? dayjs(
                                              item?.visa_status_updated_at
                                            ).diff(currentDate, "day")
                                          : 0;

                                      // console.log(
                                      //   visaStatusUpdated,
                                      //   "visaStatusUpdated"
                                      // );

                                      return (
                                        <tr>
                                          <td>{index + 1}</td>
                                          <td>
                                            <div className="d-flex align-items-center">
                                              <div className="symbol symbol-circle symbol-40px overflow-hidden me-3">
                                                <img
                                                  src={`${
                                                    item?.user?.photo ||
                                                    "assets/media/blank.png"
                                                  }`}
                                                  alt=""
                                                />
                                              </div>
                                              {/*end::Avatar*/}
                                              {/*begin::User details*/}
                                              <div className="d-flex flex-column">
                                                <Link
                                                  to={`${routesList[
                                                    "customer_details"
                                                  ].path.replace(
                                                    ":id",
                                                    item.user.uuid
                                                  )}`}
                                                  className="text-gray-800 text-hover-primary mb-1 fw-bolder fs-6 min-w-100px"
                                                >
                                                  {item?.user?.first_name +
                                                    " " +
                                                    item?.user?.last_name}
                                                </Link>
                                              </div>
                                            </div>
                                          </td>
                                          <td>{`${days} Days`}</td>
                                          <td>{item?.edition_name || "-"}</td>
                                          <td>
                                            {item?.visa_status_label || "-"}
                                          </td>
                                          <td>
                                            <div className>
                                              <div
                                                className="fw-bold text-primary cursor-pointer"
                                                onClick={() => {
                                                  setSelectedOngoingData({
                                                    ...item,
                                                  });
                                                  setVisaStageModal(
                                                    !visaStageModal
                                                  );
                                                }}
                                              >
                                                {item?.visa_stage_label || "-"}
                                              </div>
                                              {/* <span className="fs-7 text-gray-600">
                                                {item?.visa_status_label || "-"}
                                              </span> */}
                                            </div>
                                          </td>
                                          <td>
                                            <DatePicker
                                              value={
                                                item?.preferred_appointment_date
                                                  ? dayjs(
                                                      item.preferred_appointment_date
                                                    )
                                                  : null
                                              }
                                              className="w-150px fs-6"
                                              disabledDate={(current) =>
                                                current &&
                                                current.isBefore(dayjs(), "day")
                                              }
                                              onChange={(date) => {
                                                handleAppoinmentDate(
                                                  item.id,
                                                  date
                                                );
                                              }}
                                            />

                                            {/* <EditableField
                                              value={dayjs(
                                                item?.preferred_appointment_date
                                              ).format("DD-MM-YYYY")}
                                              // onSave={handleAppoinmentDate}
                                              loading={loading}
                                              placeholder="Enter value"
                                              className="border form-control-sm w-150px fs-6"
                                              inputType="text"
                                            ></EditableField> */}
                                          </td>
                                          {/* <td>
                                            {dayjs(
                                              item?.preferred_appointment_date
                                            ).format("DD-MM-YYYY")}
                                          </td> */}
                                          <td>{`${visaStatusUpdated} days`}</td>
                                          <td>
                                            {formatNumber(item?.visa_fee) ||
                                              "-"}
                                          </td>
                                          <td>
                                            <span
                                              className={`badge flex-shrink-0 align-self-center py-3 px-4 fs-7 ${
                                                item?.visa_service == 1
                                                  ? "badge-light-success" // Green for 1
                                                  : item?.visa_service == 2
                                                  ? "badge-light-warning" // Yellow for 2
                                                  : item?.visa_service == 3
                                                  ? "badge-light-info" // Red for 3
                                                  : "badge-light-primary" // Default for any other case
                                              }`}
                                            >
                                              {item?.visa_service_label
                                                ? item?.visa_service_label
                                                : "-"}
                                            </span>
                                          </td>

                                          <td>
                                            <div className="d-flex flex-shrink-0 ">
                                              <div>
                                                {item?.user.phone ? (
                                                  <Popover
                                                    content={
                                                      <>
                                                        <div>
                                                          <Button
                                                            type="link"
                                                            className="text-dark fw-bold"
                                                            onClick={() => {
                                                              setWhatsAppTemplateModal(
                                                                true
                                                              );
                                                              setSelectedModalData(
                                                                item
                                                              );
                                                            }}
                                                          >
                                                            WhatsApp Template
                                                          </Button>
                                                          <br />
                                                          <Button
                                                            type="link"
                                                            className="text-dark fw-bold"
                                                            href={`https://wa.me/${item?.user?.phone}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                          >
                                                            Open in WhatsApp
                                                          </Button>
                                                        </div>
                                                      </>
                                                    }
                                                    trigger="hover"
                                                  >
                                                    <div className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3">
                                                      <i
                                                        className="bi bi-whatsapp fs-4 text-success"
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                      />
                                                    </div>
                                                  </Popover>
                                                ) : (
                                                  <div
                                                    className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                                                    disabled
                                                    style={{
                                                      cursor: "not-allowed",
                                                      opacity: "0.5",
                                                    }}
                                                  >
                                                    <i className="bi bi-whatsapp fs-2 text-success" />
                                                  </div>
                                                )}
                                              </div>
                                              <div className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3">
                                                <i
                                                  className="bi bi-telephone fs-4 text-info"
                                                  style={{ cursor: "pointer" }}
                                                  onClick={() => {
                                                    setSelectedModalData(item);
                                                    setIsMakeCallModalOpen(
                                                      true
                                                    );
                                                  }}
                                                />
                                              </div>

                                              {/* <a
                                                data-bs-toggle="modal"
                                                data-bs-target="#kt_modal_visa"
                                                href="#"
                                                className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                                              >
                                                <i className="ki-outline ki-address-book fs-2 text-gray-900" />
                                              </a> */}
                                              {/* <a
                                                data-bs-toggle="modal"
                                                data-bs-target="#kt_modal_edition_update"
                                                href="#"
                                                className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                                              >
                                                <i className="ki-outline ki-file fs-2 text-primary" />
                                              </a> */}

                                              <Tooltip title="Passport Check">
                                                <div
                                                  className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm  me-3"
                                                  onClick={() => {
                                                    setSelectedModalData({
                                                      ...item,
                                                    });
                                                    setPassportInfoModal(
                                                      !passportInfoModal
                                                    );
                                                  }}
                                                >
                                                  <i
                                                    className={`${
                                                      item?.is_passport
                                                        ? "ki-solid"
                                                        : "ki-outline"
                                                    } ki-password-check fs-2 text-danger`}
                                                  ></i>
                                                </div>
                                              </Tooltip>
                                              <Tooltip title="Flight">
                                                <div
                                                  className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm  me-3"
                                                  onClick={() => {
                                                    // const data ={edition_id: item?.edition, user_id: item?.user?.uuid};
                                                    setSelectedModalData({
                                                      ...item,
                                                    });
                                                    setFlightModal(
                                                      !flightModal
                                                    );
                                                  }}
                                                >
                                                  {/* <i
                                                    className={`${
                                                      item?.is_flight_ticket
                                                        ? "ki-solid"
                                                        : "ki-outline"
                                                    } ki-airplane fs-2 text-warning`}
                                                  ></i>{" "} */}
                                                  <i className="ki-outline ki-airplane fs-2 text-info"></i>{" "}
                                                </div>
                                              </Tooltip>

                                              <Tooltip title="Visa Related Notes">
                                                <div
                                                  className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm  me-3"
                                                  onClick={() => {
                                                    setVisaRelatedFlag(true);
                                                    setSelectedModalData(item);
                                                    setShowNotesDrawer(
                                                      !showNotesDrawer
                                                    );
                                                  }}
                                                >
                                                  <i className="ki-outline ki-notepad fs-2 text-primary"></i>{" "}
                                                  {/* Changed to text-secondary */}
                                                </div>
                                              </Tooltip>
                                            </div>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                                {/*end::Table*/}
                              </div>
                              {/*end::Table container*/}

                              <div className="separator separator-dashed border-gray-200" />
                              <Pagination
                                setLimit={setLimit}
                                limit={limit}
                                data={ongoingData}
                                activePage={activePage}
                                totalRecords={totalRecords}
                                onChange={(pageNumber) => {
                                  setLoading(true);
                                  setActivePage(pageNumber);
                                }}
                              />
                            </>
                          ) : (
                            <div className="row g-5 mb-0 gx-md-10">
                              <div className="col-md-12 text-center">
                                <div className="mt-0">
                                  <img
                                    src="/media/no-data-found.png"
                                    className="mw-25"
                                    alt="No Data Found"
                                  />
                                </div>
                                <h1 className="mt-4">No Data Found</h1>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/*end:::Tab pane*/}
              </>
            )}

            {activeTab == 3 && (  
              <>
                <div>
                  {/*begin::Card*/}
                  <div className="card card-flush mb-6 mb-xl-9 ">
                    {/*begin::Card header*/}
                    <div className="card-header">
                      {/*begin::Card title*/}
                      <div className="card-title flex-column">
                        <h2 className="mb-1">
                          {ongoingData?.length > 0 ? ongoingData.length : 0}{" "}
                          Visa Entries
                        </h2>
                      </div>
                      {/*end::Card title*/}
                      {/*begin::Card toolbar*/}
                      <div className="card-toolbar"></div>
                      {/*end::Card toolbar*/}
                    </div>
                    {/*end::Card header*/}
                    {/*begin::Card body*/}
                    {/*begin::Body*/}

                    <div className="card-body py-2 pb-7">
                      {/*begin::Table container*/}
                      {loading ? (
                        <div className="table-responsive">
                          <Loader cols={7} width={250} />
                        </div>
                      ) : (
                        <div>
                          {ongoingData?.length > 0 ? (
                            <>
                              {/*begin::Table container*/}
                              {/*begin::Table container*/}
                              <div className="table-responsive tbl-sales">
                                {/*begin::Table*/}
                                <table
                                  className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 border-top-d"
                                  id
                                >
                                  <thead>
                                    <tr className="fw-bolder text-gray-900 bg-light-primary">
                                      <th class="min-w-75px">Sr. No.</th>
                                      <th className="min-w-150px">Name</th>
                                      <th className="min-w-100px">Days Left</th>
                                      <th className="min-w-200px">Edition</th>
                                      <th className="min-w-150px">Status</th>
                                      <th className="min-w-150px">
                                        Visa Stage
                                      </th>
                                      <th className="min-w-150px">
                                        Appoinment
                                      </th>
                                      <th className="min-w-150px">
                                        Visa Status Upd.
                                      </th>
                                      <th className="min-w-100px">Fee</th>
                                      <th className="min-w-100px">Services</th>
                                      <th className="min-w-100px">Action</th>
                                    </tr>
                                  </thead>
                                  <tbody className=" fw-semibold fs-7">
                                    {ongoingData?.map((item, index) => {
                                      const currentDate = dayjs();

                                      const days = item?.edition_start_date
                                        ? dayjs(item?.edition_start_date).diff(
                                            currentDate,
                                            "day"
                                          )
                                        : 0;

                                       
                                     
                                      const visaStatusUpdated =
                                        item?.visa_status_updated_at
                                          ? dayjs(
                                              item?.visa_status_updated_at
                                            ).diff(currentDate, "day")
                                          : 0;

                                      // console.log(
                                      //   visaStatusUpdated,
                                      //   "visaStatusUpdated"
                                      // );

                                      return (
                                        <tr>
                                          <td>{index + 1}</td>
                                          <td>
                                            <div className="d-flex align-items-center">
                                              <div className="symbol symbol-circle symbol-40px overflow-hidden me-3">
                                                <img
                                                  src={`${
                                                    item?.user?.photo ||
                                                    "assets/media/blank.png"
                                                  }`}
                                                  alt=""
                                                />
                                              </div>
                                              {/*end::Avatar*/}
                                              {/*begin::User details*/}
                                              <div className="d-flex flex-column">
                                                <Link
                                                  to={`${routesList[
                                                    "customer_details"
                                                  ].path.replace(
                                                    ":id",
                                                    item.user.uuid
                                                  )}`}
                                                  className="text-gray-800 text-hover-primary mb-1 fw-bolder fs-6 min-w-100px"
                                                >
                                                  {item?.user?.first_name +
                                                    " " +
                                                    item?.user?.last_name}
                                                </Link>
                                              </div>
                                            </div>
                                          </td>
                                          <td>{item?.days_left || "-"}</td>
                                          <td>{item?.edition_name || "-"}</td>
                                          <td>
                                            {item?.visa_status_label || "-"}
                                          </td>
                                          <td>
                                            <div className>
                                              <div
                                                className="fw-bold text-primary cursor-pointer"
                                                onClick={() => {
                                                  setSelectedOngoingData({
                                                    ...item,
                                                  });
                                                  setVisaStageModal(
                                                    !visaStageModal
                                                  );
                                                }}
                                              >
                                                {item?.visa_stage_label || "-"}
                                              </div>
                                              {/* <span className="fs-7 text-gray-600">
                                                {item?.visa_status_label || "-"}
                                              </span> */}
                                            </div>
                                          </td>
                                          <td>
                                            {dayjs(
                                              item?.preferred_appointment_date
                                            ).format("DD-MM-YYYY")}
                                          </td>
                                          <td>{`${visaStatusUpdated} days`}</td>
                                          <td>
                                            {formatNumber(item?.visa_fee) ||
                                              "-"}
                                          </td>
                                          <td>
                                            <span
                                              className={`badge flex-shrink-0 align-self-center py-3 px-4 fs-7 ${
                                                item?.visa_service == 1
                                                  ? "badge-light-success" // Green for 1
                                                  : item?.visa_service == 2
                                                  ? "badge-light-warning" // Yellow for 2
                                                  : item?.visa_service == 3
                                                  ? "badge-light-info" // Red for 3
                                                  : "badge-light-primary" // Default for any other case
                                              }`}
                                            >
                                              {item?.visa_service_label
                                                ? item?.visa_service_label
                                                : "-"}
                                            </span>
                                          </td>

                                          <td>
                                            <div className="d-flex flex-shrink-0 ">
                                              <a
                                                data-bs-toggle="modal"
                                                data-bs-target="#kt_modal_visa"
                                                href="#"
                                                className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                                              >
                                                <i className="ki-outline ki-address-book fs-2 text-gray-900" />
                                              </a>
                                              <a
                                                data-bs-toggle="modal"
                                                data-bs-target="#kt_modal_edition_update"
                                                href="#"
                                                className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                                              >
                                                <i className="ki-outline ki-file fs-2 text-primary" />
                                              </a>
                                              <a
                                                id="kt_drawer_example_permanent_toggle"
                                                href="#"
                                                className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm me-3"
                                              >
                                                <i className="ki-outline ki-airplane fs-2 text-gray-900" />
                                              </a>
                                              <a
                                                id="kt_drawer_example_toggle"
                                                href="#"
                                                className="btn btn-icon box-shadow-lg border bg-white btn-active-color-primary btn-sm"
                                              >
                                                <i className="ki-outline ki-notepad fs-2 text-gray-900" />
                                              </a>
                                            </div>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                                {/*end::Table*/}
                              </div>
                              {/*end::Table container*/}

                              <div className="separator separator-dashed border-gray-200" />
                              <Pagination
                                setLimit={setLimit}
                                limit={limit}
                                data={ongoingData}
                                activePage={activePage}
                                totalRecords={totalRecords}
                                onChange={(pageNumber) => {
                                  setLoading(true);
                                  setActivePage(pageNumber);
                                }}
                              />
                            </>
                          ) : (
                            <div className="row g-5 mb-0 gx-md-10">
                              <div className="col-md-12 text-center">
                                <div className="mt-0">
                                  <img
                                    src="/media/no-data-found.png"
                                    className="mw-25"
                                    alt="No Data Found"
                                  />
                                </div>
                                <h1 className="mt-4">No Data Found</h1>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* <Completed /> */}
              </>
            )}

            {activeTab == 4 && (
              <>
                {" "}
                <Active />
              </>
            )}

            {activeTab == 5 && (
              <>
                <CrossCheck />
              </>
            )}
          </div>
        </div>
      </div>

      {visaStageModal && (
        <VisaStageModal
          show={visaStageModal}
          onHide={() => {
            setVisaStageModal(false);
            // setLoading(true);
            getData();
          }}
          data={selectedOngoingData}
        />
      )}

      {flightModal && (
        <FlightModal
          show={flightModal}
          onHide={() => {
            setFlightModal(false);
            // setLoading(true);
            // getData();
          }}
          onSuccess={() => {
            getData();
          }}
          data={selectedModalData}
        />
      )}

      {showNotesDrawer && (
        <NotesDrawer
          show={showNotesDrawer}
          onHide={() => {
            setSelectedModalData({});
            setShowNotesDrawer(false);
            setVisaRelatedFlag(false);
            // setLoading(true);
            // getData();
          }}
          data={selectedModalData}
          notesOptions={notesOptions}
          visaRelatedFlag={visaRelatedFlag}
        />
      )}

      {passportInfoModal && (
        <PassportCheckModal
          show={passportInfoModal}
          onHide={() => {
            setPassportInfoModal(false);
            // setLoading(true);
            // getData();
          }}
          data={selectedModalData}
        />
      )}

      {isMakeCallModalOpen && (
        <MakeCallModal
          show={isMakeCallModalOpen}
          onHide={() => {
            setIsMakeCallModalOpen(false);
          }}
          data={{
            to_number: selectedModalData?.user?.phone,
          }}
        />
      )}

      {whatsappTemplateModal && (
        <WhatsappTemplateModal
          show={whatsappTemplateModal}
          onHide={() => {
            setWhatsAppTemplateModal(false);
          }}
          data={{
            user_data: selectedModalData?.user,
          }}
        />
      )}
    </div>
  );
}
