import React, { useEffect, useState } from "react";
import { fetchData } from "../../../../config/service";
import toast from "react-hot-toast";
import { LIMITS } from "../../../../config";
import { Loader, Pagination } from "../../../../components";
import { Button, Modal, Form } from "react-bootstrap";
import { Select, Spin } from "antd";
import { validateFields } from "../../../../components/validation";

const InvoiceData = ({ userId, edition_id, editionData, userData }) => {
  const [limit, setLimit] = useState(LIMITS[0]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [editingInvoice, setEditingInvoice] = useState(null);
  const [formData, setFormData] = useState({
    name_on_invoice: "",
    country_name: null,
    state_name: null,
    city_name: null,
    address: "",
    pin_code: "",
    gst_num: "",
    pan_num: "",
  });

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [regions, setRegions] = useState([]);

  const [countryLoading, setCountryLoading] = useState(false);
  const [stateLoading, setStateLoading] = useState(false);
  const [cityLoading, setCityLoading] = useState(false);

  const [regionLoading, setRegionLoading] = useState(false);
  const [regionFilter, setRegionFilter] = useState(null);

  const [citySearch, setCitySearch] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(citySearch);
  const [customCity, setCustomCity] = useState(null);

  const [sending, setSending] = useState(false);

  useEffect(() => {
    if (edition_id) {
      getData();
    }
  }, [edition_id]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(citySearch);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [citySearch]);

  useEffect(() => {
    if (showModal) {
      getCountries();
      getRegions();
    }
  }, [showModal]);

  useEffect(() => {
    if (formData?.country_name) {
      getStates();
      // getCities();
    }
  }, [formData?.country_name]);

  // useEffect(() => {
  //   if (formData?.state) {
  //     getCities();
  //   }
  // }, [activePage, formData?.country_name]);

  useEffect(() => {
    if (formData?.country_name) {
      getCities(editingInvoice?.city_name);
    }
  }, [activePage, formData?.country_name, debouncedSearchTerm]);

  const getCountries = () => {
    setCountryLoading(true);
    fetchData(`api/country/?is_active=1&limit=${300}&offset=${0}`).then(
      (res) => {
        setCountryLoading(false);
        if (res.success) {
          setCountries(res.data);
        } else {
          setCountries([]);
          toast.error(res.message);
        }
      }
    );
  };

  const getStates = () => {
    setStateLoading(true);
    fetchData(
      `api/country/state/?is_active=1&limit=${300}&offset=${0}&country_id=${
        formData?.country_name
      }`
    ).then((res) => {
      setStateLoading(false);
      if (res.success) {
        setStates(res.data);
      } else {
        setStates([]);
        toast.error(res.message);
      }
    });
  };

  const getCities = (search = "") => {
    const newSearch = debouncedSearchTerm || search;

    const limitNew = limit;
    const offsetNew = (activePage - 1) * limit;

    setCityLoading(true);

    fetchData(
      // `api/country/state/city/?is_active=1&limit=${300}&offset=${0}&state_id=${
      //   formData.state
      // }`
      `api/country/state/city/?is_active=1&limit=${limitNew}&offset=${offsetNew}&country_id=${
        formData?.country_name
      }&search=${encodeURIComponent(newSearch || "")}`
    ).then((res) => {
      setCityLoading(false);
      if (res.success) {
        const newData = res.data?.map((e) => {
          return {
            label: e?.name,
            value: e?.id,
          };
        });

        console.log(res, newData, "newData  __data");

        setCities((prevData) => {
          // Combine previous records with new records
          const combinedData = [...(prevData || []), ...(newData || [])];

          // Remove duplicates based on value (ID)
          const uniqueData = Array.from(
            new Set(combinedData.map((item) => item.value))
          ).map((id) => combinedData.find((item) => item.value === id));

          console.log(uniqueData, "uniqueData  __data");

          return uniqueData;
        });

        // setCities(res.data);
      } else {
        setCities([]);
        toast.error(res.message);
      }
    });
  };

  const getRegions = () => {
    let param = "";

    let offset = (activePage - 1) * 50;
    param += `?limit=${50}&offset=${offset}`;

    setLoading(true);

    fetchData(`api/region/${param}`).then((res) => {
      setLoading(false);
      if (res.success) {
        const newData = res.data?.map((r) => {
          return {
            label: r?.name,
            value: r?.id,
          };
        });

        setRegions(newData);
      } else {
        setRegions([]);
        toast.error(res.message);
      }
    });
  };

  const getData = () => {
    setLoading(true);
    fetchData(
      `api/payment/invoices-list-create/?user=${userId}&edition=${edition_id}&limit=${limit}&offset=${
        (activePage - 1) * limit
      }`,
      "get"
    ).then((res) => {
      setLoading(false);
      if (res.success) {
        setData(res.data);
        setTotalRecords(res.count);
      } else {
        toast.error(res.message);
      }
    });
  };

  const handleShowModal = (invoice = null) => {
    setEditingInvoice(invoice);
    setShowModal(true);
    if (invoice) {
      setFormData({
        name_on_invoice: invoice?.name_on_invoice,
        country_name: invoice.country,
        state_name: invoice.state,
        city_name: invoice.city,
        address: invoice?.address,
        pin_code: invoice?.pin_code,
        gst_num: invoice?.gst_num,
        pan_num: invoice?.pan_num,
      });
    } else {
      setFormData({
        name_on_invoice: "",
        country_name: null,
        state_name: null,
        city_name: null,
        address: "",
        pin_code: "",
        gst_num: "",
        pan_num: "",
      });
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEditingInvoice(null);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validationRules = {
    name_on_invoice: {
      required: false,
    },
    country_name: {
      required: false,
    },
    state_name: {
      required: false,
    },
    city_name: {
      required: false,
    },
    address: {
      required: false,
    },
    pin_code: {
      required: false,
      pattern: /^[0-9]{6}$/,
    },
    gst_num: {
      required: false,
      pattern: /^[0-9]{15}$/,
    },
    pan_num: {
      required: false,
      pattern: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
    },
  };

  const handleSubmit = () => {
    const { isValid } = validateFields(formData, validationRules);

    if (!isValid) {
      return;
    } else {
      if (editingInvoice?.id) {
        toast.loading("Updating...");
      } else {
        toast.loading("Adding...");
      }
    }

    setSending(true);

    const body = {
      ...(formData.name_on_invoice && {
        name_on_invoice: formData.name_on_invoice,
      }),
      ...(formData.country_name && { country: formData.country_name }),
      ...(formData.state_name && { state: formData.state_name }),
      ...(formData.city_name && { city: formData.city_name }),
      ...(formData.address && { address: formData.address }),
      ...(formData.pin_code && { pin_code: formData.pin_code }),
      ...(formData.gst_num && { gst_num: formData.gst_num }),
      ...(formData.pan_num && { pan_num: formData.pan_num }),

      // Additional fixed fields
      edition: editionData?.edition,
      // order_id: "orderd3r4bawer234",
      // payment_id: "pay_PVNsiz59BWsgDZ",
      user: editionData?.user?.uuid,
    };

    console.log(body, "body");

    let method = editingInvoice?.id ? "put" : "post";
    let api = editingInvoice?.id
      ? `api/payment/invoices-details/${editingInvoice.id}/`
      : "api/payment/invoices-list-create/";

    fetchData(api, method, body, "", true).then((res) => {
      setSending(false);
      toast.dismiss();
      if (res.success) {
        toast.success("Success");
        handleCloseModal();
        getData();
      } else {
        toast.error(res.message);
      }
    });
  };

  const handleSubmit1 = () => {
    if (editingInvoice) {
      // Edit API call
      fetchData(
        `api/payment/invoices-update/${editingInvoice.id}/`,
        "put",
        formData
      ).then((res) => {
        if (res.success) {
          toast.success("Invoice updated successfully!");
          getData();
          handleCloseModal();
        } else {
          toast.error(res.message);
        }
      });
    } else {
      // Add API call
      fetchData("api/payment/invoices-list-create/", "post", formData).then(
        (res) => {
          if (res.success) {
            toast.success("Invoice added successfully!");
            getData();
            handleCloseModal();
          } else {
            toast.error(res.message);
          }
        }
      );
    }
  };

  const handleSelectChange = (name, value) => {
    setFormData((prevState) => {
      if (name === "country_name") {
        return {
          ...prevState,
          country_name: value,
          state_name: null,
          city_name: null,
        };
      } else if (name === "state_name") {
        return {
          ...prevState,
          state_name: value,
          city_name: null,
        };
      } else {
        return {
          ...prevState,
          [name]: value,
        };
      }
    });
  };

  const [inputValue, setInputValue] = useState("");

  const options = [
    ...cities,
    ...(inputValue && !cities.some((e) => e.name === inputValue)
      ? [{ label: inputValue, value: inputValue }]
      : []),
  ];

  return (
    <div className="card card-flush mb-6 mb-xl-9">
      <div className="card-header">
        <div className="card-title flex-column">
          <h2 className="mb-1">Data for Invoices</h2>
        </div>

        <div className="card-toolbar">
          <button
            className="btn btn-primary fs-7 fw-bold"
            onClick={() => handleShowModal()}
          >
            <i className="ki-outline ki-plus-square fs-2" /> Add New Invoice
          </button>
        </div>
      </div>

      <div className="card-body p-9 pt-2">
        <div className="card-body py-2 pb-7">
          {loading ? (
            <div className="table-responsive">
              <Loader cols={7} width={250} />
            </div>
          ) : (
            <div>
              {data?.length > 0 ? (
                <>
                  <div className="table-responsive tbl-sales">
                    <table className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 border-top-d">
                      <thead>
                        <tr className="fw-bolder text-gray-900 bg-light-primary">
                          <th className="min-w-200px">Name on Invoice</th>
                          <th className="min-w-125px">Country</th>
                          <th className="min-w-125px">State</th>
                          <th className="min-w-125px">City</th>
                          <th className="min-w-125px">Address</th>
                          <th className="min-w-100px">PIN Code</th>
                          <th className="min-w-125px">GST Number</th>
                          <th className="min-w-125px">PAN Number</th>
                          <th className="min-w-100px">Actions</th>
                        </tr>
                      </thead>
                      <tbody className="fw-semibold fs-7">
                        {data.map((item) => (
                          <tr key={item.id}>
                            <td>{item.name_on_invoice}</td>
                            <td>{item.country_name}</td>
                            <td>{item.state_name}</td>
                            <td>{item.city_name}</td>
                            <td>{item.address}</td>
                            <td>{item.pin_code}</td>
                            <td>{item.gst_num}</td>
                            <td>{item.pan_num}</td>
                            <td>
                              <div className="d-flex flex-shrink-0 justify-content-start">
                                <span
                                  className="btn btn-icon bg-light btn-active-color-primary btn-sm cursor-pointer me-2"
                                  onClick={() => handleShowModal(item)}
                                >
                                  <i className="ki-outline ki-pencil fs-2 text-info" />
                                </span>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="separator separator-dashed border-gray-200" />
                  <Pagination
                    setLimit={setLimit}
                    limit={limit}
                    data={data}
                    activePage={activePage}
                    totalRecords={totalRecords}
                    onChange={(pageNumber) => {
                      setLoading(true);
                      setActivePage(pageNumber);
                    }}
                  />
                </>
              ) : (
                <div className="row g-5 mb-0 gx-md-10">
                  <div className="col-md-12 text-center">
                    <div className="mt-0">
                      <img
                        src="/media/no-data-found.png"
                        className="mw-25"
                        alt="No Data Found"
                      />
                    </div>
                    <h1 className="mt-4">No Data Found</h1>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {/* Modal for Add/Edit */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {editingInvoice ? "Edit Invoice" : "Add Invoice"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row g-9 align-items-center">
            <div className="col-md-6">
              <label className="required fw-semibold fs-5">
                Name on Invoice
              </label>
              <input
                type="text"
                className="form-control fs-7"
                name="name_on_invoice"
                value={formData.name_on_invoice}
                onChange={handleChange}
                placeholder="Enter Name"
              />
            </div>
            <div className="col-md-6 fv-row ">
              <label className="fs-6 required fw-bold mb-2">Country</label>
              <Select
                showSearch
                optionFilterProp="label"
                name="country"
                value={formData.country_name}
                onChange={(value) => handleSelectChange("country_name", value)}
                placeholder="Select Country"
                className="fs-7 w-100"
                style={{ width: "100%" }}
                dropdownStyle={{ zIndex: 1100 }}
                size="large"
                options={countries?.map((e) => {
                  return { label: e.name, value: e.id };
                })}
                loading={countryLoading}
              />
            </div>

            <div className="col-md-6 fv-row">
              <label className="fs-6 required fw-bold mb-2">State</label>
              <Select
                showSearch
                optionFilterProp="label"
                name="state"
                value={formData.state_name}
                onChange={(value) => handleSelectChange("state_name", value)}
                placeholder="Select State"
                className="fs-7 w-100"
                style={{ width: "100%" }}
                dropdownStyle={{ zIndex: 1100 }}
                size="large"
                options={states?.map((e) => {
                  return { label: e.name, value: e.id };
                })}
                loading={stateLoading}
              />
            </div>

            <div className="col-md-6 fv-row">
              <label className="fs-6 required fw-bold mb-2">City</label>
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                name="city"
                value={!cityLoading ? formData.city_name : null}
                showSearch
                onSearch={(value) => {
                  setCitySearch(value);
                }}
                // onSearch={handleSearch}
                optionFilterProp="label"
                onChange={(value) => {
                  const hexRegex = /^[a-f0-9]{24}$/; // Matches 24-character hexadecimal strings

                  if (value && !hexRegex.test(value)) {
                    setCustomCity(value);
                    handleSelectChange("city_name", value);
                  } else {
                    handleSelectChange("city_name", value);
                  }
                }}
                placeholder="Select City"
                className="fs-7 w-100"
                style={{ width: "100%" }}
                options={options}
                allowClear
                onClear={() => {
                  handleSelectChange("city", null);
                  setActivePage(1);
                }}
                onPopupScroll={(e) => {
                  const bottom =
                    Math.abs(
                      e.target.scrollHeight -
                        (e.target.scrollTop + e.target.clientHeight)
                    ) < 1.5;

                  console.log(bottom, "bottom");

                  if (bottom) {
                    setCityLoading(true);
                    setActivePage((prevState) => prevState + 1);
                  }
                }}
                notFoundContent={
                  <>
                    <div className="d-flex justify-content-center align-items-center">
                      {cityLoading ? <Spin size="small" /> : "No Data Found"}
                    </div>
                  </>
                }
                size="large"
                loading={cityLoading}
              ></Select>
            </div>

            <div className="col-12 mt-3">
              <label className="required fw-semibold fs-5">Address</label>
              <textarea
                className="form-control fs-7"
                name="address"
                value={formData.address}
                onChange={handleChange}
                placeholder="Enter Address"
                rows="2"
              />
            </div>

            <div className="col-md-6 mt-3">
              <label className="required fw-semibold fs-5">PIN Code</label>
              <input
                type="text"
                className="form-control fs-7"
                name="pin_code"
                value={formData.pin_code}
                onChange={handleChange}
                placeholder="Enter PIN Code"
              />
            </div>

            <div className="col-md-6 mt-3">
              <label className="required fw-semibold fs-5">GST Number</label>
              <input
                type="text"
                className="form-control fs-7"
                name="gst_num"
                value={formData.gst_num}
                onChange={handleChange}
                placeholder="Enter GST Number"
              />
            </div>

            <div className="col-md-12 mt-3">
              <label className="required fw-semibold fs-5">PAN Number</label>
              <input
                type="text"
                className="form-control fs-7"
                name="pan_num"
                value={formData.pan_num}
                onChange={handleChange}
                placeholder="Enter PAN Number"
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className="btn btn-light fs-7"
            onClick={handleCloseModal}
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary fs-7"
            onClick={handleSubmit}
            disabled={sending}
          >
            {sending ? (
              <span>
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2" />
              </span>
            ) : (
              <>{editingInvoice ? "Update" : "Add"}</>
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default InvoiceData;
