import React, { useEffect, useState } from "react";
import { fetchData } from "../../../config/service";
import toast from "react-hot-toast";
import { LIMITS } from "../../../config";
import { Alert, Loader, Pagination } from "../../../components";
import { Image, Select } from "antd";
import Destinations from "./modals/destinaitons";
import DebouncedSelect from "../../../components/debounced-select";

export default function Destination() {
  const [isEdit, setIsEdit] = useState(false);
  const [data, setData] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [region, setRegion] = useState(null);
  const [visaRequired, setVisaRequired] = useState(null);
  const [loading, setLoading] = useState(false);
  const [regionLoading, setRegionLoading] = useState(false);

  const [sending, setSending] = useState(false);
  const [selected, setSelected] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [limit, setLimit] = useState(LIMITS[0]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [regions, setRegions] = useState([]);
  const [regionFilter, setRegionFilter] = useState(null);

  const [destinationName, setDestinationName] = useState("");
  const [images, setImages] = useState({
    mainImage: null,
  });
  const [imagePreviews, setImagePreviews] = useState({
    mainImagePreview: null,
  });
  const [destinationModal, setDestinationModal] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 700); // 700ms debounce

    // Cleanup the timeout if searchTerm changes (before 500ms)
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    // getCountries();
    getRegions();
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      getStates();
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState) {
      getCities();
    }
  }, [selectedState]);

  useEffect(() => {
    getData();
  }, [limit, activePage, regionFilter, debouncedSearchTerm]);

  const getData = () => {
    let param = "";

    let offset = (activePage - 1) * limit;
    param += `?limit=${limit}&offset=${offset}`;

    setLoading(true);
    // Conditionally add google_rating if it exists
    if (regionFilter) {
      param += `&region=${encodeURIComponent(regionFilter)}`;
    }

    // Add search query if it exists
    if (debouncedSearchTerm) {
      param += `&search=${encodeURIComponent(debouncedSearchTerm || "")}`;
    }

    fetchData(`api/destination/${param}`).then((res) => {
      setLoading(false);
      if (res.success) {
        setData(res.data);
        setTotalRecords(res.count);
      } else {
        setData([]);
        toast.error(res.message);
      }
    });
  };

  // useEffect(() => {
  //   if (selected?.id && isEdit) {
  //     console.log(selected, "selected");
  //     setImagePreviews({
  //       mainImagePreview: selected?.image,
  //     });
  //     setImages({
  //       mainImage: selected?.image,
  //     });
  //     setDestinationName(selected?.name);
  //     setSelectedCountry(selected.country);
  //     setSelectedState(selected.state);
  //     setSelectedCity(selected.city);
  //     setRegion(selected.region);
  //     setVisaRequired(selected.is_visa);
  //   }
  // }, [selected, isEdit]);

  const clearInputs = () => {
    console.log("clearInputs");
    setDestinationName("");
    setSelectedCountry(null);
    setSelectedState(null);
    setSelectedCity(null);
    setRegion(null);
    setVisaRequired(null);
    setImagePreviews({
      mainImagePreview: null,
    });
    setImages({
      mainImage: null,
    });
    setSelected({});
    setIsEdit(false);
  };

  const getCountries = () => {
    fetchData(`api/country/?is_active=1&limit=${300}&offset=${0}`).then(
      (res) => {
        if (res.success) {
          setCountries(res.data);
        } else {
          setCountries([]);
          toast.error(res.message);
        }
      }
    );
  };

  const getStates = () => {
    fetchData(
      `api/country/state/??is_active=1&limit=${300}&offset=${0}&country_id=${selectedCountry}`
    ).then((res) => {
      if (res.success) {
        setStates(res.data);
      } else {
        setStates([]);
        toast.error(res.message);
      }
    });
  };

  const getCities = () => {
    fetchData(
      `api/country/state/city/?is_active=1&limit=${300}&offset=${0}&state_id=${selectedState}`
    ).then((res) => {
      if (res.success) {
        setCities(res.data);
      } else {
        setCities([]);
        toast.error(res.message);
      }
    });
  };

  const getRegions = () => {
    let param = "";

    let offset = (activePage - 1) * limit;
    param += `?limit=${limit}&offset=${offset}`;

    setRegionLoading(true);

    fetchData(`api/region/${param}`).then((res) => {
      setRegionLoading(false);
      if (res.success) {
        const newData = res.data?.map((r) => {
          return {
            label: r?.name,
            value: r?.id,
          };
        });

        setRegions(newData);
      } else {
        setRegions([]);
        toast.error(res.message);
      }
    });
  };

  const onSubmit = () => {
    if (
      !images?.mainImage ||
      !selectedCountry ||
      !selectedState ||
      !selectedState ||
      !region ||
      !destinationName ||
      typeof visaRequired !== "boolean"
    ) {
      if (!images?.mainImage) {
        toast.error("Image is Required");
      } else if (!selectedCountry) {
        toast.error("Please select country!");
      } else if (!selectedState) {
        toast.error("Please select state!");
      } else if (!selectedCity) {
        toast.error("Please select city!");
      } else if (!region) {
        toast.error("Please enter your region!");
      } else if (!destinationName) {
        toast.error("Please enter your Destination!");
      } else if (typeof visaRequired !== "boolean") {
        toast.error("Please select visa required!");
      }
    } else {
      setSending(true);

      const isURL = (str) => {
        try {
          new URL(str);
          return true;
        } catch {
          return false;
        }
      };

      const body = {
        name: destinationName,
        region: region,
        is_visa: visaRequired,
        country: selectedCountry,
        state: selectedState,
        city: selectedCity,
        ...(images?.mainImage && isURL(images?.mainImage)
          ? {}
          : { image: images?.mainImage || "" }),
      };

      console.log(body, "body");
      let method = selected?.id ? "put" : "post";
      let api = selected?.id
        ? `api/destination/${selected.id}/`
        : "api/destination/";
      fetchData(api, method, body, "", true).then((res) => {
        setSending(false);
        if (res.success) {
          toast.success("Success");
          clearInputs();
          getData();
        } else {
          toast.error(res.message);
        }
      });
    }
  };

  const deleteItem = () => {
    fetchData(`api/destination/${selected.id}`, "delete").then((res) => {
      toast.dismiss();
      if (res.success) {
        getData();
        toast.success("Record deleted successfully");
      } else {
        toast.error(res.message);
      }
    });
  };

  const handleImagesChange = (e, imageType) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      const reader = new FileReader();
      reader.onload = () => {
        // Update the state with the file and its preview
        setImages((prevImages) => ({
          ...prevImages,
          [imageType]: file,
        }));

        setImagePreviews((prevImagePreviews) => ({
          ...prevImagePreviews,
          [`${imageType}Preview`]: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImages = (imageType) => {
    setImages((prevImages) => ({
      ...prevImages,
      [imageType]: null,
    }));

    setImagePreviews((prevImagePreviews) => ({
      ...prevImagePreviews,
      [`${imageType}Preview`]: null,
    }));
  };

  const regionOptions = [
    { label: "North", value: "North" },
    { label: "South", value: "South" },
  ];

  return (
    <div>
      <div className="row g-5 g-xxl-10">
        {/* <div className="col-xxl-4 ">
          <div
            className="card mb-5 mb-xl-8"
            style={{ position: "sticky", top: "50px" }}
          >
            <div className="card-header ">
              <h3 className="card-title fw-bold mb-0 fs-3 d-flex align-items-center mt-0">
                {isEdit ? "Edit Destination" : "Add New Destination"}
              </h3>
              <div className="card-toolbar">
                {isEdit && (
                  <span
                    className="btn btn-light-primary btn-sm fw-bold me-3 cursor-pointer"
                    onClick={() => {
                      clearInputs();
                    }}
                  >
                    <i className="ki-outline ki-arrow-left fs-2" /> Back
                  </span>
                )}
              </div>
            </div>

            <div className="card-body ">
              <div className="row g-5 mb-5 align-items-center">
                <div className="col-md-12 fv-row">
                  <label htmlFor className=" fs-6 fw-bold mb-2">
                    Image
                  </label>

                  {imagePreviews?.mainImagePreview ? (
                    <div
                      style={{
                        position: "relative",
                        display: "inline-block",
                        marginLeft: "10px",
                      }}
                    >
                      <img
                        src={imagePreviews?.mainImagePreview}
                        alt="Image Preview"
                        style={{
                          width: "150px",
                          height: "120px",
                          objectFit: "cover",
                          borderRadius: "8px",
                          border: "2px solid #ccc",
                        }}
                      />
                      <i
                        title="Remove Image"
                        className="ki-outline ki-cross fs-2"
                        onClick={() => handleRemoveImages("mainImage")}
                        style={{
                          position: "absolute",
                          top: "-8px",
                          right: "-8px",
                          cursor: "pointer",
                          zIndex: 2,
                          backgroundColor: "white",
                          borderRadius: "50%",
                        }}
                      />
                    </div>
                  ) : (
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg"
                      className="form-control fs-7"
                      onChange={(e) => handleImagesChange(e, "mainImage")}
                    />
                  )}
                </div>

                <div className="col-md-12 fv-row">
                  <label htmlFor className=" fs-6 fw-bold mb-2">
                    Destination Name
                  </label>

                  <input
                    type="text"
                    placeholder="Destination Name"
                    className="form-control"
                    value={destinationName}
                    onChange={(e) => {
                      setDestinationName(e.target.value);
                    }}
                  />
                </div>

                <div className="col-md-12 fv-row">
                  <label htmlFor className=" fs-6 fw-bold mb-2">
                    Country
                  </label>

                  <Select
                    showSearch
                    optionFilterProp="label"
                    name="selectedCountry"
                    value={selectedCountry}
                    onChange={(value) => {
                      setSelectedCountry(value);
                      setStates([]);
                      setCities([]);
                      setSelectedState("");
                      setSelectedCity("");
                    }}
                    placeholder="Select Country"
                    className="fs-7 w-100"
                    style={{ width: "100%" }}
                    dropdownStyle={{ zIndex: 1100 }}
                    size="large"
                    options={countries?.map((e) => {
                      return { label: e.name, value: e.id };
                    })}
                  ></Select>
                </div>
                <div className="col-md-12 fv-row">
                  <label htmlFor className=" fs-6 fw-bold mb-2">
                    State
                  </label>
                  <Select
                    showSearch
                    optionFilterProp="label"
                    name="selectedState"
                    value={selectedState}
                    onChange={(value) => {
                      setSelectedState(value);
                      setCities([]);
                      setSelectedCity("");
                    }}
                    placeholder="Select State"
                    className="fs-7 w-100"
                    style={{ width: "100%" }}
                    dropdownStyle={{ zIndex: 1100 }}
                    size="large"
                    options={states?.map((e) => {
                      return { label: e.name, value: e.id };
                    })}
                  ></Select>
                </div>
                <div className="col-md-12 fv-row">
                  <label htmlFor className=" fs-6 fw-bold mb-2">
                    City
                  </label>
                  <Select
                    showSearch
                    optionFilterProp="label"
                    name="selectedCity"
                    value={selectedCity}
                    onChange={(value) => {
                      setSelectedCity(value);
                    }}
                    placeholder="Select City"
                    className="fs-7 w-100"
                    style={{ width: "100%" }}
                    dropdownStyle={{ zIndex: 1100 }}
                    size="large"
                    options={cities?.map((e) => {
                      return { label: e.name, value: e.id };
                    })}
                  ></Select>
                </div>
                <div className="col-md-12 fv-row">
                  <label htmlFor className=" fs-6 fw-bold mb-2">
                    Region
                  </label>
                  <Select
                    showSearch
                    name="region"
                    value={region}
                    onChange={(value) => {
                      setRegion(value);
                    }}
                    placeholder="Select Region"
                    className="fs-7 w-100"
                    style={{ width: "100%" }}
                    dropdownStyle={{ zIndex: 1100 }}
                    size="large"
                    options={regions}
                  ></Select>
                </div>

                <div className="col-md-12 fv-row">
                  <label htmlFor className=" fs-6 fw-bold mb-2">
                    Is visa required?
                  </label>
                  <Select
                    showSearch
                    name="visaRequired"
                    value={visaRequired}
                    onChange={(value) => {
                      console.log(value);
                      setVisaRequired(value);
                    }}
                    placeholder="Select Visa"
                    className="fs-7 w-100"
                    style={{ width: "100%" }}
                    dropdownStyle={{ zIndex: 1100 }}
                    size="large"
                    options={["Yes", "No"].map((e) => {
                      return {
                        label: e,
                        value: e === "Yes" ? true : false,
                      };
                    })}
                  ></Select>
                </div>
                <div className="col-md-12 fv-row">
                  <button
                    className="btn btn-primary fs-7 fw-bold"
                    onClick={onSubmit}
                    disabled={sending}
                  >
                    {sending ? (
                      <span>
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2" />
                      </span>
                    ) : (
                      <>
                        <i
                          className={`ki-outline ${
                            isEdit ? "ki-pencil" : "ki-plus-square"
                          } fs-2`}
                        />{" "}
                        {isEdit ? "Edit" : "Add"} Destination
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="col-xxl-12">
          <div className="card card-xxl-stretch mb-5 mb-xl-8">
            <div className="card-header">
              <div className="d-flex flex-stack flex-wrap gap-4">
                <div className="position-relative mr-4">
                  <DebouncedSelect
                    apiEndpoint="api/region/"
                    value={regionFilter}
                    onChange={(value) => setRegionFilter(value)}
                    placeholder="Select Region"
                    labelFormatter={(item) => item.name}
                    size="large"
                    style={{ width: "200px" }}
                    allowClear
                    className="fs-7 w-200px"
                    loading={regionLoading}
                    onClear={() => {
                      setRegionFilter(null);
                    }}
                  />

                  {/* <Select
                    showSearch
                    name="regionFilter"
                    value={regionFilter}
                    onChange={(value) => {
                      setRegionFilter(value);
                    }}
                    placeholder="Select Region"
                    className="fs-7 w-200px"
                    dropdownStyle={{ zIndex: 1100 }}
                    size="large"
                    options={regions}
                    allowClear
                    onClear={() => setRegionFilter(null)}
                    loading={regionLoading}
                  /> */}
                </div>
                <div className="position-relative mr-4">
                  <i className="ki-outline ki-magnifier search-icon fs-2 text-gray-500 position-absolute top-50 translate-middle-y ms-5" />
                  <input
                    type="text"
                    data-kt-table-widget-4="search"
                    className="form-control text-dark w-250px fs-7 ps-12 all-search"
                    name="all_search"
                    placeholder="Search Destination here..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              </div>
              <div className="card-toolbar">
                <button
                  className="btn btn-primary fs-7 fw-bold"
                  onClick={() => {
                    setDestinationModal(!destinationModal);
                  }}
                >
                  <i className="ki-outline ki-plus-square fs-2" /> Add New
                  Destination
                </button>
              </div>
            </div>

            <div className="card-body py-2 pb-7">
              {/*begin::Table container*/}
              {loading ? (
                <div className="table-responsive">
                  <Loader cols={7} width={250} />
                </div>
              ) : (
                <div>
                  {data?.length > 0 ? (
                    <>
                      <div className="table-responsive">
                        <table className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 gx-4 border-top-d">
                          <thead>
                            <tr className="fw-bolder text-dark bg-white">
                              <th className="min-w-50px ">#</th>
                              <th className="min-w-100px ">Image</th>
                              <th className="min-w-150px ">Destination Name</th>
                              <th className="min-w-100px ">Country</th>
                              <th className="min-w-100px ">State</th>
                              <th className="min-w-100px ">City</th>
                              <th className="min-w-100px ">Region</th>
                              {/* <th className="min-w-100px ">Description</th> */}
                              <th className="min-w-100px">Visa</th>
                              <th className="min-w-70px">Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {data.length > 0 ? (
                              data.map((item, i) => {
                                return (
                                  <tr className="bg-light" key={i}>
                                    <td className="bdr-left-user ">
                                      {(activePage - 1) * limit + 1 + i}
                                    </td>
                                    <td>
                                      {item?.image ? (
                                        <div className="symbol symbol-60px overflow-hidden me-3">
                                          <Image
                                            src={item?.image}
                                            alt={`${item.name} `}
                                            width={60}
                                            height={60}
                                          />
                                        </div>
                                      ) : (
                                        <div class="symbol symbol-circle rounded symbol-50px mr-3">
                                          <span
                                            class="symbol-label fs-2 text-primary bg-light-primary"
                                            style={{
                                              border: "1px dashed #7843e6",
                                              borderRadius: "50%",
                                            }}
                                          >
                                            {item?.name
                                              ?.charAt(0)
                                              .toUpperCase() || "-"}
                                          </span>
                                        </div>
                                      )}
                                    </td>
                                    <td>{item.name || "-"}</td>

                                    <td>
                                      <span className="fw-bold text-dark ms-0 fs-6">
                                        {item.country_name || "-"}
                                      </span>
                                    </td>
                                    <td>{item.state_name || "-"}</td>
                                    <td>{item.city_name || "-"}</td>
                                    <td>{item.region_name || "-"}</td>
                                    {/* <td>{item.description || "-"}</td> */}
                                    <td>
                                      {item.visa_type == 1
                                        ? "Offline Application"
                                        : item.visa_type == 2
                                        ? "Not Required"
                                        : item.visa_type == 3
                                        ? "On Arrival"
                                        : "E-Visa"}
                                    </td>
                                    <td>
                                      <div className="d-flex flex-shrink-0 justify-content-end">
                                        <span
                                          className="btn btn-icon bg-light btn-active-color-primary btn-sm cursor-pointer me-2"
                                          onClick={() => {
                                            // setIsEdit(true);
                                            setDestinationModal(
                                              !destinationModal
                                            );
                                            setSelected(item);
                                          }}
                                        >
                                          <i className="ki-outline ki-pencil fs-2 text-info" />
                                        </span>
                                        <span
                                          className="btn btn-icon bg-light btn-active-color-primary btn-sm cursor-pointer"
                                          onClick={() => {
                                            setShowDeleteModal(true);
                                            setSelected(item);
                                          }}
                                        >
                                          <i className="ki-outline ki-trash fs-2 text-danger" />
                                        </span>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <span>No records</span>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="separator separator-dashed border-gray-200" />
                      <Pagination
                        setLimit={setLimit}
                        limit={limit}
                        data={data}
                        activePage={activePage}
                        totalRecords={totalRecords}
                        onChange={(pageNumber) => {
                          setLoading(true);
                          setActivePage(pageNumber);
                        }}
                      />
                    </>
                  ) : (
                    <div className="row g-5 mb-0 gx-md-10">
                      <div className="col-md-12 text-center">
                        <div className="mt-0">
                          <img
                            src="/media/no-data-found.png"
                            className="mw-25"
                            alt="No Data Found"
                          />
                        </div>
                        <h1 className="mt-4">No Data Found</h1>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/*end::Pagination*/}
            </div>
          </div>
        </div>
      </div>

      <Alert
        show={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
        }}
        // onSuccess={() => {
        //   getData();
        // }}
        onAction={() => {
          toast.loading("Please wait...");
          deleteItem();
        }}
      />

      <Destinations
        item={selected}
        show={destinationModal}
        onHide={() => {
          setSelected({});
          setDestinationModal(false);
        }}
        onSuccess={() => {
          getData();
        }}
      />
    </div>
  );
}
