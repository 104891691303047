import React, { useEffect, useState } from "react";

import { LIMITS, PROPERTIES } from "../../../config";
import { fetchData } from "../../../config/service";
import { Alert, Loader, Pagination } from "../../../components";
import toast from "react-hot-toast";
import { Button, Image, Popover, Select, Tooltip } from "antd";
import dayjs from "dayjs";
import { NotesModal } from "../../customers/modals";
import WhatsappTemplateModal from "../../customers/modals/whatsapp-template";

const priorityOptions = [
  {
    label: "Critical",
    value: 1,
  },
  {
    label: "High",
    value: 2,
  },
  {
    label: "Medium",
    value: 3,
  },
  {
    label: "Low",
    value: 4,
  },
];

export default function CrmPage() {
  const [visaModal, setVisaModal] = useState(false);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [limit, setLimit] = useState(LIMITS[0]);
  const [loading, setLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const [openAccordion, setOpenAccordion] = useState(null); // State to track the open accordion row

  const [notesOptions, setNotesOptions] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedModalData, setSelectedModalData] = useState(null);
  const [whatsappTemplateModal, setWhatsAppTemplateModal] = useState(false);

  const toggleAccordion = (index) => {
    setOpenAccordion(openAccordion === index ? null : index); // Toggle the accordion
  };

  useEffect(() => {
    getNotesOptions();
  }, []);

  useEffect(() => {
    getData();
  }, [limit, activePage]);

  const getData = () => {
    let param = "";

    let offset = (activePage - 1) * limit;
    param += `?limit=${limit}&offset=${offset}`;

    // Add search query if it exists
    if (debouncedSearchTerm) {
      param += `&search=${encodeURIComponent(debouncedSearchTerm || "")}`;
    }

    fetchData(`api/crm/${param}`).then((res) => {
      setLoading(false);
      if (res.success) {
        setData(res.data);
        setTotalRecords(res.count);
      } else {
        setData([]);
        toast.error(res.message);
      }
    });
  };

  const getNotesOptions = () => {
    fetchData(`api/note/notes-enum/`, "get").then((res) => {
      setLoading(false);
      if (res.success) {
        setNotesOptions(res["note_choices"]);
      } else {
        toast.error(res.message);
      }
    });
  };

  const deleteItem = () => {
    fetchData(
      `customer/api/item/item-game-plan-details/${selected.id}/`,
      "delete"
    ).then((res) => {
      toast.dismiss();
      if (res.success) {
        setLoading(true);
        getData();
        toast.success("Record deleted successfully");
      } else {
        toast.error(res.message);
      }
    });
  };

  const handlePriorityChange = (value, data) => {
    toast.loading("Updating priority...");
    fetchData(`api/support/tickets/${data.id}/`, "put", {
      priority: value,
    }).then((res) => {
      toast.dismiss();
      if (res.success) {
        setLoading(true);
        getData();
        toast.success("Priority updated successfully");
      } else {
        toast.error(res.message);
      }
    });
  };

  return (
    <div>
      <div className="row g-5 g-xxl-10">
        <div className="col-xxl-12">
          {/*begin::Tables Widget 9*/}
          <div className="card card-xxl-stretch mb-5 mb-xl-8">
            {/*begin::Header*/}
            <div className="card-header">
              <h1 className="card-title">CRM</h1>
              {/* <div className="d-flex flex-stack flex-wrap gap-4">
                <div className="position-relative mr-4">
                  <i className="ki-outline ki-magnifier search-icon fs-2 text-gray-500 position-absolute top-50 translate-middle-y ms-5" />
                  <input
                    type="text"
                    data-kt-table-widget-4="search"
                    className="form-control text-dark w-250px fs-7 ps-12 all-search"
                    name="all_search"
                    placeholder="Search here..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <div className="position-relative mr-4"></div>
              </div> */}
              <div className="card-toolbar"></div>
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className="card-body py-2 pb-7">
              {/*begin::Table container*/}
              {loading ? (
                <div className="table-responsive">
                  <Loader cols={7} width={250} />
                </div>
              ) : (
                <div>
                  {data?.length > 0 ? (
                    <>
                      <div className="table-responsive tbl-sales">
                        <table className="table align-middle table-row-dashed table-row-gray-300 gy-4 gs-4 border-top-d">
                          <thead>
                            <tr className="fw-bolder text-dark bg-white">
                              <th className="min-w-70px">Sr. No.</th>
                              <th className="min-w-150px">Name</th>
                              <th className="min-w-150px">Edition</th>
                              <th className="min-w-200px">Status</th>
                              <th className="min-w-150px">Onboarded</th>
                            </tr>
                          </thead>
                          <tbody className="fw-semibold fs-7">
                            {data?.map((item, index) => (
                              <>
                                {/* Table Row */}
                                <tr
                                  className={`bg-light ${
                                    openAccordion === index
                                      ? "table-active"
                                      : ""
                                  }`}
                                  onClick={() => toggleAccordion(index)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <td>
                                    <div className="d-flex align-items-center gap-3">
                                      {/* Icon */}
                                      <i
                                        className={`ki-outline fs-2 ${
                                          openAccordion === index
                                            ? "ki-up"
                                            : "ki-down"
                                        } me-2`}
                                      ></i>
                                      {(activePage - 1) * limit + 1 + index}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                        {item?.user?.photo ? (
                                          <img src={item?.user?.photo} alt="" />
                                        ) : (
                                          <div className="symbol symbol-circle symbol-50px mr-3">
                                            <span
                                              className="symbol-label fs-2 text-primary bg-light-primary"
                                              style={{
                                                border: "1px dashed #7843e6",
                                                borderRadius: "50%",
                                              }}
                                            >
                                              {item?.user?.first_name
                                                ?.charAt(0)
                                                .toUpperCase()}
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                      <div className="d-flex flex-column">
                                        <a
                                          href
                                          className="text-primary mb-1 fw-bolder gotham_black"
                                        >
                                          {item.user?.first_name +
                                            " " +
                                            item.user?.last_name || "-"}
                                        </a>
                                        <span>{item?.user?.email || "-"}</span>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <span className="text-primary fs-6 fw-bold">
                                      {item?.edition?.ip || "-"}
                                    </span>
                                  </td>
                                  <td>
                                    Call Request on{" "}
                                    <span className="fs-6 fw-bold">
                                      {dayjs(item?.item?.call_requested).format(
                                        "DD-MM-YYYY"
                                      ) || "-"}
                                    </span>
                                  </td>

                                  <td>
                                    Onboarded on{" "}
                                    <span className="fs-6 fw-bold">
                                      {dayjs(item?.item?.onboard_on).format(
                                        "DD-MM-YYYY"
                                      ) || "-"}
                                    </span>
                                  </td>
                                </tr>

                                {/* Accordion Row */}
                                {openAccordion === index && (
                                  <tr className="bg-light">
                                    <td colSpan="6">
                                      <div className="accordion-body px-9 py-6">
                                        {/* Header with Score and Actions */}
                                        <div className="d-flex justify-content-between align-items-center mb-8">
                                          <div className="d-flex align-items-center gap-8">
                                            <h3 className="text-primary fs-4 fw-bold mb-0">
                                              <span className="text-dark fs-4 fw-semibold">
                                                Score:
                                              </span>{" "}
                                              {item.user?.score || 0}
                                            </h3>
                                            <h3 className="text-primary fs-4 fw-bold mb-0">
                                              <span className="text-dark fs-4 fw-semibold">
                                                Phone:
                                              </span>{" "}
                                              {item.user?.phone}
                                            </h3>
                                          </div>
                                          <div className="d-flex gap-2">
                                            <Tooltip
                                              placement="top"
                                              title="Add Note"
                                            >
                                              <button
                                                className="btn btn-icon box-shadow-lg border btn-active-color-primary btn-sm"
                                                onClick={() => {
                                                  setIsModalVisible(true);
                                                  setSelectedModalData(item);
                                                }}
                                                title="Add Note"
                                              >
                                                <i className="ki-outline ki-plus-square fs-1"></i>
                                              </button>
                                            </Tooltip>

                                            <div>
                                              {item?.user.phone ? (
                                                <Popover
                                                  content={
                                                    <>
                                                      <div>
                                                        <Button
                                                          type="link"
                                                          className="text-dark fw-bold"
                                                          onClick={() => {
                                                            setWhatsAppTemplateModal(
                                                              true
                                                            );
                                                            setSelected(item);
                                                          }}
                                                        >
                                                          WhatsApp Template
                                                        </Button>
                                                        <br />
                                                        <Button
                                                          type="link"
                                                          className="text-dark fw-bold"
                                                          href={`https://wa.me/${item?.user?.phone}`}
                                                          target="_blank"
                                                          rel="noopener noreferrer"
                                                        >
                                                          Open in WhatsApp
                                                        </Button>
                                                      </div>
                                                    </>
                                                  }
                                                  trigger="hover"
                                                >
                                                  <div className="btn btn-icon box-shadow-lg border btn-active-color-primary btn-sm">
                                                    <i
                                                      className="bi bi-whatsapp fs-2 text-success"
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                    />
                                                  </div>
                                                </Popover>
                                              ) : (
                                                <div
                                                  className="btn btn-icon box-shadow-lg border btn-active-color-primary btn-sm"
                                                  disabled
                                                  style={{
                                                    cursor: "not-allowed",
                                                    opacity: "0.5",
                                                  }}
                                                >
                                                  <i className="bi bi-whatsapp fs-2 text-success" />
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>

                                        {/* Platform Metrics Section */}
                                        <div className="mb-8">
                                          <h3 className="text-gray-700 fs-5 fw-semibold mb-6">
                                            Platform Metrics
                                          </h3>

                                          <div className="d-flex flex-wrap gap-8 mb-8">
                                            {/* First Row */}
                                            <div className="d-flex flex-column min-w-200px">
                                              <span className="text-gray-500 fs-7 fw-semibold">
                                                Applied On
                                              </span>
                                              <span className="text-gray-800 fs-6">
                                                {item.applied_on
                                                  ? dayjs(
                                                      item.applied_on
                                                    ).format("hh:mm a - DD MMM")
                                                  : "-"}
                                              </span>
                                            </div>

                                            <div className="d-flex flex-column min-w-200px">
                                              <span className="text-gray-500 fs-7 fw-semibold">
                                                Curated On
                                              </span>
                                              <span className="text-gray-800 fs-6">
                                                {item.curated_on
                                                  ? dayjs(
                                                      item.curated_on
                                                    ).format("hh:mm a - DD MMM")
                                                  : "-"}
                                              </span>
                                            </div>

                                            <div className="d-flex flex-column min-w-200px">
                                              <span className="text-gray-500 fs-7 fw-semibold">
                                                Paid On
                                              </span>
                                              <span className="text-gray-800 fs-6">
                                                -
                                              </span>
                                            </div>

                                            <div className="d-flex flex-column min-w-200px">
                                              <span className="text-gray-500 fs-7 fw-semibold">
                                                Revenue
                                              </span>
                                              <span className="text-gray-800 fs-6">
                                                {item.user?.revenue || "-"}
                                              </span>
                                            </div>
                                          </div>

                                          <div className="d-flex flex-wrap gap-8 mb-8">
                                            {/* Second Row */}
                                            <div className="d-flex flex-column min-w-200px">
                                              <span className="text-gray-500 fs-7 fw-semibold">
                                                Call Requested by
                                              </span>
                                              <span className="text-gray-800 fs-6">
                                                Mahima
                                              </span>
                                            </div>

                                            <div className="d-flex flex-column min-w-200px">
                                              <span className="text-gray-500 fs-7 fw-semibold">
                                                Onboarded On
                                              </span>
                                              <span className="text-gray-800 fs-6">
                                                {item.onboard_on
                                                  ? dayjs(
                                                      item.onboard_on
                                                    ).format("hh:mm a - DD MMM")
                                                  : "-"}
                                              </span>
                                            </div>

                                            <div className="d-flex flex-column min-w-200px">
                                              <span className="text-gray-500 fs-7 fw-semibold">
                                                Support Reached out on
                                              </span>
                                              <span className="text-gray-800 fs-6">
                                                -
                                              </span>
                                            </div>
                                          </div>

                                          {/* Metrics Grid */}
                                          {/* <div className="d-flex flex-wrap gap-8">
                                            <div className="d-flex flex-column min-w-100px">
                                              <span className="text-gray-800 fs-2 fw-bold mb-1">
                                                5
                                              </span>
                                              <span className="text-gray-500 fs-7">
                                                Beta Views
                                              </span>
                                            </div>

                                            <div className="d-flex flex-column min-w-100px">
                                              <span className="text-gray-800 fs-2 fw-bold mb-1">
                                                2
                                              </span>
                                              <span className="text-gray-500 fs-7">
                                                Theta Views
                                              </span>
                                            </div>

                                            <div className="d-flex flex-column min-w-100px">
                                              <span className="text-gray-800 fs-2 fw-bold mb-1">
                                                2
                                              </span>
                                              <span className="text-gray-500 fs-7">
                                                Pick NE
                                              </span>
                                            </div>

                                            <div className="d-flex flex-column min-w-100px">
                                              <span className="text-gray-800 fs-2 fw-bold mb-1">
                                                300
                                              </span>
                                              <span className="text-gray-500 fs-7">
                                                Unsure Actions
                                              </span>
                                            </div>
                                          </div> */}
                                        </div>

                                        {/* Call Notes Section */}
                                        {/* <div>
                                          <h3 className="text-gray-600 fs-5 fw-semibold mb-6">
                                            Call Notes
                                          </h3>
                                          <div className="bg-light-primary bg-opacity-50 rounded p-6">
                                            <span className="text-gray-600 fs-7">
                                              No call notes available
                                            </span>
                                          </div>
                                        </div> */}
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </>
                            ))}
                          </tbody>
                        </table>
                      </div>

                      <div className="separator separator-dashed border-gray-200" />
                      <Pagination
                        setLimit={setLimit}
                        limit={limit}
                        data={data}
                        activePage={activePage}
                        totalRecords={totalRecords}
                        onChange={(pageNumber) => {
                          setLoading(true);
                          setActivePage(pageNumber);
                        }}
                      />
                    </>
                  ) : (
                    <div className="row g-5 mb-0 gx-md-10">
                      <div className="col-md-12 text-center">
                        <div className="mt-0">
                          <img
                            src="/media/no-data-found.png"
                            className="mw-25"
                            alt="No Data Found"
                          />
                        </div>
                        <h1 className="mt-4">No Data Found</h1>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/*end::Pagination*/}
            </div>
            {/*begin::Body*/}
          </div>
          {/*end::Tables Widget 9*/}
        </div>
      </div>

      <Alert
        show={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
        }}
        // onSuccess={() => {
        //   getData();
        // }}
        onAction={() => {
          toast.loading("Please wait...");
          deleteItem();
        }}
      />

      {whatsappTemplateModal && (
        <WhatsappTemplateModal
          show={whatsappTemplateModal}
          onHide={() => {
            setWhatsAppTemplateModal(false);
          }}
          data={{
            user_data: selected?.user,
          }}
        />
      )}

      {isModalVisible && (
        <NotesModal
          show={isModalVisible}
          notesOptions={notesOptions}
          onHide={() => {
            setIsModalVisible(false);
            // setNotesLoading(true);
            // getNotes();
          }}
          data={{
            uuid: selectedModalData?.user?.uuid,
          }}
        />
      )}
    </div>
  );
}
