import React, { useEffect, useState, useRef } from "react";
import { Select, Spin } from "antd";
import { fetchData } from "../../config/service";
import toast from "react-hot-toast";

const DebouncedSelect = ({
  apiEndpoint,
  value,
  onChange,
  placeholder = "Select an option",
  additionalParams = {},
  labelFormatter = (item) => `${item.name}`,
  valueFormatter = (item) => item.id,
  debounceTime = 500,
  defaultSearchKey = null,
  defaultSearchValue = null,
  allowClear = false,
  selectFirstOption = false, // New prop to control default selection behavior
  ...props
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const isFirstLoad = useRef(true);
  const hasSetDefaultValue = useRef(false);

  // Handle search term debouncing
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
      setPage(1); // Reset pagination on search
    }, debounceTime);
    return () => clearTimeout(handler);
  }, [searchTerm, debounceTime]);

  // Separate effect for initial load with default search
  useEffect(() => {
    if (isFirstLoad.current && defaultSearchValue !== null) {
      fetchOptions();
    }
  }, [defaultSearchValue]);

  useEffect(() => {
    // Skip if it's first load and we have default search
    if (isFirstLoad.current && defaultSearchKey) {
      return;
    }
    fetchOptions();
  }, [debouncedSearchTerm, page]);

  const fetchOptions = () => {
    let params = new URLSearchParams({
      limit: 10,
      offset: debouncedSearchTerm ? 0 : (page - 1) * 10,
      search: debouncedSearchTerm || "",
      ...additionalParams,
    });

    // Only add default search params on initial load
    if (isFirstLoad.current && defaultSearchKey) {
      params.append(defaultSearchKey, defaultSearchValue);
    }

    setLoading(true);
    fetchData(`${apiEndpoint}?${params}`)
      .then((res) => {
        if (res.success) {
          const newOptions = res.data.map((item) => ({
            label: labelFormatter(item),
            value: valueFormatter(item),
          }));

          setOptions((prevOptions) => {
            const combined = [
              ...(debouncedSearchTerm ? [] : prevOptions),
              ...newOptions,
            ];
            const uniqueOptions = Array.from(
              new Set(combined.map((opt) => opt.value))
            ).map((id) => combined.find((opt) => opt.value === id));

            // Set first option as default value if selectFirstOption is true
            // and it hasn't been set before
            if (
              selectFirstOption &&
              uniqueOptions.length > 0 &&
              isFirstLoad.current &&
              !hasSetDefaultValue.current &&
              !value // Only set if no value is already selected
            ) {
              hasSetDefaultValue.current = true;
              onChange(uniqueOptions[0].value);
            }

            return uniqueOptions;
          });
        } else {
          setOptions([]);
          toast.error(res.message);
        }
      })
      .finally(() => {
        setLoading(false);
        isFirstLoad.current = false;
      });
  };

  return (
    <Select
      showSearch
      placeholder={placeholder}
      value={loading ? null : value}
      onChange={(val) => {
        if (!val && allowClear) {
          setSearchTerm(""); // Reset search term when clearing
          setDebouncedSearchTerm("");
          setPage(1);
          fetchOptions(); // Fetch fresh list without search filter
        }
        onChange(val);
      }}
      onSearch={setSearchTerm}
      optionFilterProp="label"
      style={{ width: "100%" }}
      loading={loading}
      options={options}
      onPopupScroll={(e) => {
        const bottom =
          Math.abs(
            e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight)
          ) < 1.5;
        if (bottom && !loading) {
          setPage((prevState) => prevState + 1);
        }
      }}
      notFoundContent={loading ? <Spin size="small" /> : "No Data Found"}
      allowClear={allowClear}
      {...props}
    />
  );
};

export default DebouncedSelect;
