import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { fetchData } from "../../../../../config/service";
import toast from "react-hot-toast";
import { Dropzone } from "../../../../../components";
import { message, Select, Spin, TimePicker, Tooltip } from "antd";
import { validateFields } from "../../../../../components/validation";
import moment from "moment";
import dayjs from "dayjs";

export default function AirportDetails({
  show,
  airportList,
  setFilterData,
  setActivePage,
  airportModalDetails,
  setAirportModalDetails,
  airportModalValid,
  setAirportModalValid,
  airportLoading,
  setAirportLoading,
  onHide,
  data,
}) {
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);

  const [isDuplicate, setIsDuplicate] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAirportModalDetails({
      ...airportModalDetails,
      [name]: value,
    });
  };

  const handleTimeChange = (name, time) => {
    setAirportModalDetails({
      ...airportModalDetails,
      [name]: time,
    });
  };

  //   const clearInput = () => {
  //     setFormState({
  //       day: "",
  //       title: "",
  //       hotel: "",
  //       agendas: [""],
  //       photo: "",
  //       destination: "",
  //       experience: "",
  //       aboutDay: "",
  //       trip_highlight: false,
  //     });
  //     setPictures([]);
  //     setRemovedImages([]);
  //   };

  const rules = {
    arrivalAirport: {
      required: true,
      maxLength: 100,
    },
    departureAirport: {
      required: false,
      maxLength: 100,
    },
    landingTime: {
      required: false,
      maxLength: 500,
    },
    takeOffTime: {
      required: false,
      maxLength: 100,
    },
    // checkOutTime: {
    //   required: true,
    //   maxLength: 500,
    // },
    checkInPrice: {
      type: "number",
      required: false,
    },
    description: {
      required: false,
    },
  };

  const onSubmit = () => {
    const { isValid, errors } = validateFields(airportModalDetails, rules);

    if (!isValid) {
      return;
    } else {
      setAirportModalValid(true);
      handleHide(true);
    }

    // console.log(airportModalDetails, "airportModalDetails");
  };

  const handleHide = (canClose = false) => {
    if (canClose) {
      onHide(); // Close modal if `canClose` is true
    } else {
      message.warning(
        "Please fill in all the required fields before closing the modal."
      );
    }
  };

  return (
    <Modal centered show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <h3 className="modal-title">Airport Details</h3>
      </Modal.Header>
      <div>
        <div
          className="modal-body pb-7 pt-5 px-lg-10"
          style={
            loading
              ? {
                  filter: "blur(5px)",
                  pointerEvents: "none",
                }
              : {}
          }
        >
          <div
            className="scroll-y pe-7 ps-3 h-400px"
            id="kt_modal_add_customer_scroll"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-max-height="auto"
            data-kt-scroll-dependencies="#kt_modal_add_customer_header"
            data-kt-scroll-wrappers="#kt_modal_add_customer_scroll"
            data-kt-scroll-offset="300px"
          >
            <div className="row g-7">
              <div className="col-md-6 fv-row">
                <label className="fs-6 fw-bold mb-2">Arrival Airport</label>
                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  value={airportModalDetails?.arrivalAirport}
                  showSearch
                  onSearch={(value) => {
                    setFilterData((prevState) => ({
                      ...prevState,
                      airportSearch: value,
                    }));
                  }}
                  optionFilterProp="label"
                  onChange={(value) => {
                    setAirportModalDetails((prevState) => ({
                      ...prevState,
                      arrivalAirport: value,
                    }));
                  }}
                  placeholder="Select Arrival Airport"
                  style={{ width: "100%" }}
                  options={airportList}
                  allowClear
                  onClear={() => {
                    setAirportModalDetails((prevState) => ({
                      ...prevState,
                      arrivalAirport: null,
                    }));
                    setActivePage(1);
                  }}
                  onPopupScroll={(e) => {
                    const bottom =
                      Math.abs(
                        e.target.scrollHeight -
                          (e.target.scrollTop + e.target.clientHeight)
                      ) < 1.5;

                    if (bottom) {
                      setAirportLoading(true);
                      setActivePage((prevState) => prevState + 1);
                    }
                  }}
                  notFoundContent={
                    <>
                      <div className="d-flex justify-content-center align-items-center">
                        {airportLoading ? (
                          <Spin size="small" />
                        ) : (
                          "No Data Found"
                        )}
                      </div>
                    </>
                  }
                  size="large"
                  loading={airportLoading}
                ></Select>

                {/* <input
                      type="text"
                      className="form-control fs-7"
                      name="arrivalAirport"
                      value={airportModalDetails.arrivalAirport}
                      onChange={handleInputChange}
                      disabled={isDuplicate}
                    /> */}
              </div>

              <div className="col-md-6 fv-row">
                <label className="fs-6 fw-bold mb-2">Departure Airport</label>

                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  value={airportModalDetails?.departureAirport}
                  showSearch
                  onSearch={(value) => {
                    setFilterData((prevState) => ({
                      ...prevState,
                      airportSearch: value,
                    }));
                  }}
                  optionFilterProp="label"
                  onChange={(value) => {
                    setAirportModalDetails((prevState) => ({
                      ...prevState,
                      departureAirport: value,
                    }));
                  }}
                  placeholder="Select Departure Airport"
                  style={{ width: "100%" }}
                  options={airportList}
                  allowClear
                  onClear={() => {
                    setAirportModalDetails((prevState) => ({
                      ...prevState,
                      departureAirport: null,
                    }));
                    setActivePage(1);
                  }}
                  onPopupScroll={(e) => {
                    const bottom =
                      Math.abs(
                        e.target.scrollHeight -
                          (e.target.scrollTop + e.target.clientHeight)
                      ) < 1.5;

                    if (bottom) {
                      setAirportLoading(true);
                      setActivePage((prevState) => prevState + 1);
                    }
                  }}
                  notFoundContent={
                    <>
                      <div className="d-flex justify-content-center align-items-center">
                        {airportLoading ? (
                          <Spin size="small" />
                        ) : (
                          "No Data Found"
                        )}
                      </div>
                    </>
                  }
                  size="large"
                  loading={airportLoading}
                ></Select>
                {/* <input
                      type="text"
                      className="form-control fs-7"
                      name="departureAirport"
                      value={airportModalDetails.departureAirport}
                      onChange={handleInputChange}
                      disabled={isDuplicate}
                    /> */}
              </div>

              <div className="col-md-6 fv-row">
                <label className="fs-6 fw-bold mb-2">
                  Recommended Landing Time
                </label>
                <TimePicker
                  className="fs-6 w-100"
                  size="large"
                  value={
                    airportModalDetails.landingTime
                      ? dayjs(airportModalDetails.landingTime, "HH:mm")
                      : null
                  }
                  onChange={(time, timeString) => {
                    setAirportModalDetails({
                      ...airportModalDetails,
                      landingTime: timeString,
                    });
                  }}
                  format="HH:mm"
                />
              </div>

              <div className="col-md-6 fv-row">
                <label className="fs-6 fw-bold mb-2">
                  {" "}
                  Recommended Take Off Time
                </label>

                <TimePicker
                  className="fs-6 w-100"
                  size="large"
                  value={
                    airportModalDetails.takeOffTime
                      ? dayjs(airportModalDetails.takeOffTime, "HH:mm")
                      : null
                  }
                  onChange={(time, timeString) => {
                    setAirportModalDetails({
                      ...airportModalDetails,
                      takeOffTime: timeString,
                    });
                  }}
                  format="HH:mm"
                />
              </div>

              {/* <div className="col-md-4 fv-row">
                <label className="fs-6 fw-bold mb-2">Check Out Time</label>
                <TimePicker
                  className="fs-7 w-100"
                  size="large"
                  value={airportModalDetails.checkOutTime}
                  onChange={(time) => handleTimeChange("checkOutTime", time)}
                  format="HH:mm"
                />
              </div> */}

              {/* <div className="col-md-12 fv-row">
                <label className="fs-6 fw-bold mb-2">Check In Price(Rs)</label>
                <input
                  type="number"
                  className="form-control fs-7"
                  name="checkInPrice"
                  value={airportModalDetails.checkInPrice}
                  onChange={handleInputChange}
                  disabled={isDuplicate}
                />
              </div> */}

              <div className="col-md-12 fv-row">
                <label className="fs-6 fw-bold mb-2">
                  Airport to Hotel Suggestions
                </label>
                <textarea
                  className="form-control fs-7"
                  name="description"
                  rows={4}
                  value={airportModalDetails.description}
                  onChange={handleInputChange}
                  disabled={isDuplicate}
                  placeholder="Enter Airport to Hotel Suggestions"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-light fs-7"
            onClick={() => onHide()}
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary fs-7"
            onClick={() => onSubmit()}
          >
            Submit
          </button>
        </div>
      </div>
    </Modal>
  );
}
