import toast from "react-hot-toast";

// Function to convert field names into more readable format
function formatFieldName(fieldName) {
  return fieldName
    .replace(/_/g, " ") // Replace underscores with spaces
    .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter of each word
}

export function objectValidation(arrayOfObjects, requiredKeys, title = "") {
  const errors = [];
  let toastDisplayed = false; // Flag to prevent multiple toast messages

  arrayOfObjects.forEach((obj, index) => {
    const fields = Object.keys(obj);
    const values = Object.values(obj);

    // If requiredKeys is not provided, treat all keys as required
    const keysToValidate = requiredKeys || fields;

    // Check if any required field is empty, null, or undefined
    const isAnyRequiredFieldEmpty = keysToValidate.some(
      (key) => obj[key] === "" || obj[key] === null || obj[key] === undefined
    );

    if (isAnyRequiredFieldEmpty) {
      // If any required field is empty, log an error for this object
      const missingFields = keysToValidate
        .filter(
          (key) =>
            obj[key] === "" || obj[key] === null || obj[key] === undefined
        )
        .map(formatFieldName) // Format field names to be more readable
        .join(", ");

      // Include the title in the error message if provided
      const errorMessage = `${
        title ? title + ": " : ""
      }Please fill in all required fields. Missing fields: ${missingFields} in entry #${
        index + 1
      }.`;

      errors.push(errorMessage);

      // Display error using toast only if one hasn't been displayed already
      if (!toastDisplayed) {
        toast.error(errorMessage);
        toastDisplayed = true; // Mark that a toast message has been displayed
      }
    }
  });

  return { isValid: errors.length === 0, errors };
}
