import React, { useEffect, useState } from "react";
import {
  filterNonEmptyValues,
  getNonEmptyKeys,
  LIMITS,
  parseISOToUTCDate,
  routesList,
} from "../../../../config";
import { Link, useParams } from "react-router-dom";
import {
  Upload,
  Button,
  message,
  Select,
  DatePicker,
  Tooltip,
  Spin,
  TimePicker,
  Image as AntdImage,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import toast from "react-hot-toast";
import { fetchData } from "../../../../config/service";
import { validateFields } from "../../../../components/validation";
import moment from "moment";
import Itinerary from "./itinerary";
import { useDropzone } from "react-dropzone";
import { v4 as uuidv4 } from "uuid"; // Import UUID for unique filenames
import { Dropzone } from "../../../../components";
import dayjs from "dayjs";
import AirportDetails from "./airport-details/airport-details";
import GamePlan from "./game-plan";
import GamePlanModal from "./game-plan/gameplanModal";
import GamePlanDayModal from "./game-plan/dayModal";
import ImportCsvModal from "./import-csv/importCsvModal";
import { get } from "jquery";
import ItineraryModal from "./itinerary/modal";
import HotelDetail from "./hotel-detail/hotel-detail";
import LinkDetails from "./link-details/link-details";
import HighlightsDetails from "./highlight-details";
import { objectValidation } from "../../../../components/objectValidation";
import StoryHighlights from "./story-highlights";
import ContentModal from "./content-modal";

const { Option } = Select;

export default function Handle() {
  const { id } = useParams();
  const [itineraryModal, setItineraryModal] = useState(false);
  const [makeModalFreezed, setMakeModalFreezed] = useState(false);

  const [fileList, setFileList] = useState([]);

  const [loading, setLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);

  const [sending, setSending] = useState(false);

  const [activePage, setActivePage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [limit, setLimit] = useState(LIMITS[0]);

  const [airportActivePage, setAirportActivePage] = useState(1);
  const [destinationActivePage, setDestinationActivePage] = useState(1);
  const [hotelActivePage, setHotelActivePage] = useState(1);

  // handle main form
  const [formData, setFormData] = useState({
    duplicateEdition: "",
    project: null,
    editionName: "",
    startDate: null,
    endDate: null,
    travelStyle: null,
    maxSpots: "",
    publicSpots: "",
    hotel: null,
    destination: null,
    // arrivalAirport: null,
    // departureAirport: null,
    locationIdentifier: "",
    addOns: null,
    whatsappLink: "",
    heroImage: null,
    bannerImage: null,
    ipName: "",
    editionNumber: "",
    editionCost: "",
    editionDescription: "",
    shortDescription: "",
    aboutTrip: "",
    visaRequirement: false,
    preSalesGuidebook: "",
    singleRoomAddonCost: "",
    region: null,
    advancePayment: "",
    starEdition: false,
    highlightImage: null,
    description: "",
    edition_status: null,
    users: [],
    hotel_check_in: null,
    hotel_check_out: null,
    blueprint: "",
  });

  const [duplicateData, setDuplicateData] = useState({});

  const [hotelList, setHotelList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [destinationList, setDestinationList] = useState([]);
  const [addOnsList, setAddOnsList] = useState([]);
  const [travelStyleList, setTravelList] = useState([]);
  const [editionStatusList, setEditionStatusList] = useState([]);
  const [regions, setRegions] = useState([]);

  const [hotelLoading, setHotelLoading] = useState(false);
  const [projectLoading, setProjectLoading] = useState(false);
  const [destinationLoading, setDestinationLoading] = useState(false);
  const [addOnsLoading, setAddOnsLoading] = useState(false);
  const [travelStyleLoading, setTravelStyleLoading] = useState(false);
  const [editionStatusLoading, setEditionStatusLoading] = useState(false);
  const [regionLoading, setRegionLoading] = useState(false);

  const [editionsDetailData, setEditionsDetailData] = useState([]);
  const [modalItinerarydata, setModalItineraryData] = useState({});
  const [itinerarydata, setItineraryData] = useState([]);
  const [editionId, setEditionId] = useState(null);
  const [files, setFiles] = useState([]);

  const [heroImagePreview, setHeroImagePreview] = useState(null);
  const [bannerImagePreview, setBannerImagePreview] = useState(null);
  const [highlightImagePreview, setHighlightImagePreview] = useState(null);
  const [quickLinkImagePreview, setQuickLinkImagePreview] = useState([null]);
  const [hightLightModalImagePreview, setHighLightModalImagePreview] = useState(
    [null]
  );

  const [storyHighLightModalImagePreview, setStoryHighLightModalImagePreview] =
    useState([null]);

  const [uploadedUrls, setUploadedUrls] = useState({
    heroImageUrl: "",
    bannerImageUrl: "",
    highlightImageUrl: "",
  });

  // State for Dropzone 1
  const [storyMediaImages, setStoryMediaImages] = useState([]);
  const [removeStoryMediaImages, setRemoveStoryMediaImages] = useState([]);
  const [storyMediaUrls, setStoryMediaUrls] = useState([]);

  // State for Dropzone 2
  const [cardMediaImages, setCardMediaImages] = useState([]);
  const [removeCardMediaImages, setRemoveCardMediaImages] = useState([]);
  const [cardMediaUrls, setCardMediaUrls] = useState([]);

  // State for Dropzone 2
  const [highLightMediaImages, setHighlightMediaImages] = useState([]);
  const [removeHighlightMediaImages, setRemoveHighlightMediaImages] = useState(
    []
  );
  const [highlightMediaUrls, setHighlightMediaUrls] = useState([]);

  const [filterData, setFilterData] = useState({
    edition: null,
    airportSearch: null,
    hotelSearch: null,
    destinationSearch: null,
  });

  const [editionsList, setEditionsList] = useState([]);
  const [editionsListLoading, setEditionsListLoading] = useState(false);

  const [airportLoading, setAirportLoading] = useState(false);
  const [airportList, setAirportList] = useState([]);

  const [isDuplicate, setIsDuplicate] = useState(false);

  const [userListLoading, setUserListLoading] = useState(false);
  const [userList, setUserList] = useState([]);

  const [debouncedAirportSearchTerm, setDebouncedAirportSearchTerm] = useState(
    filterData?.airportSearch
  );
  const [debouncedHotelSearchTerm, setDebouncedHotelSearchTerm] = useState(
    filterData?.hotelSearch
  );

  const [debouncedDestinationSearchTerm, setDebouncedDestinationSearchTerm] =
    useState(filterData?.destinationSearch);

  const [showAirportModal, setShowAirportModal] = useState(false);
  const [airportModalDetails, setAirportModalDetails] = useState({
    arrivalAirport: null,
    departureAirport: null,
    landingTime: null,
    takeOffTime: null,
    checkOutTime: null,
    checkInPrice: "",
    description: "",
  });

  const [airportModalValid, setAirportModalValid] = useState(false);
  const [selectedHotelList, setSelectedHotelList] = useState(false);

  const [activeTab, setActiveTab] = useState(1);

  const [gamePlanModal, setGamePlanModal] = useState(false);
  const [gamePlanDayModal, setGamePlanDayModal] = useState(false);
  const [gamePlanLoading, setGamePlanLoading] = useState(false);
  const [gamePlanData, setGamePlanData] = useState([]);
  const [gamePlanCount, setGamePlanCount] = useState(null);

  const [importCsvModal, setImportCsvModal] = useState(false);
  const [selectImportTab, setSelectImportTab] = useState(1);

  const [showHotelModal, setShowHotelModal] = useState(false);
  const [hotelModalDetails, setHotelModalDetails] = useState([
    { hotel: "", confirmation_number: "" },
  ]);

  const [showLinkModal, setShowLinkModal] = useState(false);
  const [linkModalDetails, setLinkModalDetails] = useState([
    { name: "", image: "", link: "", description: "" },
  ]);

  const [highLightModal, setHighLightModal] = useState(false);
  const [highlightsModalDetails, setHighlightsModalDetails] = useState([
    { url: "", highlight_description: "" },
  ]);

  const [storyHighLightModal, setStoryHighLightModal] = useState(false);
  const [storyHighlightsModalDetails, setStoryHighlightsModalDetails] =
    useState([{ url: "" }]);

  const [hotelModalDetailsDuplicate, setHotelModalDetailsDuplicate] = useState({
    hotel: null,
    confirmationNumber: null,
  });

  const [contentModal, setContentModal] = useState(false);
  const [contentModalDetails, setContentModalDetails] = useState({
    transport_mode: "",
    no_of_breakfast: "",
    no_of_meals: "",
  });

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedAirportSearchTerm(filterData?.airportSearch);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [filterData?.airportSearch]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedHotelSearchTerm(filterData?.hotelSearch);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [filterData?.hotelSearch]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedDestinationSearchTerm(filterData?.destinationSearch);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [filterData?.destinationSearch]);

  useEffect(() => {
    if (id) {
      getEditionsData();
      getIitineraryData();
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      getGamePlanData();
    }
  }, [id]);

  // useEffect(() => {
  //   if (id || filterData?.edition) {
  //     getEditionsData();
  //     getIitineraryData();
  //   }
  // }, [id, filterData?.edition]);

  useEffect(() => {
    getEditionsList();
    // getHotelList();
    getTravelStyleList();
    getProjectList();
    // getDestinationList();
    getAddOnsList();
    getRegions();
    getUsers();
  }, []);

  useEffect(() => {
    if (id) {
      if (debouncedHotelSearchTerm) {
        getHotelList();
      }
    } else {
      getHotelList();
    }
  }, [debouncedHotelSearchTerm, id, hotelActivePage]);

  useEffect(() => {
    getAirportList();
  }, [airportActivePage, debouncedAirportSearchTerm]);

  useEffect(() => {
    if (id) {
      if (debouncedDestinationSearchTerm) {
        getDestinationList();
      }
    } else {
      getDestinationList();
    }
  }, [debouncedDestinationSearchTerm, id, destinationActivePage]);

  const clearInputs = () => {
    setFormData({
      duplicateEdition: "",
      project: null,
      editionName: "",
      startDate: "",
      endDate: "",
      travelStyle: null,
      maxSpots: "",
      publicSpots: "",
      hotel: null,
      destination: null,
      arrivalAirport: "",
      departureAirport: "",
      locationIdentifier: "",
      addOns: null,
      whatsappLink: "",
      heroImage: null,
      bannerImage: null,
      ipName: "",
      editionNumber: "",
      editionCost: "",
      editionDescription: "",
      shortDescription: "",
      aboutTrip: "",
      visaRequirement: false,
      preSalesGuidebook: "",
      singleRoomAddonCost: "",
      region: null,
      advancePayment: "",
      starEdition: false,
      highlightImage: null,
      description: "",
      edition_status: null,
      users: [],
      hotel_check_in: null,
      hotel_check_out: null,
      blueprint: "",
    });

    setAirportModalDetails({
      arrivalAirport: null,
      departureAirport: null,
      landingTime: null,
      takeOffTime: null,
      checkOutTime: null,
      checkInPrice: "",
      description: "",
    });

    setDuplicateData({});

    setContentModalDetails({
      transport_mode: "",
      no_of_breakfast: "",
      no_of_meals: "",
    });

    setHotelModalDetails([{ hotel: "", confirmation_number: "" }]);

    setLinkModalDetails([{ name: "", image: "", link: "", description: "" }]);
    setHighlightsModalDetails([{ url: "", highlight_description: "" }]);
    setStoryHighlightsModalDetails([{ url: "" }]);
    setHeroImagePreview(null);
    setBannerImagePreview(null);
    setHighlightMediaImages([]);
    setStoryMediaImages([]);
    setCardMediaImages([]);
  };

  const getEditionsData = (editionId = null, duplicateValue = false) => {
    const selectedEdition = editionId || filterData?.edition; // Prefer passed editionId if provided
    const currentId = id || selectedEdition; // Use id from URL if available

    setEditionId(currentId);

    // console.log(isDuplicate, "isDuplicate");

    fetchData(`api/editions/${currentId}`).then((res) => {
      if (res.success) {
        const data = res;

        // Now you can safely set the form data after both airport labels are fetched
        setFormData({
          project: data.project || null,
          editionName: data.name || "",
          startDate: data.start_date || "",
          endDate: data.end_date || "",
          travelStyle: data.travel_style?.map((item) => item) || null,
          maxSpots: data.max_spots || "",
          publicSpots: data.public_spots || "",
          hotel: data.hotel_details?.map((hotel) => hotel.id) || null, // Assuming hotel details are an array
          destination: data.destination || null,
          // arrivalAirport: data.arrival_airport || "",
          // departureAirport: data.departure_airport || "",
          // locationIdentifier: data.location_name || "",
          addOns: data.add_on_details?.map((addon) => addon.id) || null, // Assuming add-ons are an array
          // whatsappLink: data.whatsapp_link || "",
          heroImage: data.hero_image || null,
          bannerImage: data.banner_image || null,
          ipName: data.ip || "",
          editionNumber: data.edition_number || "",
          editionCost: data.cost || "",
          editionDescription: data.description || "",
          shortDescription: data.short_description || "",
          aboutTrip: data.about_trip || "",
          visaRequirement: data.is_visa,
          preSalesGuidebook: data.guidebook_link || "",
          singleRoomAddonCost: data.single_room_cost || "",
          region: data.region || null,
          advancePayment: data.advance_percentage || "",
          starEdition: data.is_star_edition || false,
          description: data.highlights_text || "",
          highlightImage: data.highlight_image || null,
          edition_status: data.status,
          users: duplicateValue ? [] : data.hosts?.map((e) => e?.uuid) || [],

          hotel_check_in: data?.hotel_check_in,
          hotel_check_out: data?.hotel_check_out,
          blueprint: data?.blueprint,
        });

        setDuplicateData(data);

        setAirportModalDetails({
          arrivalAirport: data.arrival_airport || "",
          departureAirport: data.departure_airport || "",
          landingTime: data.recommended_landing_time || "",
          takeOffTime: data.recommended_take_off_time || "",
          checkInPrice: data.early_check_in || "",
          description: data.travel_info || "",
        });

        const hotelModalData =
          data?.hotel_confirmation_data.length > 0
            ? data?.hotel_confirmation_data.map((hotel) => ({
                hotel: hotel.id,
                confirmation_number: hotel.confirmation_number,
              }))
            : [{ hotel: "", confirmation_number: "" }];

        setHotelModalDetails(hotelModalData);
        const hotel_ids = data?.hotel_confirmation_data
          .map((hotel) => hotel.id)
          .join(",");

        getHotelList(hotel_ids);

        const destination_ids = data?.destination.join(",");

        // console.log(data?.destination, destination_ids, "destination_ids");
        getDestinationList(destination_ids);

        const quickLinkModalData =
          data?.quick_links.length > 0
            ? data?.quick_links.map((item) => ({
                name: item.name,
                link: item.link,
                image: item?.image,
                description: item?.description,
              }))
            : [{ name: "", image: "", link: "", description: "" }];

        setLinkModalDetails(quickLinkModalData);
        const quickLinkImagePreviews =
          data?.quick_links.length > 0
            ? data?.quick_links.map((item) => item?.image)
            : [null];
        setQuickLinkImagePreview(quickLinkImagePreviews);

        const highLightModalData =
          data?.highlights_images.length > 0
            ? data?.highlights_images.map((item) => ({
                url: item.url,
                highlight_description: item.highlight_description,
              }))
            : [{ url: "", highlight_description: "" }];
        setHighlightsModalDetails(highLightModalData);

        const storyHighLightModalData =
          data?.story_media.length > 0
            ? data?.story_media.map((item) => ({
                url: item.url,
              }))
            : [{ url: "" }];
        setStoryHighlightsModalDetails(storyHighLightModalData);

        const highLightImagePreviews =
          data?.highlights_images.length > 0
            ? data?.highlights_images.map((item) => item?.url)
            : [null];
        setHighLightModalImagePreview(highLightImagePreviews);

        const storyHighLightImagePreviews =
          data?.story_media.length > 0
            ? data?.story_media.map((item) => item?.url)
            : [null];

        setStoryHighLightModalImagePreview(storyHighLightImagePreviews);

        setContentModalDetails({
          transport_mode: data?.whats_include?.transport_mode || "",
          no_of_breakfast: data?.whats_include?.no_of_breakfast || "",
          no_of_meals: data?.whats_include?.no_of_meals || "",
        });

        setHeroImagePreview(data?.hero_image);
        setBannerImagePreview(data?.banner_image);
        setHighlightMediaImages(data?.highlights_images || []);

        setUploadedUrls({
          heroImageUrl: data?.hero_image || "",
          bannerImageUrl: data?.banner_image || "",
        });

        const values = [
          {
            label: data.arrival_airport_name || "",
            value: data.arrival_airport || "",
          },
          {
            label: data.departure_airport_name || "",
            value: data.departure_airport || "",
          },
        ];

        setAirportList((prevData) => {
          // Combine previous records with new records
          const combinedData = [...(values || []), ...(prevData || [])];

          // Remove duplicates based on value (ID)
          const uniqueData = Array.from(
            new Set(combinedData.map((item) => item.value))
          ).map((id) => combinedData.find((item) => item.value === id));

          return uniqueData;
        });

        // setSelectedHotelList(
        //   data.hotel_details?.map((hotel) => hotel.id) || null
        // );

        setSelectedHotelList(
          data.hotel_confirmation_data?.map((hotel) => hotel.id) || null
        );

        if (duplicateValue) {
          toast.dismiss();
        }
      } else {
        clearInputs();
        toast.error(res.message);
      }
    });
  };

  const getIitineraryData = () => {
    setLoading(true);

    fetchData(`api/itinerary/?edition=${id || editionId}`).then((res) => {
      setLoading(false);
      if (res.success) {
        setItineraryData(res.data);
      } else {
        setItineraryData([]);
        toast.error(res.message);
      }
    });
  };

  const getGamePlanData = (edition_id) => {
    const mainId = edition_id || id || editionId;

    // console.log(id, editionId, edition_id, "mainId");

    // console.log(mainId, "mainId");
    setGamePlanLoading(true);

    fetchData(`api/gameplan/?related_edition=${mainId}`).then((res) => {
      setGamePlanLoading(false);
      if (res.success) {
        setGamePlanData(res.data);
        const lastItem = res.data[res.data.length - 1]; // Access the last element
        setGamePlanCount(lastItem?.day_number);
      } else {
        setGamePlanData([]);
        toast.error(res.message);
      }
    });
  };

  const getUsers = () => {
    setUserListLoading(true);
    fetchData(`api/accounts/?user_type=${7}&type=${"search_user"}`).then(
      (res) => {
        setUserListLoading(false);
        if (res.success) {
          const data = res.data.map((e) => {
            return {
              label: e.first_name + e.last_name,
              value: e.uuid,
            };
          });
          setUserList(data);
        } else {
          setUserList([]);
          toast.error(res.message);
        }
      }
    );
  };

  const getHotelList = (hotelIds = "") => {
    const newSearch = debouncedHotelSearchTerm;

    const limitNew = limit;
    const offsetNew = newSearch ? 0 : (hotelActivePage - 1) * limit;

    setHotelLoading(true);
    fetchData(
      `api/hotel/hotel-names/?limit=${limitNew}&offset=${offsetNew}&search=${encodeURIComponent(
        newSearch || ""
      )}&hotel_ids=${hotelIds}`
    ).then((res) => {
      setHotelLoading(false);
      if (res.success) {
        const newData = res.data?.map((e) => {
          return {
            label: e?.name,
            value: e?.id,
          };
        });

        setHotelList((prevData) => {
          // Combine previous records with new records
          const combinedData = [...(prevData || []), ...(newData || [])];

          // Remove duplicates based on value (ID)
          const uniqueData = Array.from(
            new Set(combinedData.map((item) => item.value))
          ).map((id) => combinedData.find((item) => item.value === id));

          return uniqueData;
        });
      } else {
        setHotelList([]);
        toast.error(res.message);
      }
    });
  };

  const getAirportList = (searchTerm = "") => {
    const newSearch = debouncedAirportSearchTerm || searchTerm;

    const limitNew = limit;
    const offsetNew = newSearch ? 0 : (airportActivePage - 1) * limit;

    setAirportLoading(true);
    fetchData(
      `api/project_settings/airport-names/?limit=${limitNew}&offset=${offsetNew}&search=${encodeURIComponent(
        newSearch || ""
      )}`
    ).then((res) => {
      setAirportLoading(false);
      if (res.success) {
        const newData = res.data?.map((e) => {
          return {
            label: e?.airport_name + " - " + e?.city_name,
            value: e?.id,
          };
        });

        setAirportList((prevData) => {
          // Combine previous records with new records
          const combinedData = [...(prevData || []), ...(newData || [])];

          // Remove duplicates based on value (ID)
          const uniqueData = Array.from(
            new Set(combinedData.map((item) => item.value))
          ).map((id) => combinedData.find((item) => item.value === id));

          return uniqueData;
        });
      } else {
        setAirportList([]); // Clear the list in case of failure
        toast.error(res.message);
      }
    });
  };

  const getDestinationList = (destinationIds = "") => {
    const newSearch = debouncedDestinationSearchTerm;

    // const limitNew = newSearch ? 0 : 20;
    // const offsetNew = newSearch ? 0 : (destinationActivePage - 1) * 20;

    const limitNew = limit;
    const offsetNew = newSearch ? 0 : (destinationActivePage - 1) * limit;

    let param = "";
    param += `?limit=${limitNew}&offset=${offsetNew}&search=${encodeURIComponent(
      newSearch || ""
    )}&destination_ids=${destinationIds}`;

    setDestinationLoading(true);
    fetchData(`api/destination/destination-select/${param}`).then((res) => {
      setDestinationLoading(false);

      if (res.success) {
        const newData = res.data?.map((e) => {
          return {
            label: e?.name + " - " + e?.country_name,
            value: e?.id,
          };
        });

        setDestinationList((prevData) => {
          // Combine previous records with new records
          const combinedData = [...(prevData || []), ...(newData || [])];

          // Remove duplicates based on value (ID)
          const uniqueData = Array.from(
            new Set(combinedData.map((item) => item.value))
          ).map((id) => combinedData.find((item) => item.value === id));

          return uniqueData;
        });

        // const data = res?.data.map((e) => {
        //   return {
        //     label: e?.name,
        //     value: e?.id,
        //   };
        // });
        // setDestinationList(data);
      } else {
        setDestinationList([]);
        toast.error(res.message);
      }
    });
  };

  const getProjectList = () => {
    setProjectLoading(true);
    let param = "";

    let offset = (activePage - 1) * limit;
    param += `?limit=${limit}&offset=${offset}`;

    fetchData(`api/project/${param}`).then((res) => {
      setProjectLoading(false);

      if (res.success) {
        const data = res?.data.map((e) => {
          return {
            label: e?.name,
            value: e?.id,
          };
        });
        setProjectList(data);
      } else {
        setProjectList([]);
        toast.error(res.message);
      }
    });
  };

  const getAddOnsList = (destinationId) => {
    setAddOnsLoading(true);
    let param = "";

    let offset = (activePage - 1) * limit;
    param += `?limit=${limit}&offset=${offset}&destination=${
      destinationId || ""
    }`;

    fetchData(`api/add-ons/${param}`).then((res) => {
      setAddOnsLoading(false);
      if (res.success) {
        const data = res?.data.map((e) => {
          return {
            label: e?.title_of_day,
            value: e?.id,
          };
        });
        setAddOnsList(data);
      } else {
        setAddOnsList([]);
        toast.error(res.message);
      }
    });
  };

  const getEditionsList = () => {
    setEditionStatusLoading(true);
    fetchData(`api/editions/editions-select/`, "get").then((res) => {
      setEditionStatusLoading(false);
      if (res.success) {
        const data = res.data.map((e) => {
          return { label: e.name, value: e.id };
        });
        setEditionsList(data);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getTravelStyleList = () => {
    setTravelStyleLoading(true);
    fetchData(`api/editions/editions-choices/`).then((res) => {
      setTravelStyleLoading(false);

      if (res.success) {
        const travel_data = res["travel_style_choices"];
        const edition_status = res["edition_status_choices"];
        const data = travel_data.map((item) => ({
          value: item.value,
          label: (
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={item.link}
                alt={item.display_name}
                style={{ width: 24, height: 24, marginRight: 8 }}
              />
              {item.display_name}
            </div>
          ),
        }));

        const edition_status_data = edition_status?.map((e) => {
          return {
            label: e?.display_name,
            value: e?.value,
          };
        });

        setEditionStatusList(edition_status_data);
        setTravelList(data);
      } else {
        setEditionStatusList([]);
        setTravelList([]);
        toast.error(res.message);
      }
    });
  };

  const getRegions = () => {
    setRegionLoading(true);
    let param = "";

    let offset = (activePage - 1) * 50;
    param += `?limit=${50}&offset=${offset}`;

    fetchData(`api/region/${param}`).then((res) => {
      setRegionLoading(false);
      if (res.success) {
        const newData = res.data?.map((r) => {
          return {
            label: r?.name,
            value: r?.id,
          };
        });

        setRegions(newData);
      } else {
        setRegions([]);
        toast.error(res.message);
      }
    });
  };

  // Set the state variables to empty arrays if the API call fails
  const handleInputChange = (e) => {
    const { name, value, type, files } = e.target;
    setFormData({
      ...formData,
      [name]: type === "file" ? files[0] : value,
    });
  };

  const fetchPresignedUrls = async (filesData) => {
    const body = {
      // upload_for: 1,
      files: filesData,
      thumbnail_files: filesData,
    };

    try {
      const response = await fetchData(
        "api/editions/pre-sign-urls/",
        "POST",
        body
      );
      if (response.success) {
        return {
          files: response.pre_signed_urls,
          thumbnails: response.pre_thumbnail_urls,
        };
      } else {
        message.error(response.message);
        return null;
      }
    } catch (error) {
      console.error("Error fetching presigned URLs:", error);
      message.error("Error fetching presigned URLs");
      return null;
    }
  };

  const uploadFiles = async (filesToUpload) => {
    if (filesToUpload.length === 0) return [];

    // Filter out files with invalid types
    const allowedTypes = ["image/jpeg", "image/png", "video/mp4"];
    const filteredFiles = filesToUpload.filter((file) =>
      allowedTypes.includes(file.type)
    );

    if (filteredFiles.length === 0) {
      console.error(
        "No valid file types selected. Only JPG, PNG, and MP4 are allowed."
      );
      return [];
    }

    // Prepare file data for presigned URL request
    const filesData = filteredFiles.map((file) => {
      const uniqueFileName = `${uuidv4()}.${file.name.split(".")[1]}`;
      return {
        file_name: uniqueFileName,
        file_type: file.type,
      };
    });

    // Fetch presigned URLs (assuming fetchPresignedUrls now also returns thumbUrl)
    const presignedUrls = await fetchPresignedUrls(filesData);
    if (!presignedUrls || presignedUrls.length === 0) {
      console.error("No presigned URLs received.");
      return [];
    }

    const { files, thumbnails } = presignedUrls;

    // Start timing
    const startTime = performance.now();

    // Array to store the URLs of successfully uploaded files
    const uploadedFileUrls = [];

    // Upload each file to its corresponding presigned URL
    await Promise.all(
      filteredFiles.map(async (file, index) => {
        const presignedUrl = files[index]?.pre_signed_url;
        const fileUrl = files[index]?.file_url;
        const thumbUrl = thumbnails[index]?.file_url;
        const uniqueFileName = files[index].file_name;

        if (!presignedUrl) {
          console.error(`No presigned URL available for file: ${file.name}`);
          return;
        }

        try {
          // Upload original file
          const uploadResponse = await fetch(presignedUrl, {
            method: "PUT",
            headers: {
              "Content-Type": file.type,
            },
            body: file,
          });

          if (uploadResponse.ok) {
            uploadedFileUrls.push(fileUrl); // Add the URL to the array
          } else {
            console.error(
              `File ${uniqueFileName} upload failed: ${uploadResponse.statusText}`
            );
          }

          // Compress image if it's an image type and upload to thumbUrl
          if (thumbUrl && file.type.startsWith("image/")) {
            const compressedImage = await compressImage(file, 200); // Compress to max 200px width/height

            const thumbResponse = await fetch(thumbUrl, {
              method: "PUT",
              headers: {
                "Content-Type": compressedImage.type,
              },
              body: compressedImage,
            });

            if (thumbResponse.ok) {
              // console.log(
              //   `Thumbnail for ${uniqueFileName} uploaded successfully!`
              // );
            } else {
              console.error(
                `Thumbnail for ${uniqueFileName} upload failed: ${thumbResponse.statusText}`
              );
            }
          }
        } catch (error) {
          console.error(
            `An error occurred while uploading file ${file.name}:`,
            error
          );
        }
      })
    );

    // End timing
    const endTime = performance.now();
    const timeTakenMs = endTime - startTime;
    const timeTakenSec = (timeTakenMs / 1000).toFixed(2);
    const timeTakenMin = Math.floor(timeTakenMs / 60000);
    const remainingSec = ((timeTakenMs % 60000) / 1000).toFixed(2);

    // console.log(
    //   `Uploaded ${uploadedFileUrls.length} files in ${timeTakenMin} minutes and ${remainingSec} seconds`
    // );

    // Return the array of successfully uploaded file URLs
    return uploadedFileUrls;
  };

  // Function to compress the image using Canvas API
  const compressImage = (file, maxSize) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;

        img.onload = () => {
          // Create canvas element
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          // Calculate new dimensions while maintaining the aspect ratio
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxSize) {
              height = Math.floor((height * maxSize) / width);
              width = maxSize;
            }
          } else {
            if (height > maxSize) {
              width = Math.floor((width * maxSize) / height);
              height = maxSize;
            }
          }

          // Set canvas dimensions and draw the image onto it
          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);

          // Convert the canvas to a Blob (compressed image)
          canvas.toBlob(
            (blob) => {
              if (blob) {
                const compressedFile = new File([blob], file.name, {
                  type: file.type,
                  lastModified: Date.now(),
                });
                resolve(compressedFile);
              } else {
                reject(new Error("Compression failed"));
              }
            },
            file.type,
            0.7 // Compression quality (0.7 = 70%)
          );
        };

        img.onerror = (error) => {
          reject(error);
        };
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };

  const handleQuickLinkFileChange = async (e, index, key) => {
    const { name, files } = e.target;
    if (files && files[0]) {
      const file = files[0];

      // Check file size (5MB = 5 * 1024 * 1024 bytes)
      if (file.size > 5 * 1024 * 1024) {
        toast.error("File size must be less than 5MB");
        return;
      }

      setQuickLinkImagePreview((prevPreviews) => {
        const updatedPreviews = [...prevPreviews];
        const reader = new FileReader();
        reader.onloadend = () => {
          updatedPreviews[index] = reader.result;
          setQuickLinkImagePreview(updatedPreviews);
        };
        reader.readAsDataURL(file);
        return updatedPreviews;
      });

      // Upload file and update the specific image URL in linkModalDetails
      const urls = await uploadFiles([file]);
      setLinkModalDetails((prevDetails) => {
        const updatedDetails = [...prevDetails];
        updatedDetails[index] = {
          ...updatedDetails[index],
          image: urls[0] || "",
        };
        return updatedDetails;
      });
    }
  };

  const handleQuickLinkFileRemove = (index) => {
    // Remove image preview
    setQuickLinkImagePreview((prevPreviews) => {
      const updatedPreviews = [...prevPreviews];
      updatedPreviews[index] = null; // or "" to remove the preview
      return updatedPreviews;
    });

    // Remove the uploaded file URL from the linkModalDetails
    setLinkModalDetails((prevDetails) => {
      const updatedDetails = [...prevDetails];
      updatedDetails[index] = {
        ...updatedDetails[index],
        image: "", // Clear the image URL
      };
      return updatedDetails;
    });
  };

  const handleHighLightModalFileChange = async (e, index, key) => {
    const { name, files } = e.target;
    if (files && files[0]) {
      const file = files[0];

      // Check file size (5MB = 5 * 1024 * 1024 bytes)
      if (file.size > 5 * 1024 * 1024) {
        toast.error("File size must be less than 5MB");
        return;
      }

      setHighLightModalImagePreview((prevPreviews) => {
        const updatedPreviews = [...prevPreviews];
        const reader = new FileReader();
        reader.onloadend = () => {
          updatedPreviews[index] = reader.result;
          setHighLightModalImagePreview(updatedPreviews);
        };
        reader.readAsDataURL(file);
        return updatedPreviews;
      });

      // Upload file and update the specific image URL in highlightsModalDetails
      const urls = await uploadFiles([file]);
      setHighlightsModalDetails((prevDetails) => {
        const updatedDetails = [...prevDetails];
        updatedDetails[index] = {
          ...updatedDetails[index],
          url: urls[0] || "",
        };
        return updatedDetails;
      });
    }
  };

  const handleStoryHighLightModalFileChange = async (e, index, key) => {
    const { name, files } = e.target;
    if (files && files[0]) {
      const file = files[0];

      // Check file size (5MB = 5 * 1024 * 1024 bytes)
      if (file.size > 5 * 1024 * 1024) {
        toast.error("File size must be less than 5MB");
        return;
      }

      setStoryHighLightModalImagePreview((prevPreviews) => {
        const updatedPreviews = [...prevPreviews];
        const reader = new FileReader();
        reader.onloadend = () => {
          updatedPreviews[index] = reader.result;
          setStoryHighLightModalImagePreview(updatedPreviews);
        };
        reader.readAsDataURL(file);
        return updatedPreviews;
      });

      // Upload file and update the specific image URL in storyHighlightsModalDetails
      const urls = await uploadFiles([file]);
      setStoryHighlightsModalDetails((prevDetails) => {
        const updatedDetails = [...prevDetails];
        updatedDetails[index] = {
          ...updatedDetails[index],
          url: urls[0] || "",
        };
        return updatedDetails;
      });
    }
  };

  const handleHighlightFileRemove = (index) => {
    // Remove image preview
    setHighLightModalImagePreview((prevPreviews) => {
      const updatedPreviews = [...prevPreviews];
      updatedPreviews[index] = null; // or "" to remove the preview
      return updatedPreviews;
    });

    // Remove the uploaded file URL from the highlightsModalDetails
    setHighlightsModalDetails((prevDetails) => {
      const updatedDetails = [...prevDetails];
      updatedDetails[index] = {
        ...updatedDetails[index],
        image: "", // Clear the image URL
      };
      return updatedDetails;
    });
  };
  const handleStoryHighlightFileRemove = (index) => {
    // Remove image preview
    setStoryHighLightModalImagePreview((prevPreviews) => {
      const updatedPreviews = [...prevPreviews];
      updatedPreviews[index] = null; // or "" to remove the preview
      return updatedPreviews;
    });

    // Remove the uploaded file URL from the storyHighlightsModalDetails
    setStoryHighlightsModalDetails((prevDetails) => {
      const updatedDetails = [...prevDetails];
      updatedDetails[index] = {
        ...updatedDetails[index],
        image: "", // Clear the image URL
      };
      return updatedDetails;
    });
  };

  const handleFileChange = async (e) => {
    const { name, files } = e.target;
    if (files && files[0]) {
      const file = files[0];

      // Check file size (5MB = 5 * 1024 * 1024 bytes)
      if (file.size > 5 * 1024 * 1024) {
        toast.error("File size must be less than 5MB");
        return;
      }

      setFormData((prevState) => ({
        ...prevState,
        [name]: file,
      }));

      const reader = new FileReader();
      reader.onloadend = () => {
        if (name === "heroImage") {
          setHeroImagePreview(reader.result);
        } else if (name === "bannerImage") {
          setBannerImagePreview(reader.result);
        } else if (name === "highlightImage") {
          setHighlightImagePreview(reader.result);
        }
      };
      reader.readAsDataURL(file);

      // Upload file and update URLs
      const urls = await uploadFiles([file]);
      setUploadedUrls((prevState) => ({
        ...prevState,
        [`${name}Url`]: urls[0] || "",
      }));
    }
  };

  const handleRemoveImage = (imageType) => {
    setFormData((prevState) => ({
      ...prevState,
      [imageType]: null,
    }));
    if (imageType === "heroImage") {
      setHeroImagePreview(null);
      setUploadedUrls((prevState) => ({
        ...prevState,
        heroImageUrl: "",
      }));
    } else if (imageType === "bannerImage") {
      setBannerImagePreview(null);
      setUploadedUrls((prevState) => ({
        ...prevState,
        bannerImageUrl: "",
      }));
    } else if (imageType === "highlightImage") {
      setHighlightImagePreview(null);
      setUploadedUrls((prevState) => ({
        ...prevState,
        highlightImageUrl: "",
      }));
    }
  };

  const validationRules = {
    // duplicateEdition: {
    //   required: true, // This field is required
    //   type: "number", // Expecting a number
    //   min: 1, // Minimum value (optional)
    //   message: "Duplicate edition is required and must be a number greater than or equal to 1.",
    // },
    project: {
      required: true, // Must select a project
      message: "Please select a project.",
    },
    editionName: {
      required: true, // Edition name is required
      maxLength: 100, // Maximum length of 100 characters
      message: "Edition name is required",
    },
    startDate: {
      required: true, // Start date is required
      message: "Start date is required.",
    },
    endDate: {
      required: true, // End date is required
      message: "End date is required.",
    },
    travelStyle: {
      required: true, // Travel style must be selected
      message: "Travel style is required.",
    },
    maxSpots: {
      required: true,
      type: "number",
      min: 1, // Min number of spots
      message: "Maximum spots are required.",
    },
    publicSpots: {
      required: true,
      type: "number",
      min: 0, // Min public spots (optional)
      message: "Public spots are required.",
    },
    hotel: {
      required: false,
    },
    destination: {
      required: true, // Destination is required
      message: "Destination is required.",
    },
    // arrivalAirport: {
    //   required: true, // Arrival airport is required
    //   message: "Arrival airport is required.",
    // },
    // departureAirport: {
    //   required: true, // Departure airport is required
    //   message: "Departure airport is required.",
    // },
    locationIdentifier: {
      required: false,
    },
    addOns: {
      required: false,
    },
    whatsappLink: {
      required: false,
    },
    // heroImage: {
    //   required: true, // WhatsApp link is required
    //   type: "image" || "url", // Must be a valid image
    //   message: "Hero image is required and must be a valid image or URL.",
    // },
    // bannerImage: {
    //   required: true, // WhatsApp link is required
    //   type: "image" || "url", // Must be a valid image
    //   message: "Banner image is required and must be a valid image or URL.",
    // },
    ipName: {
      required: true, // IP name is required
      message: "IP name is required.",
    },
    editionNumber: {
      required: false,
    },
    editionCost: {
      required: true,
      type: "number",
      min: 0, // Cost should be a non-negative number
      message: "Edition cost is required and must be a non-negative number.",
    },
    editionDescription: {
      required: true,
      message: "Edition description is required.",
    },
    shortDescription: {
      required: false,
      message: "Short description is required.",
    },
    aboutTrip: {
      required: false,
      message: "About trip is required.",
    },
    visaRequirement: {
      required: false,
    },
    preSalesGuidebook: {
      required: false,
      // type: "url",
    },
    singleRoomAddonCost: {
      required: true,
      type: "number", // Must be a number
      min: 0, // Minimum cost is 0
      message:
        "Single room add-on cost is required and must be a non-negative number.",
    },
    region: {
      required: true,
      message: "Region is required.",
    },
    advancePayment: {
      required: true,
      type: "number",
      min: 0,
      max: 100,
      message:
        "Advance payment is required and must be a percentage between 0 and 100.",
    },
    hotel_check_in: {
      required: true,
      message: "Hotel check-in date is required.",
    },
    users: {
      required: true,
      message: "At least one user must be selected.",
    },
    hotel_check_out: {
      required: true,
      message: "Hotel check-out date is required.",
    },
  };

  const airportValidationRules = {
    arrivalAirport: {
      required: true,
      message: "Arrival airport is required.",
    },
    departureAirport: {
      required: false,
      maxLength: 500,
    },
    landingTime: {
      required: false,
      maxLength: 500,
    },
    takeOffTime: {
      required: false,
      maxLength: 100,
    },
    // checkOutTime: {
    //   required: true,
    //   maxLength: 500,
    // },
    checkInPrice: {
      type: "number",
      required: false,
    },
    description: {
      required: false,
    },
  };

  const apiFormDataMapping = {
    project: "project",
    name: "editionName",
    start_date: "startDate",
    end_date: "endDate",
    travel_style: "travelStyle",
    max_spots: "maxSpots",
    public_spots: "publicSpots",
    destination: "destination", // Destination ID
    hotel: "hotel", // Hotel IDs array

    // Optional fields (commented in original code)
    // location_name: "locationIdentifier", // Location name

    add_on: "addOns", // Add-ons IDs array
    hero_image: "heroImageUrl", // Hero image URL
    banner_image: "bannerImageUrl", // Banner image URL

    ip: "ipName",
    edition_number: "editionNumber",
    cost: "editionCost",
    description: "editionDescription",
    short_description: "shortDescription",
    about_trip: "aboutTrip",
    is_visa: "visaRequirement",
    guidebook_link: "preSalesGuidebook", // Pre-sales guidebook link
    single_room_cost: "singleRoomAddonCost",
    region: "region",
    advance_percentage: "advancePayment",
    is_star_edition: "starEdition", // Star edition boolean

    // Media URLs (commented in original code)
    // story_media: "storyMediaUrls",       // Story media URLs array
    // card_media: "cardMediaUrls",         // Card media URLs array

    highlights_images: "highlightMediaUrls", // Highlights media URLs array
    highlights_text: "description", // Highlights text

    delete_images: "removeHighlightMediaImages", // Images to delete
    related_edition: "editionId", // Related edition ID for duplication

    status: "edition_status", // Edition status

    hosts: "users", // Hosts array

    // Airport details (spread in from airportDetailBody)
    // arrival_airport: "arrivalAirport",     // Arrival airport code
    // departure_airport: "departureAirport", // Departure airport code

    hotel_check_in: "hotel_check_in", // Hotel check-in date
    hotel_check_out: "hotel_check_out", // Hotel check-out date
    blueprint: "blueprint",
  };

  const airportModalMapping = {
    arrival_airport: "arrivalAirport",
    departure_airport: "departureAirport",
    recommended_landing_time: "landingTime",
    recommended_take_off_time: "takeOffTime",
    early_check_in: "checkInPrice",
    travel_info: "description",
  };

  const validateHotelModalDetails = (details) => {
    return details.some(
      (detail) =>
        detail.hotel.trim() !== "" && detail.confirmation_number.trim() !== ""
    );
  };

  const handleSubmit = () => {
    // Validate the main form data
    const { isValid: isValidForm, errors: formErrors } = validateFields(
      formData,
      validationRules
    );

    // Check if the main form is valid first
    if (!isValidForm) {
      console.error("Form validation errors:", formErrors);
      return; // Exit if the main form is not valid
    }

    // Proceed to validate the airport modal data only if the main form is valid
    const { isValid: isValidAirport, errors: airportErrors } = validateFields(
      airportModalDetails,
      airportValidationRules
    );

    // Check if the airport data is valid
    if (!isValidAirport) {
      console.error("Airport validation errors:", airportErrors);
      return; // Exit if the airport modal data is not valid
    }

    // Usage
    if (!validateHotelModalDetails(hotelModalDetails)) {
      toast.error("Please add at least one hotel with confirmation.");
      return;
    }

    // quick link modal Validation
    // const { isValid: isValidLinkModal, errors: linkModalErrors } =
    //   objectValidation(linkModalDetails, ["name", "image"]);

    // if (!isValidLinkModal) {
    //   console.error("Validation validation errors:", linkModalErrors);
    //   return; // Exit if the airport modal data is not valid
    // }

    const { isValid: isValidHighlightModal, errors: highlightModalErrors } =
      objectValidation(highlightsModalDetails, ["url"], "Highlight Details");

    if (!isValidHighlightModal) {
      console.error("Validation validation errors:", highlightModalErrors);
      return; // Exit if the airport modal data is not valid
    }

    const {
      isValid: isValidStoryHighlightModal,
      errors: storyHighlightModalErrors,
    } = objectValidation(
      storyHighlightsModalDetails,
      ["url"],
      "Story Highlight Details"
    );

    if (!isValidStoryHighlightModal) {
      console.error("Validation validation errors:", storyHighlightModalErrors);
      return; // Exit if the airport modal data is not valid
    }

    const { isValid: isValidContentModal, errors: contentModalErros } =
      objectValidation(
        [contentModalDetails],
        ["no_of_meals", "no_of_breakfast"],
        "What's Include Content Details"
      );

    if (!isValidContentModal) {
      console.error("Validation validation errors:", storyHighlightModalErrors);
      return; // Exit if the airport modal data is not valid
    }

    if (!heroImagePreview || !uploadedUrls?.heroImageUrl) {
      toast.error("Please upload a hero image.");
      return;
    }

    if (!bannerImagePreview || !uploadedUrls?.bannerImageUrl) {
      toast.error("Please upload a Banner image.");
      return;
    }

    // If both the form and airport data are valid, proceed to the next step
    const msg = id
      ? "Updating Edition"
      : isDuplicate
      ? "Duplicating Edition"
      : "Adding Edition";
    toast.loading(msg);

    const isURL = (str) => {
      try {
        new URL(str);
        return true;
      } catch {
        return false;
      }
    };

    const addIfAirportModalNotAirportModalEmpty = (key, value) => {
      // Check if 'id' or 'editionId' exists
      const hasId = id || editionId;

      if (hasId) {
        // Proceed with checking realValue and duplicateValue if 'id' or 'editionId' is present
        const realValue = airportModalDetails[airportModalMapping[key]]; // Real value from formData
        const duplicateValue = duplicateData[key];

        // Check if the real value and duplicate value have changed
        if (realValue !== duplicateValue) {
          if (key === "early_check_in") {
            return { [key]: 0 };
          } else {
            return { [key]: realValue }; // Return the key-value pair if the values have changed
          }
        }
      }

      // If the 'id' or 'editionId' is not present, or if values are not changed, check for empty or undefined
      const realValue = airportModalDetails[airportModalMapping[key]]; // Real value from formData
      if (realValue !== "" && realValue !== undefined && realValue !== null) {
        return { [key]: realValue }; // Return the key-value pair if the value is not empty or undefined
      }

      // If it's empty or undefined, return an empty object
      return {};
    };

    const airportDetailBody = {
      ...addIfAirportModalNotAirportModalEmpty(
        "arrival_airport",
        airportModalDetails.arrivalAirport
      ),
      ...addIfAirportModalNotAirportModalEmpty(
        "departure_airport",
        airportModalDetails.departureAirport
      ),
      ...addIfAirportModalNotAirportModalEmpty(
        "recommended_landing_time",
        airportModalDetails.landingTime
      ),
      ...addIfAirportModalNotAirportModalEmpty(
        "recommended_take_off_time",
        airportModalDetails.takeOffTime
      ),
      // ...addIfAirportModalNotAirportModalEmpty(
      //   "early_check_in",
      //   airportModalDetails.checkInPrice
      // ), // comment out on 11 feb
      ...addIfAirportModalNotAirportModalEmpty(
        "travel_info",
        airportModalDetails.description
      ),
    };

    // Helper function to add properties only if the value is non-empty
    const addValuesIfNotEmpty = (key, value) => {
      // Check if 'id' or 'editionId' exists
      const hasId = id || editionId;

      // console.log(hasId, "hasId");

      if (hasId) {
        const realValue = formData[apiFormDataMapping[key]]; // Real value from formData
        const duplicateValue = duplicateData[key];

        // console.log(
        //   "key----------",
        //   key,
        //   "real----------",
        //   realValue,
        //   "duplicate--------",
        //   duplicateValue,
        //   "hasId"
        // );

        // Check if the real value and duplicate value have changed
        if (realValue !== duplicateValue) {
          return { [key]: realValue }; // Return the key-value pair if the values have changed
        }
      }

      // If the 'id' or 'editionId' is not present, or if values are not changed, check for empty or undefined
      const realValue = formData[apiFormDataMapping[key]]; // Real value from formData
      if (realValue !== "" && realValue !== undefined && realValue !== null) {
        return { [key]: realValue }; // Return the key-value pair if the value is not empty or undefined
      }

      // If it's empty or undefined, return an empty object
      return {};
    };

    const body = {
      ...addValuesIfNotEmpty("project", formData.project),
      ...addValuesIfNotEmpty("name", formData.editionName),
      ...addValuesIfNotEmpty("start_date", formData.startDate),
      ...addValuesIfNotEmpty("end_date", formData.endDate),
      ...addValuesIfNotEmpty("travel_style", formData.travelStyle),
      ...addValuesIfNotEmpty("max_spots", formData.maxSpots),
      ...addValuesIfNotEmpty("public_spots", formData.publicSpots),
      ...addValuesIfNotEmpty("destination", formData.destination),
      ...addValuesIfNotEmpty("hotel", formData.hotel),

      // ...addValuesIfNotEmpty("location_name", formData.locationIdentifier),

      add_on: formData.addOns || [], // Always include as empty array if undefined
      // ...addValuesIfNotEmpty("hero_image", uploadedUrls?.heroImageUrl),
      // ...addValuesIfNotEmpty("banner_image", uploadedUrls?.bannerImageUrl),

      hero_image: uploadedUrls?.heroImageUrl,
      banner_image: uploadedUrls?.bannerImageUrl,

      ...addValuesIfNotEmpty("ip", formData.ipName),
      ...addValuesIfNotEmpty("edition_number", formData.editionNumber),
      ...addValuesIfNotEmpty("cost", formData.editionCost),
      ...addValuesIfNotEmpty("description", formData.editionDescription),
      ...addValuesIfNotEmpty("short_description", formData.shortDescription),
      ...addValuesIfNotEmpty("about_trip", formData.aboutTrip),
      ...addValuesIfNotEmpty("is_visa", formData.visaRequirement),
      ...addValuesIfNotEmpty("guidebook_link", formData.preSalesGuidebook),
      ...addValuesIfNotEmpty("single_room_cost", formData.singleRoomAddonCost),
      ...addValuesIfNotEmpty("region", formData.region),
      ...addValuesIfNotEmpty("advance_percentage", formData.advancePayment),
      is_star_edition: formData?.starEdition,
      // ...addValuesIfNotEmpty("is_star_edition", formData?.starEdition),

      // story_media: storyMediaUrls,
      // card_media: cardMediaUrls,

      // highlights_images: highlightMediaUrls || [], // commented on 6-12-2024

      // ...addValuesIfNotEmpty("highlights_text", formData?.description),

      ...(removeHighlightMediaImages.length > 0
        ? { delete_images: removeHighlightMediaImages }
        : {}),

      ...(isDuplicate ? { related_edition: editionId } : {}),
      ...(editionId && id ? { status: formData?.edition_status || "" } : {}), // Only include if IDs are provided

      hosts: formData.users || [], // Always include as empty array if undefined

      ...airportDetailBody, // Spread in airport details as is

      ...addValuesIfNotEmpty("hotel_check_in", formData.hotel_check_in),
      ...addValuesIfNotEmpty("hotel_check_out", formData.hotel_check_out),

      ...(Object.values(hotelModalDetails[0]).every((value) => value === "")
        ? { hotel_confirmation_data: [] }
        : {
            hotel_confirmation_data: hotelModalDetails
              .map(filterNonEmptyValues) // Apply filter to each object
              .filter((item) => Object.keys(item).length > 0), // Remove completely empty objects
          }),

      // ...result

      ...(Object.values(linkModalDetails[0]).every((value) => value === "")
        ? { quick_links: [] }
        : {
            quick_links: linkModalDetails
              .map(filterNonEmptyValues) // Apply filter to each object
              .filter((item) => Object.keys(item).length > 0), // Remove completely empty objects
          }),

      ...(Object.values(highlightsModalDetails[0]).every(
        (value) => value === ""
      )
        ? { highlights_images: [] }
        : {
            highlights_images: highlightsModalDetails
              .map(filterNonEmptyValues) // Apply filter to each object
              .filter((item) => Object.keys(item).length > 0), // Remove completely empty objects
          }),

      ...(Object.values(storyHighlightsModalDetails[0]).every(
        (value) => value === ""
      )
        ? { story_media: [] }
        : {
            story_media: storyHighlightsModalDetails
              .map(filterNonEmptyValues) // Apply filter to each object
              .filter((item) => Object.keys(item).length > 0), // Remove completely empty objects
          }),

      ...addValuesIfNotEmpty("blueprint", formData.blueprint),

      ...(Object.values(contentModalDetails).every((value) => value === "")
        ? { whats_include: [] }
        : {
            whats_include: filterNonEmptyValues(contentModalDetails),
          }),
    };

    console.log(filterNonEmptyValues(contentModalDetails));

    console.log(body, "body");

    let api = id ? `api/editions/${id}/` : "api/editions/";
    let method = id ? "put" : "post";

    fetchData(api, method, body, "", false).then((res) => {
      toast.remove();
      setSending(false);
      if (res.success) {
        console.log(res, "res");
        setEditionId(res?.id);

        // console.log(formData?.hotel, "res");
        setSelectedHotelList(formData?.hotel || null);
        if (id) {
          if (debouncedAirportSearchTerm) {
            setFilterData((prevState) => ({
              ...prevState,
              airportSearch: null,
            }));
          }

          if (debouncedHotelSearchTerm) {
            setFilterData((prevState) => ({
              ...prevState,
              hotelSearch: null,
            }));
          }

          getEditionsData();
          setHighlightMediaUrls([]);
          toast.success("Success");
        } else {
          setEditionId(res?.id);
          setFilterDataValue("edition", null);
          if (isDuplicate) {
            setGamePlanLoading(true);
            setLoading(true);
            getGamePlanData(res?.id);
            getIitineraryData();
            setIsDuplicate(false);
          }

          // setItineraryModal(true);
          // setMakeModalFreezed(true);
          clearInputs();
          getEditionsData(res?.id);
          const msg = isDuplicate
            ? "Edition Duplicated"
            : "Please Add Itinerary To Complete Edition";
          toast.success(msg);
        }
      } else {
        toast.error(res.message);
      }
    });
  };

  const handleEdit = (data) => {
    setModalItineraryData(data);
    setItineraryModal(true);
  };

  const onDelete = (id) => {
    toast.loading("Deleting Itinerary...");
    fetchData(`api/itinerary/${id}/`, "delete").then((res) => {
      toast.dismiss();
      if (res.success) {
        setLoading(true);
        getIitineraryData();
        toast.success("Record deleted successfully");
      } else {
        toast.error(res.message);
      }
    });
  };

  // Function to disable dates before the start date
  const disabledDate = (
    current,
    dateType,
    referenceDate,
    startLimit,
    endLimit
  ) => {
    const today = dayjs().startOf("day");

    // Convert limits to dayjs objects if they exist
    const startLimitDate = startLimit ? dayjs(startLimit).startOf("day") : null;
    const endLimitDate = endLimit ? dayjs(endLimit).endOf("day") : null;

    if (dateType === "start") {
      // For start date:
      // - disable dates before today
      // - disable dates before startLimit if provided
      // - disable dates after endLimit if provided
      return (
        current &&
        (current < today ||
          (startLimitDate && current < startLimitDate) ||
          (endLimitDate && current > endLimitDate))
      );
    } else if (dateType === "end") {
      // For end date:
      // - disable dates before today
      // - disable dates before the selected start date (referenceDate)
      // - disable dates before startLimit if provided
      // - disable dates after endLimit if provided
      return (
        current &&
        (current < today ||
          (referenceDate && current < dayjs(referenceDate).startOf("day")) ||
          (startLimitDate && current < startLimitDate) ||
          (endLimitDate && current > endLimitDate))
      );
    }
  };

  const setFilterDataValue = (key, value) => {
    const common_data = {
      ...filterData,
      [key]: value,
    };
    setFilterData(common_data);
  };

  const tabs = [
    { id: 1, name: "Itinerary" },
    { id: 2, name: "Game Plan" },
  ];

  return (
    <div>
      <div className="row g-5 g-xxl-10">
        <div className={`${id ? "col-xxl-6" : "col-xxl-6"}`}>
          {/*begin::Tables Widget 9*/}
          <div className="card card-xxl-stretch mb-5 mb-xl-8 ">
            {/*begin::Header*/}
            <div className="card-header ">
              <h3 className="card-title fw-bold mb-0 fs-3 d-flex align-items-center">
                <Link
                  to={routesList["manage_editions"].path}
                  className="d-flex me-3 btn btn-sm btn-icon btn-light-primary"
                >
                  <i className="ki-outline ki-arrow-left fs-2 " />
                </Link>
                {id || editionId ? "Edit Edition" : "Add Edition"}
              </h3>
              <div className="card-toolbar"></div>
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className="card-body hover-scroll-y mb-10 pe-0">
              <div className="h-600px pe-5">
                <div>
                  <div className="row g-5 mb-5 align-items-center">
                    {(!id || !editionId) && (
                      <>
                        <div className="col-md-12 fv-row">
                          {" "}
                          <h4 className="mb-3">Duplicate Edition</h4>
                        </div>

                        <div className="col-md-12 fv-row mb-5">
                          <Select
                            value={!editionsListLoading && filterData?.edition}
                            showSearch
                            optionFilterProp="label"
                            onChange={(value) => {
                              toast.loading("Please wait...");
                              setFilterDataValue("edition", value);
                              const duplicateValue = true;
                              setIsDuplicate(duplicateValue);
                              getEditionsData(value, duplicateValue);
                            }}
                            placeholder="Select Edition"
                            style={{ width: "100%" }}
                            // dropdownStyle={{
                            //   maxHeight: "100px",
                            //   overflow: "auto",
                            // }}
                            options={editionsList}
                            allowClear
                            onClear={() => {
                              setTimeout(() => {
                                if (isDuplicate) {
                                  setIsDuplicate(false);
                                  clearInputs();
                                  setFilterDataValue("edition", null);
                                  setEditionId(null);
                                }
                              }, 1000);

                              // getEditionsData(null);
                            }}
                            onPopupScroll={(e) => {
                              const bottom =
                                e.target.scrollHeight ===
                                e.target.scrollTop + e.target.clientHeight;

                              if (bottom) {
                              }
                            }}
                            size="large"
                            loading={editionsListLoading}
                            disabled={editionsListLoading}
                          />
                        </div>
                      </>
                    )}

                    {/* Story Media */}
                    {/* <div className="col-md-12 fv-row">
                      <h4>Story Media</h4>
                      <Dropzone
                        pictures={storyMediaImages}
                        setPictures={setStoryMediaImages}
                        removedImages={removeStoryMediaImages}
                        setRemovedImages={setRemoveStoryMediaImages}
                        shouldUpload={true} // Pass the flag to Dropzone 1
                        setUploadedUrls={setStoryMediaUrls}
                      />
                    </div> */}

                    {/* Card Media */}
                    {/* <div className="col-md-12 fv-row">
                      <h4>Card Media</h4>

                      <Dropzone
                        pictures={cardMediaImages}
                        setPictures={setCardMediaImages}
                        removedImages={removeCardMediaImages}
                        setRemovedImages={setRemoveCardMediaImages}
                        shouldUpload={true} // Pass the flag to Dropzone 2
                        setUploadedUrls={setCardMediaUrls}
                      />
                    </div> */}
                  </div>
                </div>

                {(!id || !editionId) && (
                  <>
                    <div className="separator separator my-3 separator-dashed border-gray-300" />
                  </>
                )}

                <div className="row g-5 mb-5 align-items-center">
                  <div className="col-md-12 fv-row">
                    <h4>
                      {id || editionId
                        ? "Edit Existing Edition"
                        : "Create New Edition"}
                    </h4>
                  </div>

                  <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2">Select Project</label>
                    <Select
                      showSearch
                      optionFilterProp="label"
                      name="project"
                      value={!projectLoading && formData.project}
                      onChange={(value) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          project: value,
                        }));
                      }}
                      placeholder="Choose Option"
                      className="fs-7 w-100"
                      style={{ width: "100%" }}
                      dropdownStyle={{ zIndex: 1100 }}
                      size="large"
                      options={projectList}
                      disabled={isDuplicate || projectLoading}
                      loading={projectLoading}
                    ></Select>
                  </div>

                  <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2">Edition Name</label>
                    <input
                      type="text"
                      className="form-control fs-7"
                      name="editionName"
                      value={formData.editionName}
                      onChange={handleInputChange}
                      disabled={isDuplicate}
                      placeholder="Enter Your Edition Name"
                    />
                  </div>

                  <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2">Start Date</label>
                    <DatePicker
                      showTime={{
                        format: "HH:mm",
                        defaultValue: dayjs().hour(14).minute(0),
                      }}
                      format="DD-MM-YYYY HH:mm" // Desired display format
                      value={
                        formData.startDate
                          ? parseISOToUTCDate(formData.startDate) // Parse the ISO date in UTC format
                          : null
                      }
                      onChange={(date, dateString) => {
                        // Convert date to ISO format in UTC if dateString is valid
                        // const utcDateString = date
                        //   ? dayjs(date).utc().toISOString()
                        //   : null;

                        const utcDateString = date
                          ? date.format("YYYY-MM-DD HH:mm")
                          : null;

                        console.log(
                          date,
                          "___",
                          dateString,
                          utcDateString,
                          "dateString"
                        );

                        setFormData({
                          ...formData,
                          startDate: utcDateString,
                          endDate: null,
                          hotel_check_in: null,
                          hotel_check_out: null,
                        });
                      }}
                      className="fs-2 w-100"
                      placeholder="Pick a Start Date"
                      disabledDate={(current) => disabledDate(current, "start")}
                      size="large"
                    />
                  </div>

                  <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2">End Date</label>
                    <DatePicker
                      showTime={{
                        format: "HH:mm",
                        defaultValue: dayjs().hour(10).minute(0),
                      }}
                      format="DD-MM-YYYY HH:mm" // Updated format to DD--MM--YYYY HH:mm
                      value={
                        formData.endDate
                          ? parseISOToUTCDate(formData.endDate) // Parse the ISO date in UTC format
                          : null
                      }
                      onChange={(date, dateString) => {
                        // Convert date to ISO format in UTC if dateString is valid
                        const utcDateString = date
                          ? date.format("YYYY-MM-DD HH:mm")
                          : null;

                        console.log(
                          date,
                          "___",
                          dateString,
                          utcDateString,
                          "dateString"
                        );

                        setFormData({
                          ...formData,
                          endDate: utcDateString,
                          hotel_check_in: null,
                          hotel_check_out: null,
                        });
                      }}
                      className="fs-7 w-100"
                      placeholder="Pick an End Date"
                      disabledDate={(current) =>
                        disabledDate(current, "end", formData.startDate)
                      }
                      size="large"
                    />
                  </div>

                  <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2">Travel Style</label>
                    <Select
                      showSearch
                      optionFilterProp="label"
                      mode="multiple"
                      onSearch={(value) => {
                        // // Filter travelStyleList based on the search value
                        // const filteredData = travelStyleList?.filter(
                        //   (option) => {
                        //     // Extract the display name (second child) from the label JSX
                        //     const displayName =
                        //       option?.label?.props?.children[1]; // This gets the name of the travel style
                        //     return displayName
                        //       ?.toLowerCase()
                        //       .includes(value.toLowerCase());
                        //   }
                        // );
                        // setTimeout(() => {
                        //   setTravelList(filteredData);
                        //   console.log(filteredData, "Filtered data");
                        // }, 2000);
                      }}
                      name="travelStyle"
                      value={!travelStyleLoading && formData.travelStyle}
                      onChange={(value) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          travelStyle: value,
                        }));
                      }}
                      placeholder="Choose Option"
                      className="fs-7 w-100"
                      style={{ width: "100%" }}
                      dropdownStyle={{ zIndex: 1100 }}
                      size="large"
                      options={travelStyleList}
                      disabled={isDuplicate || travelStyleLoading}
                      loading={travelStyleLoading}
                    ></Select>
                  </div>

                  <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2">
                      Max Spots (Internal)
                    </label>
                    <input
                      type="text"
                      className="form-control fs-7"
                      name="maxSpots"
                      value={formData.maxSpots}
                      onChange={handleInputChange}
                      disabled={isDuplicate}
                      placeholder="Add No. of Max Spots"
                    />
                  </div>

                  <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2">
                      Number of Spots (Public)
                    </label>
                    <input
                      type="text"
                      className="form-control fs-7"
                      name="publicSpots"
                      value={formData.publicSpots}
                      onChange={handleInputChange}
                      disabled={isDuplicate}
                      placeholder="Add No. of Spots"
                    />
                  </div>

                  {/* <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2">Add Hotel</label>
                    <Select
                      mode="multiple"
                      showSearch
                      optionFilterProp="label"
                      name="hotel"
                      value={formData.hotel}
                      onChange={(value) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          hotel: value,
                        }));
                      }}
                      placeholder="Select Hotel"
                      className="fs-7 w-100"
                      style={{ width: "100%" }}
                      dropdownStyle={{ zIndex: 1100 }}
                      size="large"
                      options={hotelList}
                      disabled={isDuplicate}
                      loading={hotelLoading}
                    ></Select>
                  </div> */}

                  {/* <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2">
                      Hotel Check In Time
                    </label>
                    <DatePicker
                      showTime={{
                        format: "HH:mm",
                      }}
                      format="DD-MM-YYYY HH:mm"
                      value={
                        formData.hotel_check_in
                          ? parseISOToUTCDate(formData.hotel_check_in)
                          : null
                      }
                      onChange={(date, dateString) => {
                        // Convert date to ISO format in UTC if dateString is valid
                        const utcDateString = date
                          ? date.format("YYYY-MM-DD HH:mm")
                          : null;

                        setFormData({
                          ...formData,
                          hotel_check_in: utcDateString,
                          hotel_check_out: null,
                        });
                      }}
                      className="fs-7 w-100"
                      placeholder="Pick a Hotel Check In Time"
                      disabledDate={(current) =>
                        disabledDate(
                          current,
                          "start",
                          null,
                          formData?.startDate,
                          formData?.endDate
                        )
                      }
                      size="large"
                    />
                  </div>

                  <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2">
                      Hotel Check Out Time
                    </label>

                    <DatePicker
                      showTime={{
                        format: "HH:mm",
                      }}
                      format="DD-MM-YYYY HH:mm"
                      value={
                        formData.hotel_check_out
                          ? parseISOToUTCDate(formData.hotel_check_out)
                          : null
                      }
                      onChange={(date, dateString) => {
                        // Convert date to ISO format in UTC if dateString is valid
                        const utcDateString = date
                          ? date.format("YYYY-MM-DD HH:mm")
                          : null;

                        setFormData({
                          ...formData,
                          hotel_check_out: utcDateString,
                        });
                      }}
                      className="fs-7 w-100"
                      placeholder="Pick a Hotel Checkout Time"
                      disabledDate={(current) =>
                        disabledDate(
                          current,
                          "end",
                          formData?.hotel_check_in,
                          formData?.startDate,
                          formData?.endDate
                        )
                      }
                      size="large"
                    />
                  </div> */}

                  <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2">Destination</label>

                    <Select
                      // getPopupContainer={(triggerNode) =>
                      //   triggerNode.parentNode
                      // }
                      mode="multiple"
                      showSearch
                      onSearch={(value) => {
                        setFilterData((prevState) => ({
                          ...prevState,
                          destinationSearch: value,
                        }));
                      }}
                      optionFilterProp="label"
                      // name="destination"
                      value={formData.destination}
                      onChange={(value) => {
                        getAddOnsList(value);
                        setFormData((prevState) => ({
                          ...prevState,
                          destination: value,
                        }));
                      }}
                      placeholder="Select Destination"
                      className="fs-7 w-100"
                      style={{ width: "100%" }}
                      // dropdownStyle={{ zIndex: 1100, maxHeight: "200px" }}
                      size="large"
                      options={destinationList}
                      disabled={isDuplicate}
                      loading={destinationLoading}
                      onClear={() => {
                        setFormData((prevState) => ({
                          ...prevState,
                          destination: null,
                        }));
                        setDestinationActivePage(1);
                      }}
                      onPopupScroll={(e) => {
                        const bottom =
                          Math.abs(
                            e.target.scrollHeight -
                              (e.target.scrollTop + e.target.clientHeight)
                          ) < 1.5;

                        if (bottom) {
                          setDestinationLoading(true);
                          setDestinationActivePage(
                            (prevState) => prevState + 1
                          );
                        }
                      }}
                      notFoundContent={
                        <>
                          <div className="d-flex justify-content-center align-items-center">
                            {destinationLoading ? (
                              <Spin size="small" />
                            ) : (
                              "No Data Found"
                            )}
                          </div>
                        </>
                      }
                    ></Select>
                  </div>

                  {/* <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2">
                      Name of Location/s (Identifier)
                    </label>
                    <input
                      type="text"
                      className="form-control fs-7"
                      name="locationIdentifier"
                      value={formData.locationIdentifier}
                      onChange={handleInputChange}
                      disabled={isDuplicate}
                      placeholder="Enter Location Name"
                    />
                  </div> */}

                  {/* <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2">
                      Whatsapp Link (Closed Group)
                    </label>
                    <input
                      type="text"
                      className="form-control fs-7"
                      name="whatsappLink"
                      value={formData.whatsappLink}
                      onChange={handleInputChange}
                      disabled={isDuplicate}
                      placeholder="Enter Whatsapp Link"
                    />
                  </div> */}

                  <div
                    className="col-md-6 fv-row"
                    style={{ pointerEvents: isDuplicate ? "none" : "auto" }}
                  >
                    <label className="fs-6 fw-bold mb-2">Hero Image</label>
                    {heroImagePreview ? (
                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                          marginLeft: "10px",
                        }}
                      >
                        <AntdImage
                          src={heroImagePreview}
                          alt="Hero Preview"
                          style={{
                            width: "150px",
                            height: "120px",
                            objectFit: "cover",
                            borderRadius: "8px",
                            border: "2px solid #ccc",
                          }}
                        />
                        <i
                          title="Remove Image"
                          className="ki-outline ki-cross fs-2"
                          onClick={() => handleRemoveImage("heroImage")}
                          style={{
                            position: "absolute",
                            top: "-8px",
                            right: "-8px",
                            cursor: "pointer",
                            zIndex: 2,
                            backgroundColor: "white",
                            borderRadius: "50%",
                          }}
                        />
                      </div>
                    ) : (
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        className="form-control fs-7"
                        name="heroImage"
                        onChange={handleFileChange}
                        disabled={isDuplicate}
                      />
                    )}
                  </div>

                  <div
                    className="col-md-6 fv-row"
                    style={{ pointerEvents: isDuplicate ? "none" : "auto" }}
                  >
                    <label className="fs-6 fw-bold mb-2">Banner Image</label>

                    {bannerImagePreview ? (
                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                          marginLeft: "10px",
                        }}
                      >
                        <AntdImage
                          src={bannerImagePreview}
                          alt="Baneer Preview"
                          style={{
                            width: "150px",
                            height: "120px",
                            objectFit: "cover",
                            borderRadius: "8px",
                            border: "2px solid #ccc",
                          }}
                        />
                        <i
                          title="Remove Image"
                          className="ki-outline ki-cross fs-2"
                          onClick={() => handleRemoveImage("bannerImage")}
                          style={{
                            position: "absolute",
                            top: "-8px",
                            right: "-8px",
                            cursor: "pointer",
                            zIndex: 2,
                            backgroundColor: "white",
                            borderRadius: "50%",
                          }}
                        />
                      </div>
                    ) : (
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        className="form-control fs-7"
                        name="bannerImage"
                        onChange={handleFileChange}
                        disabled={isDuplicate}
                      />
                    )}
                  </div>

                  <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2">Add Add-Ons</label>
                    <Select
                      mode="multiple"
                      showSearch
                      optionFilterProp="label"
                      name="addOns"
                      value={!addOnsLoading ? formData.addOns : null}
                      onChange={(value) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          addOns: value,
                        }));
                      }}
                      placeholder="Choose Add ons"
                      className="fs-7 w-100"
                      style={{ width: "100%" }}
                      dropdownStyle={{ zIndex: 1100 }}
                      size="large"
                      options={addOnsList}
                      disabled={addOnsLoading}
                      loading={addOnsLoading}
                    ></Select>
                  </div>

                  <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2">Name of IP</label>
                    <input
                      type="text"
                      className="form-control fs-7"
                      name="ipName"
                      value={formData.ipName}
                      onChange={handleInputChange}
                      disabled={isDuplicate}
                      placeholder="Enter Name of IP"
                    />
                  </div>

                  {(id || editionId) && (
                    <div className="col-md-6 fv-row">
                      <label className="fs-6 fw-bold mb-2">
                        Edition Number
                      </label>
                      <input
                        type="text"
                        className="form-control fs-7"
                        name="editionNumber"
                        value={formData.editionNumber}
                        onChange={handleInputChange}
                        disabled={true}
                        placeholder="Enter Edition Number"
                      />
                    </div>
                  )}

                  <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2">
                      Cost of Edition(₹)
                    </label>
                    <input
                      type="number"
                      className="form-control fs-7"
                      name="editionCost"
                      value={formData.editionCost}
                      onChange={handleInputChange}
                      placeholder="Enter Total Cost of Edition"
                    />
                  </div>

                  <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2">
                      Edition Description
                    </label>
                    <textarea
                      className="form-control fs-7"
                      name="editionDescription"
                      rows={4}
                      value={formData.editionDescription}
                      onChange={handleInputChange}
                      disabled={isDuplicate}
                      placeholder="Enter Edition Description"
                    ></textarea>
                  </div>

                  <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2">
                      Short Description
                    </label>
                    <textarea
                      className="form-control fs-7"
                      name="shortDescription"
                      rows={4}
                      value={formData.shortDescription}
                      onChange={handleInputChange}
                      disabled={isDuplicate}
                      placeholder="Enter Short Description"
                    ></textarea>
                  </div>

                  <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2">
                      Visa Requirement
                    </label>
                    <Select
                      showSearch
                      optionFilterProp="label"
                      name="visaRequirement"
                      value={formData.visaRequirement}
                      onChange={(value) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          visaRequirement: value,
                        }));
                      }}
                      placeholder="Choose Visa Requirement"
                      className="fs-7 w-100"
                      style={{ width: "100%" }}
                      dropdownStyle={{ zIndex: 1100 }}
                      size="large"
                      options={["Yes", "No"].map((e) => {
                        return {
                          label: e,
                          value: e === "Yes" ? true : false,
                        };
                      })}
                      disabled={isDuplicate}
                    ></Select>
                  </div>

                  <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2">
                      Pre Sales - Guidebook Link
                    </label>
                    <input
                      type="text"
                      className="form-control fs-7"
                      name="preSalesGuidebook"
                      value={formData.preSalesGuidebook}
                      onChange={handleInputChange}
                      disabled={isDuplicate}
                      placeholder="Enter Your Guidebook Link"
                    />
                  </div>

                  <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2">
                      Cost of Single Room Addon
                    </label>
                    <input
                      type="number"
                      className="form-control fs-7"
                      name="singleRoomAddonCost"
                      value={formData.singleRoomAddonCost}
                      onChange={handleInputChange}
                      // disabled={isDuplicate}
                      placeholder="Enter Cost of Single Room Addon"
                    />
                  </div>

                  <div className="col-md-6 fv-row">
                    <label className="fs-6 fw-bold mb-2">
                      Region (important for tax calc + visa)
                    </label>

                    <Select
                      showSearch
                      optionFilterProp="label"
                      name="region"
                      value={formData.region}
                      onChange={(value) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          region: value,
                        }));
                      }}
                      placeholder="Choose Region"
                      className="fs-7 w-100"
                      style={{ width: "100%" }}
                      dropdownStyle={{ zIndex: 1100 }}
                      size="large"
                      options={regions}
                      disabled={isDuplicate}
                      loading={regionLoading}
                    ></Select>
                  </div>

                  {(id || editionId) && (
                    <div className="col-md-6 fv-row ">
                      <label className="fs-6 fw-bold mb-2">
                        Edition Status
                      </label>
                      <Select
                        showSearch
                        optionFilterProp="label"
                        name="edition_status"
                        value={formData.edition_status}
                        onChange={(value) => {
                          setFormData((prevState) => ({
                            ...prevState,
                            edition_status: value,
                          }));
                        }}
                        placeholder="Choose Edition Status"
                        className="fs-7 w-100"
                        style={{ width: "100%" }}
                        dropdownStyle={{ zIndex: 1100 }}
                        size="large"
                        options={editionStatusList}
                        disabled={isDuplicate}
                        loading={editionStatusLoading}
                      ></Select>
                    </div>
                  )}

                  <div className="col-md-6 fv-row ">
                    <label className="fs-6 fw-bold mb-2">
                      Select Lead Facilitator
                    </label>
                    <Select
                      mode="multiple"
                      showSearch
                      optionFilterProp="label"
                      name="users"
                      value={formData.users}
                      onChange={(value) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          users: value,
                        }));
                      }}
                      placeholder="Select Users"
                      className="fs-7 w-100"
                      style={{ width: "100%" }}
                      dropdownStyle={{ zIndex: 1100 }}
                      size="large"
                      options={userList}
                      // disabled={isDuplicate}
                      loading={userListLoading}
                    ></Select>
                  </div>

                  <div className="col-md-9 fv-row">
                    <label className="fs-6 fw-bold mb-2">
                      How much % advance do we need as payment 1?
                    </label>
                    <input
                      type="number"
                      className="form-control fs-7"
                      name="advancePayment"
                      value={formData.advancePayment}
                      onChange={handleInputChange}
                      placeholder="Enter Advance Payment %"
                    />
                  </div>

                  <div className="col-md-3 fv-row">
                    <label className="fs-6 fw-bold mb-2">Star Edition</label>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        id="starEdition"
                        name="starEdition"
                        checked={formData.starEdition}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          setFormData({
                            ...formData,
                            starEdition: isChecked ? true : false,
                          });
                        }}
                        className="form-check-input"
                        disabled={isDuplicate}
                      />
                      <label
                        htmlFor="starEdition"
                        className="form-check-label fs-7"
                      >
                        Yes
                      </label>
                    </div>
                  </div>

                  <div className="col-md-12 fv-row">
                    <button
                      className="btn btn-primary fs-7 fw-bold w-100 mt-5"
                      onClick={() => {
                        setShowHotelModal(true);
                      }}
                    >
                      {id || editionId ? (
                        <>View Hotel Details</>
                      ) : (
                        <>
                          <i className="ki-outline ki-plus-square fs-2" /> Add
                          Add Hotel Details
                        </>
                      )}
                    </button>
                  </div>

                  <div className="col-md-12 fv-row">
                    <button
                      className="btn btn-primary fs-7 fw-bold w-100 mt-5"
                      onClick={() => {
                        setShowAirportModal(true);
                      }}
                    >
                      {id || editionId ? (
                        <>View Airport Details</>
                      ) : (
                        <>
                          <i className="ki-outline ki-plus-square fs-2" /> Add
                          Add Airport Details
                        </>
                      )}
                    </button>
                  </div>

                  <div className="col-md-12 fv-row">
                    <button
                      className="btn btn-primary fs-7 fw-bold w-100 mt-5"
                      onClick={() => {
                        setShowLinkModal(true);
                      }}
                    >
                      {id || editionId ? (
                        <>View Quick Link Details</>
                      ) : (
                        <>
                          <i className="ki-outline ki-plus-square fs-2" />
                          Add Quick Link Details
                        </>
                      )}
                    </button>
                  </div>

                  <div className="col-md-12 fv-row">
                    <button
                      className="btn btn-primary fs-7 fw-bold w-100 mt-5"
                      onClick={() => {
                        setHighLightModal(true);
                      }}
                    >
                      {id || editionId ? (
                        <>View Highlights Details</>
                      ) : (
                        <>
                          <i className="ki-outline ki-plus-square fs-2" />
                          Add HighLights Details
                        </>
                      )}
                    </button>
                  </div>

                  <div className="col-md-12 fv-row">
                    <button
                      className="btn btn-primary fs-7 fw-bold w-100 mt-5"
                      onClick={() => {
                        setStoryHighLightModal(true);
                      }}
                    >
                      {id || editionId ? (
                        <>View Story Highlights Details</>
                      ) : (
                        <>
                          <i className="ki-outline ki-plus-square fs-2" />
                          Add Story HighLights Details
                        </>
                      )}
                    </button>
                  </div>

                  <div className="col-md-12 fv-row">
                    <button
                      className="btn btn-primary fs-7 fw-bold w-100 mt-5"
                      onClick={() => {
                        setContentModal(true);
                      }}
                    >
                      {id || editionId ? (
                        <>What's Include Content</>
                      ) : (
                        <>
                          <i className="ki-outline ki-plus-square fs-2" />
                          What's Include Content
                        </>
                      )}
                    </button>
                  </div>

                  <div className="separator separator mt-7 separator-dashed border-gray-300" />

                  {/* HightLights Section */}
                  {/* <div className="col-md-12 fv-row">
                    <label className="fs-3 fw-bold mb-2">Highlights</label>
                  </div> */}

                  {/* <div
                    className="col-md-12 fv-row"
                    style={{ pointerEvents: isDuplicate ? "none" : "auto" }}
                  >
                    <label className="fs-6 fw-bold mb-2">Images</label>

                    <Dropzone
                      pictures={highLightMediaImages}
                      setPictures={setHighlightMediaImages}
                      removedImages={removeHighlightMediaImages}
                      setRemovedImages={setRemoveHighlightMediaImages}
                      shouldUpload={true}
                      setUploadedUrls={setHighlightMediaUrls}
                      setLoading={setUploadLoading}
                    />

                    {highlightImagePreview ? (
                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                          marginLeft: "10px",
                        }}
                      >
                        <img
                          src={highlightImagePreview}
                          alt="Hero Preview"
                          style={{
                            width: "150px",
                            height: "120px",
                            objectFit: "cover",
                            borderRadius: "8px",
                            border: "2px solid #ccc",
                          }}
                        />
                        <i
                          title="Remove Image"
                          className="ki-outline ki-cross fs-2"
                          onClick={() => handleRemoveImage("highlightImage")}
                          style={{
                            position: "absolute",
                            top: "-8px",
                            right: "-8px",
                            cursor: "pointer",
                            zIndex: 2,
                            backgroundColor: "white",
                            borderRadius: "50%",
                          }}
                        />
                      </div>
                    ) : (
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        className="form-control fs-7"
                        name="highlightImage"
                        onChange={handleFileChange}
                      />
                    )}
                  </div> */}

                  <div className="col-md-12 fv-row">
                    <label className="fs-6 fw-bold mb-2">About trip</label>
                    <textarea
                      className="form-control fs-7"
                      name="aboutTrip"
                      rows={4}
                      value={formData.aboutTrip}
                      onChange={handleInputChange}
                      disabled={isDuplicate}
                      placeholder="Enter Highlights of the Edition"
                    ></textarea>
                  </div>
                  {/* Bluprint Section */}
                  <div className="col-md-12 fv-row">
                    <label className="fs-3 fw-bold mb-2">Blue Print</label>
                  </div>

                  <div className="col-md-12 fv-row mb-4">
                    <label className="fs-6 fw-bold mb-2">Url</label>
                    <textarea
                      className="form-control fs-7"
                      name="blueprint"
                      rows={2}
                      value={formData.blueprint}
                      onChange={handleInputChange}
                      disabled={isDuplicate}
                      placeholder="Enter blueprint Url "
                    ></textarea>
                  </div>

                  <div className="col-md-4 fv-row">
                    <button
                      disabled={uploadLoading}
                      className="btn btn-primary fs-7 fw-bold"
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      <i className="ki-outline ki-plus-square fs-2" />
                      {isDuplicate
                        ? "Duplicate Edition"
                        : id
                        ? "Update Edition"
                        : "Create Edition"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/*begin::Body*/}
          </div>
          {/*end::Tables Widget 9*/}
        </div>

        <div
          className="col-xxl-6"
          style={
            loading
              ? {
                  filter: "blur(5px)",
                  pointerEvents: "none",
                }
              : {}
          }
        >
          <div className="card card-xxl-stretch mb-5 mb-xl-8">
            {/* Tabs Header */}
            <div className="card-header">
              <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold my-4">
                {tabs.map((tab) => (
                  <li className="nav-item" key={tab.id}>
                    <button
                      className={`nav-link text-gray-800 ${
                        activeTab === tab.id ? "active" : ""
                      }`}
                      onClick={() => setActiveTab(tab.id)}
                    >
                      {tab.name}
                    </button>
                  </li>
                ))}
              </ul>
            </div>

            {/* Tabs Body */}
            <div className="card-body ">
              {activeTab === 1 && (
                <>
                  <div className="d-flex justify-content-between align-items-center mb-5">
                    <h3 className="card-title fw-bold mb-0 fs-3 d-flex align-items-center">
                      Itinerary
                    </h3>
                    <div className="card-toolbar">
                      <button
                        className="btn "
                        title="Import Itinerary"
                        onClick={() => {
                          setSelectImportTab(1);
                          setImportCsvModal(!importCsvModal);
                        }}
                        disabled={!editionId && !id}
                      >
                        <i className="ki-outline ki-exit-up text-dark fs-1" />
                      </button>
                      <Tooltip
                        title={`${
                          editionId || id
                            ? ""
                            : "First Add Editions To Add Itinerary"
                        }`}
                      >
                        <span>
                          {/* Wrap button with a span to allow tooltip on disabled state */}
                          <button
                            className="btn btn-primary fs-7 fw-bold "
                            onClick={() => setItineraryModal(!itineraryModal)}
                            disabled={!editionId && !id}
                          >
                            <i className="ki-outline ki-plus-square fs-2" /> Add
                            Itinerary
                          </button>
                        </span>
                      </Tooltip>
                    </div>
                  </div>

                  <Itinerary
                    cardData={itinerarydata}
                    onEdit={handleEdit}
                    onDelete={onDelete}
                  />
                </>
              )}

              {activeTab === 2 && (
                <>
                  <div className="d-flex justify-content-between align-items-center mb-5">
                    {/* Replace with your Game Plan content */}
                    <h3 className="card-title fw-bold mb-0 fs-3 d-flex align-items-center">
                      Game Plan
                    </h3>
                    <div className="card-toolbar">
                      <button
                        className="btn "
                        title="Import Game Plan"
                        onClick={() => {
                          setSelectImportTab(2);
                          setImportCsvModal(!importCsvModal);
                        }}
                        disabled={!editionId && !id}
                      >
                        <i className="ki-outline ki-exit-up text-dark fs-1" />
                      </button>
                      <Tooltip
                        title={`${
                          editionId || id
                            ? ""
                            : "First Add Editions To Add Game Plan"
                        }`}
                      >
                        <span>
                          <button
                            className="btn btn-primary fs-7 fw-bold"
                            onClick={() =>
                              setGamePlanDayModal(!gamePlanDayModal)
                            }
                            disabled={!editionId && !id}
                          >
                            <i className="ki-outline ki-plus-square fs-2" /> Add
                            Days
                          </button>
                        </span>
                      </Tooltip>
                    </div>
                  </div>

                  <GamePlan
                    gamePlanLoading={gamePlanLoading}
                    gamePlanData={gamePlanData}
                    setGamePlanLoading={setGamePlanLoading}
                    getGamePlanData={getGamePlanData}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <ImportCsvModal
        show={importCsvModal}
        onHide={() => {
          setModalItineraryData({});
          setImportCsvModal(false);
        }}
        onSuccess={() => {
          if (selectImportTab === 1) {
            setLoading(true);
            getIitineraryData();
          } else {
            setGamePlanLoading(true);
            getGamePlanData();
          }
        }}
        data={{
          edition_id: id || editionId,
        }}
        selectImportTab={selectImportTab}
      />

      <ItineraryModal
        show={itineraryModal}
        destinationList={destinationList}
        hotelList={hotelList}
        onHide={() => {
          setModalItineraryData({});
          setItineraryModal(false);
          setMakeModalFreezed(false);
        }}
        onSuccess={() => {
          if (id || editionId) {
            setLoading(true);
            getIitineraryData();
          }
        }}
        data={{
          edition_id: id || editionId,
          itinerary_id: modalItinerarydata?.id || null,
          modalFreezed: makeModalFreezed,
          hotel: selectedHotelList || [],
        }}
      />

      <GamePlanDayModal
        show={gamePlanDayModal}
        onHide={() => {
          setGamePlanDayModal(false);
          getGamePlanData();
        }}
        data={{
          edition_id: id || editionId,
          startDate: formData?.startDate || null,
          endDate: formData?.endDate || null,
        }}
        disabledDate={disabledDate}
      />

      <AirportDetails
        show={showAirportModal}
        airportList={airportList}
        setFilterData={setFilterData}
        setActivePage={setAirportActivePage}
        airportModalDetails={airportModalDetails}
        setAirportModalDetails={setAirportModalDetails}
        airportModalValid={airportModalValid}
        setAirportModalValid={setAirportModalValid}
        airportLoading={airportLoading}
        setAirportLoading={setAirportLoading}
        onHide={() => {
          setShowAirportModal(false);
        }}
        data={{
          edition_id: id || editionId,
          itinerary_id: modalItinerarydata?.id || null,
          modalFreezed: makeModalFreezed,
        }}
      />

      <HotelDetail
        show={showHotelModal}
        setFilterData={setFilterData}
        hotelModalDetails={hotelModalDetails}
        setHotelModalDetails={setHotelModalDetails}
        onHide={() => {
          setShowHotelModal(false);
        }}
        data={{
          edition_id: id || editionId,
          itinerary_id: modalItinerarydata?.id || null,
          modalFreezed: makeModalFreezed,
        }}
        hotelList={hotelList}
        hotelLoading={hotelLoading}
        formData={formData}
        setFormData={setFormData}
        parseISOToUTCDate={parseISOToUTCDate}
        disabledDate={disabledDate}
        setHotelLoading={setHotelLoading}
        setHotelActivePage={setHotelActivePage}
      />

      <LinkDetails
        show={showLinkModal}
        linkModalDetails={linkModalDetails}
        setLinkModalDetails={setLinkModalDetails}
        onHide={() => {
          setShowLinkModal(false);
        }}
        data={{
          edition_id: id || editionId,
          itinerary_id: modalItinerarydata?.id || null,
          modalFreezed: makeModalFreezed,
        }}
        handleQuickLinkFileChange={handleQuickLinkFileChange}
        handleQuickLinkFileRemove={handleQuickLinkFileRemove}
        quickLinkImagePreview={quickLinkImagePreview}
        setQuickLinkImagePreview={setQuickLinkImagePreview}
      />

      <HighlightsDetails
        show={highLightModal}
        highlightsModalDetails={highlightsModalDetails}
        setHighlightsModalDetails={setHighlightsModalDetails}
        onHide={() => {
          setHighLightModal(false);
        }}
        data={{
          edition_id: id || editionId,
          itinerary_id: modalItinerarydata?.id || null,
          modalFreezed: makeModalFreezed,
        }}
        hightLightModalImagePreview={hightLightModalImagePreview}
        setHighLightModalImagePreview={setHighLightModalImagePreview}
        handleHighLightModalFileChange={handleHighLightModalFileChange}
        handleHighlightFileRemove={handleHighlightFileRemove}
      />

      <StoryHighlights
        show={storyHighLightModal}
        storyHighlightsModalDetails={storyHighlightsModalDetails}
        setStoryHighlightsModalDetails={setStoryHighlightsModalDetails}
        onHide={() => {
          setStoryHighLightModal(false);
        }}
        data={{
          edition_id: id || editionId,
          itinerary_id: modalItinerarydata?.id || null,
          modalFreezed: makeModalFreezed,
        }}
        storyHighLightModalImagePreview={storyHighLightModalImagePreview}
        setStoryHighLightModalImagePreview={setStoryHighLightModalImagePreview}
        handleStoryHighLightModalFileChange={
          handleStoryHighLightModalFileChange
        }
        handleStoryHighlightFileRemove={handleStoryHighlightFileRemove}
      />

      <ContentModal
        show={contentModal}
        onHide={() => setContentModal(false)}
        contentModalDetails={contentModalDetails}
        setContentModalDetails={setContentModalDetails}
      />
    </div>
  );
}
