import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import EditionSuccess from "./RedEditionsTabs/edition_success";
import GuestList from "./RedEditionsTabs/guestList";
import InventoryManager from "./RedEditionsTabs/inventoryManager";
import SecondPayments from "./RedEditionsTabs/secondPayments";
import { formatNumber } from "../../../../config";

export default function RedEdition({ show, onHide, data }) {
  const [active, setActive] = useState(1);
  const [currentCount, setCurrentCount] = useState(0);
  const [avgPrice, setAvgPrice] = useState(0);

  const tabs = [
    { id: 1, label: "Edition Success" },
    { id: 2, label: "Guestlist" },
    { id: 3, label: "Inventory Manager" },
    { id: 4, label: "Second Payments (0)" },
  ];

  return (
    <Modal centered show={show} onHide={onHide} size="xl">
      <Modal.Header>
        <h3 className="modal-title">
          Edition in red <span className="text-gray-400 fw-semibold">|</span>{" "}
          <small className="fs-6">Current Count: </small>
          <span className="badge badge-circle badge-outline badge-primary">
            {currentCount}
          </span>
        </h3>
        {/*begin::Close*/}
        <div>
          {/*begin::Destination*/}
          <div className="d-flex align-items-center fw-bold ">
            {/*begin::Label*/}
            <div className="text-gray-600 fs-6 me-2 fw-semibold min-w-90px ">
              Average Price:{" "}
              <span className="text-gray-900 fw-bold">
                {formatNumber(avgPrice)}
              </span>
            </div>
            {/*end::Label*/}
            <div
              className="btn btn-icon btn-sm btn-active-light-primary ms-2 w-70px"
              onClick={onHide}
            >
              <i className="ki-outline ki-cross fs-1" />
            </div>
          </div>
          {/*end::Destination*/}
        </div>
      </Modal.Header>
      <div className="modal-body pb-7 pt-3 ps-lg-7 pe-0">
        <div
          className="scroll-y pe-7 ps-3"
          id="kt_modal_add_customer_scroll"
          style={{
            height: "400px",
          }}
        >
          <div className="row g-5 mb-0 align-items-center">
            <div className="col-md-12">
              <ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6">
                {tabs.map((tab) => (
                  <li className="nav-item" key={tab.id}>
                    <span
                      className={`nav-link fs-5 px-3 text-gray-800 text-active-primary cursor-pointer ${
                        active === tab.id && "active"
                      }`}
                      onClick={() => setActive(tab.id)}
                    >
                      {tab.label}
                    </span>
                  </li>
                ))}
              </ul>

              <div className="tab-content">
                {active === 1 && (
                  <EditionSuccess
                    setCurrentCount={setCurrentCount}
                    setAvgPrice={setAvgPrice}
                    editionData={data}
                  />
                )}

                {active === 2 && (
                  <GuestList
                    setCurrentCount={setCurrentCount}
                    setAvgPrice={setAvgPrice}
                    editionData={data}
                  />
                )}

                {active === 3 && (
                  <InventoryManager
                    setCurrentCount={setCurrentCount}
                    setAvgPrice={setAvgPrice}
                    editionData={data}
                  />
                )}

                {active === 4 && (
                  <SecondPayments
                    setCurrentCount={setCurrentCount}
                    setAvgPrice={setAvgPrice}
                    editionData={data}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-light fs-7" onClick={onHide}>
          Close
        </button>
      </div>
    </Modal>
  );
}
