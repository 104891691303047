import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import { fetchData } from "../../../config/service";
import { DatePicker, Select } from "antd";
import dayjs from "dayjs";
import { parseISOToUTCDate } from "../../../config";
import { validateFields } from "../../../components/validation";

const revenueOptions = [
  {
    value: 1,
    label: "0 to 5L",
  },
  {
    value: 2,
    label: "5 to 10L",
  },
  {
    value: 3,
    label: "10 to 15L",
  },
  {
    value: 4,
    label: "15 to 25L",
  },
  {
    value: 5,
    label: "25 to 50L",
  },
  {
    value: 6,
    label: "50L to 1C",
  },
  {
    value: 7,
    label: "1 to 3C",
  },
  {
    value: 8,
    label: "3 to 6C",
  },
  {
    value: 9,
    label: "6 to 10C",
  },
  {
    value: 10,
    label: "10 to 20C",
  },
  {
    value: 11,
    label: "20C +",
  },
];

export default function VisaFeeModal({ show, onHide, data }) {
  const [sending, setSending] = useState(false);
  const [userId, setUserId] = useState("");
  const [visaInformation, setVisaInformation] = useState({
    preferred_city: null,
    preferred_appointment_date: "",
    visa_start: "",
    visa_end: "",
    destination: "",
    employment_status: null,
    marital_status: null,
    annual_income: null,
    visa_service: "",
  });
  const [cityLoading, setCityLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const [visaLoading, setVisaLoading] = useState(false);
  const [visaChoices, setVisaChoices] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setVisaInformation((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    getCities();
    getVisaChoices();
  }, []);

  useEffect(() => {
    if (data) {
      setVisaInformation({
        visa_start: parseISOToUTCDate(
          data?.editionData?.edition_details?.start_date
        ),
        visa_end: parseISOToUTCDate(
          data?.editionData?.edition_details?.end_date
        ),
      });
      setUserId(data?.uuid);
    }
  }, [data]);

  const getCities = () => {
    setCityLoading(true);
    fetchData(
      `api/country/state/city/?is_active=1&limit=${10000}&offset=${0}&phone_code=91`
    ).then((res) => {
      setCityLoading(false);
      if (res.success) {
        setCities(res.data);
      } else {
        setCities([]);
        toast.error(res.message);
      }
    });
  };

  const getVisaChoices = () => {
    setVisaLoading(true);
    fetchData(`customer/api/visa/visa-choices/`, "get").then((res) => {
      setVisaLoading(false);
      if (res.success) {
        setVisaChoices(res);
      } else {
        setVisaChoices({});
        toast.error(res.message);
      }
    });
  };

  const rules = {
    preferred_city: {
      required: true,
    },
    preferred_appointment_date: {
      required: true,
    },
    visa_start: {
      required: true,
    },
    visa_end: {
      required: true,
    },
    destination: {
      required: true,
    },
    employment_status: {
      required: false,
    },
    marital_status: {
      required: false,
    },
    annual_income: {
      required: false,
    },
    visa_service: {
      required: true,
    },
  };

  const onSubmit = async () => {
    const { isValid, errors } = validateFields(visaInformation, rules);

    if (!isValid) {
      return;
    } else {
      toast.loading("Adding Visa Information ....");
    }

    setSending(true);

    const body = {
      edition: data?.edition_id,
      user: data?.user_id,
      ...visaInformation,
    };

    // console.log(body, "body");

    fetchData(
      `customer/api/visa/visa-information-create-list/`,
      "post",
      body
    ).then((res) => {
      toast.remove();
      setSending(false);
      if (res.success) {
        onHide();
        toast.success("Success");
      } else {
        toast.error(res.message);
      }
    });
  };

  const disabledDate = (
    current,
    dateType,
    referenceDate,
    startLimit,
    endLimit
  ) => {
    const today = dayjs().startOf("day");

    // Convert limits to dayjs objects if they exist
    const startLimitDate = startLimit ? dayjs(startLimit).startOf("day") : null;
    const endLimitDate = endLimit ? dayjs(endLimit).endOf("day") : null;

    if (dateType === "start") {
      // For start date:
      // - disable dates before today
      // - disable dates before startLimit if provided
      // - disable dates after endLimit if provided
      return (
        current &&
        (current < today ||
          (startLimitDate && current < startLimitDate) ||
          (endLimitDate && current > endLimitDate))
      );
    } else if (dateType === "end") {
      // For end date:
      // - disable dates before today
      // - disable dates before the selected start date (referenceDate)
      // - disable dates before startLimit if provided
      // - disable dates after endLimit if provided
      return (
        current &&
        (current < today ||
          (referenceDate && current < dayjs(referenceDate).startOf("day")) ||
          (startLimitDate && current < startLimitDate) ||
          (endLimitDate && current > endLimitDate))
      );
    }
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="fw-bold fs-6 mb-n3">
            Change Visa Information <span className="text-primary"></span>
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="row g-3">
            {/* Appointment Preferences */}
            <div className="col-md-6">
              <label className="form-label">
                Preferred city for visa appointment
              </label>
              <Select
                showSearch
                optionFilterProp="label"
                size="large"
                value={visaInformation?.preferred_city}
                onChange={(value) =>
                  setVisaInformation({
                    ...visaInformation,
                    preferred_city: value,
                  })
                }
                placeholder="Select Preferred City"
                options={cities?.map((city) => ({
                  value: city.id,
                  label: city.name,
                }))}
                style={{ width: "100%" }}
                dropdownStyle={{ zIndex: 1111 }}
              />
            </div>
            <div className="col-md-6">
              <label className="form-label">Preferred appointment date</label>
              <DatePicker
                format="DD-MM-YYYY"
                value={
                  visaInformation?.preferred_appointment_date
                    ? parseISOToUTCDate(
                        visaInformation?.preferred_appointment_date
                      )
                    : null
                }
                placeholder="Pick a Visa Appointment Date"
                onChange={(date, dateString) => {
                  // Convert date to ISO format in UTC if dateString is valid
                  const utcDateString = date ? date.format("YYYY-MM-DD") : null;

                  setVisaInformation({
                    ...visaInformation,
                    preferred_appointment_date: utcDateString,
                  });
                }}
                disabledDate={(current) => disabledDate(current, "start")}
                className="fs-7 w-100"
                size="large"
              />
            </div>
            <div className="col-md-6">
              <label className="form-label">Visa starts from</label>
              <DatePicker
                format="DD-MM-YYYY"
                value={
                  visaInformation.visa_start
                    ? parseISOToUTCDate(visaInformation.visa_start)
                    : null
                }
                placeholder="Pick a Visa Start Date"
                onChange={(date, dateString) => {
                  // Convert date to ISO format in UTC if dateString is valid
                  const utcDateString = date ? date.format("YYYY-MM-DD") : null;

                  setVisaInformation({
                    ...visaInformation,
                    visa_start: utcDateString,
                  });
                }}
                className="fs-7 w-100"
                disabledDate={(current) => disabledDate(current, "start")}
                size="large"
              />
            </div>
            <div className="col-md-6">
              <label className="form-label">Visa end date</label>

              <DatePicker
                format="DD-MM-YYYY"
                value={
                  visaInformation.visa_end
                    ? parseISOToUTCDate(visaInformation.visa_end)
                    : null
                }
                placeholder="Pick a Visa End Date"
                onChange={(date, dateString) => {
                  // Convert date to ISO format in UTC if dateString is valid
                  const utcDateString = date ? date.format("YYYY-MM-DD") : null;

                  setVisaInformation({
                    ...visaInformation,
                    visa_end: utcDateString,
                  });
                }}
                disabledDate={(current) =>
                  disabledDate(current, "end", visaInformation.visa_start)
                }
                className="fs-7 w-100"
                size="large"
              />
            </div>
            <div className="col-md-12">
              <label className="form-label">Main destination</label>
              <input
                type="text"
                className="form-control"
                name="destination"
                value={visaInformation.destination}
                placeholder="Enter your main destination"
                onChange={handleChange}
              />
            </div>

            {/* Personal Information */}
            <div className="col-md-4">
              <label className="form-label">Employment status</label>
              <Select
                size="large"
                value={visaInformation.employment_status}
                placeholder="Select your employment status"
                onChange={(value) =>
                  setVisaInformation({
                    ...visaInformation,
                    employment_status: value,
                  })
                }
                options={visaChoices["employment_status_choices"]?.map(
                  (item) => ({
                    value: item.value,
                    label: item.display_name,
                  })
                )}
                style={{ width: "100%" }}
                dropdownStyle={{ zIndex: 1111 }}
              />
            </div>
            <div className="col-md-4">
              <label className="form-label">Marital status</label>
              <Select
                size="large"
                value={visaInformation.marital_status}
                placeholder="Select your marital status"
                onChange={(value) =>
                  setVisaInformation({
                    ...visaInformation,
                    marital_status: value,
                  })
                }
                options={visaChoices["marital_status_choices"]?.map((item) => ({
                  value: item.value,
                  label: item.display_name,
                }))}
                style={{ width: "100%" }}
                dropdownStyle={{ zIndex: 1111 }}
              />
            </div>
            <div className="col-md-4">
              <label className="form-label">Annual income</label>
              <Select
                size="large"
                value={visaInformation.annual_income}
                placeholder="Select your annual income"
                onChange={(value) =>
                  setVisaInformation({
                    ...visaInformation,
                    annual_income: value,
                  })
                }
                options={revenueOptions}
                style={{ width: "100%" }}
                dropdownStyle={{ zIndex: 1111 }}
              />
            </div>

            {/* Service Type */}
            <div className="col-md-12">
              <label className="form-label">Service Type</label>
              <div className="row ms-2 mt-3">
                <div className="form-check col-md-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="visa_service"
                    value="1"
                    checked={visaInformation.visa_service === "1"}
                    onChange={handleChange}
                  />
                  <label className="form-check-label text-gray-700">
                    Full board
                  </label>
                </div>
                <div className="form-check col-md-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="visa_service"
                    value="2"
                    checked={visaInformation.visa_service === "2"}
                    onChange={handleChange}
                  />
                  <label className="form-check-label text-gray-700">
                    Half board
                  </label>
                </div>
                <div className="form-check col-md-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="visa_service"
                    value="3"
                    checked={visaInformation.visa_service === "3"}
                    onChange={handleChange}
                  />
                  <label className="form-check-label text-gray-700">
                    Document Review
                  </label>
                </div>
                <div className="form-check col-md-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="visa_service"
                    value="4"
                    checked={visaInformation.visa_service === "4"}
                    onChange={handleChange}
                  />
                  <label className="form-check-label text-gray-700">
                    Basic
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-light-danger fs-7"
          onClick={onHide}
        >
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary fs-7"
          onClick={() => {
            onSubmit(visaInformation);
          }}
          disabled={sending}
        >
          Submit
          {sending && (
            <span className="spinner-border spinner-border-sm align-middle ms-2" />
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
