import React, { useEffect, useState } from "react";
import { Drawer, Button, Select, Checkbox } from "antd";
import { NotesModal } from "../../../customers/modals";
import { fetchData } from "../../../../config/service";
import moment from "moment";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import { Loader } from "../../../../components";

const NotesDrawer = ({
  show,
  data,
  onHide,
  notesOptions,
  onboardingFlag,
  visaRelatedFlag,
}) => {
  const [isOpenNotesModal, setIsOpenNotesModal] = useState(false);
  const [notesLoading, setNotesLoading] = useState(false);
  const [notes, setNotes] = useState([]);
  const [filteredNotes, setFilteredNotes] = useState({ note_type: null });
  const [selectedNotesData, setSelectedNotesData] = useState(null);

  const notesData = [
    {
      dateAdded: "78.9 Days Back",
      userName: "Koyel Tapadar",
      avatar: "assets/media/avatars/blank.png",
      category: "Visa Related",
      notes: [
        "S1: Appointment Pending -- 23/1: Not Sure; Kean will update",
        "16/1: Going for Walk-in appointment this Friday",
      ],
    },
    {
      dateAdded: "45 Days Back",
      userName: "John Doe",
      avatar: "assets/media/avatars/blank.png",
      category: "Passport Related",
      notes: [
        "S2: Documentation missing -- 12/2: Pending approval",
        "20/2: Submitted all documents, waiting for confirmation",
      ],
    },
    // Add more note objects as needed
  ];

  useEffect(() => {
    if (show) {
      getNotes();
    }
  }, [show, filteredNotes]);

  const getNotes = () => {
    setNotesLoading(true);
    fetchData(
      `api/note/?user=${data?.user?.uuid}&note_type=${
        visaRelatedFlag
          ? 1
          : onboardingFlag
          ? 5
          : filteredNotes?.note_type || ""
      }`,
      "get"
    ).then((res) => {
      setNotesLoading(false);
      if (res.success) {
        console.log(res, "res");
        // const transformedData = res?.data?.map((item) => {
        //   return {
        //     date: dayjs.utc(item.created_at).format("MMM D, YYYY"),
        //     title: noteTypeMapping[item.note_type] || "Other Note",
        //     content: [`${item.note}`],
        //   };
        // });

        setNotes(res?.data);
      } else {
        toast.error(res.message);
      }
    });
  };

  const noteTypeValue = (noteType) => {
    const item = notesOptions?.find((option) => option?.value === noteType);
    return item?.display_name;
  };

  console.log(notesOptions, "notesOptions");
  return (
    <>
      <Drawer
        title={`Notes For ${data?.user?.first_name || ""} ${
          data?.user?.last_name || ""
        }`}
        placement="right"
        onClose={onHide}
        visible={show}
        width={450}
        footer={
          <>
            {!onboardingFlag && (
              <>
                <div className="p-3">
                  {" "}
                  <button
                    className="btn btn-primary fs-7"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_add_note"
                    onClick={() => setIsOpenNotesModal(!isOpenNotesModal)}
                  >
                    Add Notes <i className="ki-outline ki-plus fs-3" />
                  </button>
                </div>
              </>
            )}
          </>
        }
      >
        <div className="row g-5 mb-0 gx-md-10">
          <div className="col-md-12 ">
            <Select
              options={notesOptions?.map((role) => {
                return {
                  label: role?.display_name,
                  value: role?.value,
                };
              })}
              value={
                visaRelatedFlag ? 1 : onboardingFlag ? 5 : notesData?.note_type
              }
              onChange={(e) => {
                setFilteredNotes((prevData) => ({ ...prevData, note_type: e }));
              }}
              placeholder="Select Notes Subject"
              dropdownStyle={{ zIndex: 1111 }}
              style={{ width: "100%", marginTop: "10px" }}
              size="large"
              allowClear
              onClear={() =>
                setFilteredNotes((prevData) => ({
                  ...prevData,
                  note_type: null,
                }))
              }
              disabled={onboardingFlag}
            />
          </div>

          <div className="col-md-12 mt-5 ">
            <div
              className="overlay-layer  bg-light bg-opacity-25  "
              style={{ Opacity: 1, zIndex: 9 }}
            >
              {notesLoading ? (
                <Loader cols={1} />
              ) : (
                <>
                  {notes.length > 0 ? (
                    <>
                      {notes.map((item, index) => (
                        <div key={index}>
                          <div className="bg-light p-4 rounded mb-5 position-relative">
                            <button
                              className="btn btn-outline-primary btn-sm position-absolute top-0 end-0 m-3"
                              onClick={() => {
                                setIsOpenNotesModal(!isOpenNotesModal);
                                setSelectedNotesData(item);
                              }}
                            >
                              Edit
                            </button>
                            <div className="fs-7 text-primary fw-bold">
                              Note Added -{" "}
                              {dayjs.utc(item?.created_at).format("DD-MM-YYYY")}
                            </div>
                            <div className="d-flex align-items-center mt-2 mb-3">
                              <div className="symbol symbol-circle symbol-30px overflow-hidden me-3">
                                <img src={item?.user?.photo} alt="" />
                              </div>
                              <div className="d-flex flex-column">
                                <a
                                  href="#"
                                  className="text-gray-800 text-hover-primary mb-0 fw-bolder fs-6"
                                >
                                  {item?.user?.first_name +
                                    " " +
                                    item?.user?.last_name}
                                </a>
                              </div>
                            </div>
                            <h3 className="mb-4 mt-2 fs-4">
                              {noteTypeValue(item?.note_type || 0)}
                            </h3>
                            <div>{item?.note}</div>
                          </div>
                          <div className="separator separator-dashed my-5 border-gray-300" />
                        </div>
                      ))}
                    </>
                  ) : (
                    <div
                      className="row g-5 mb-0 gx-md-10 "
                      style={{ minHeight: "600px" }}
                    >
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <div className=" col-md-12 text-center">
                          <div className="mt-0">
                            <img
                              src="/media/no-data-found.png"
                              className="mw-25"
                              alt="No Data Found"
                            />
                          </div>
                          <h1 className="mt-4">No Data Found</h1>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </Drawer>

      {isOpenNotesModal && (
        <NotesModal
          show={isOpenNotesModal}
          notesOptions={notesOptions}
          onHide={() => {
            setIsOpenNotesModal(false);
            setNotesLoading(true);
            getNotes();
          }}
          data={{
            uuid: data?.user?.uuid,
          }}
          selectedNotesData={selectedNotesData}
        />
      )}
    </>
  );
};

export default NotesDrawer;
