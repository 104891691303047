import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import { DatePicker, Select, TimePicker } from "antd";
import { fetchData } from "../config/service";

export default function EmailTemplateModal({ show, onHide, data }) {
  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messageData, setMessageData] = useState({
    template: null,
    message: "",
    scheduleDate: null,
    scheduleTime: null,
  });
  const [templateList, setTemplateList] = useState([]);
  const [showScheduler, setShowScheduler] = useState(false);

  const setMessageDataValue = (field, value) => {
    setMessageData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  //   useEffect(() => {
  //     if (data) {

  //     }
  //   }, [data]);

  useEffect(() => {
    getEmailTemplate();
  }, []);

  useEffect(() => {
    if (messageData?.template) {
      getSingleEmailTemplate();
    }
  }, [messageData?.template]);

  const getEmailTemplate = () => {
    setLoading(true);
    fetchData(`api/dynamic-templates/?template_type=2&limit=1000`, "get").then(
      (res) => {
        setLoading(false);
        if (res) {
          const data = res?.data?.map((item) => {
            return {
              value: item?.id,
              label: item?.template_name,
            };
          });

          setTemplateList(data);
        } else {
          toast.error(res.message);
        }
      }
    );
  };

  const getSingleEmailTemplate = () => {
    setLoading(true);
    fetchData(
      `api/dynamic-templates/?template_type=2&id=${
        messageData?.template || ""
      }`,
      "get"
    ).then((res) => {
      setLoading(false);
      if (res) {
        const msg = res?.data?.find(
          (item) => item?.id === messageData?.template
        );
        setMessageData((prev) => ({
          //   template: null,
          ...prev,
          message: msg?.message,
        }));
      } else {
        toast.error(res.message);
      }
    });
  };

  const onSubmit = async () => {
    setSending(true);

    const body = {
      id: messageData?.template,
      email: data?.user_data?.email,
      // email: "cord4.dhaval@gmail.com",
      edition: data?.edition,
    };

    // console.log(body, data, "body");

    fetchData(`api/dynamic-templates/send-mail/`, "post", body).then((res) => {
      setSending(false);
      if (res.success) {
        onHide();
        toast.success("Email Sent Successfully");
      } else {
        toast.error(res.message);
      }
    });
  };

  return (
    <Modal show={show} onHide={onHide} size="md" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="fw-bold fs-6 mb-n3">
            Send Email Template <span className="text-primary"></span>
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row g-9">
          <div className="col-12 mb-4">
            <div className="bg-light-secondary p-3 rounded">
              <div className="mb-2">
                <strong className="me-2">Name:</strong>
                {data?.user_data?.first_name +
                  " " +
                  data?.user_data?.last_name || "-"}
              </div>
              <div>
                <strong className="me-2">Email:</strong>
                {data?.user_data?.email || "-"}
              </div>
            </div>
          </div>

          <div className="col-12 mb-4">
            <label className="required fw-semibold fs-6 mb-2">
              Pick Template
            </label>
            <Select
              placeholder="Select template"
              value={messageData.template}
              onChange={(e) => setMessageDataValue("template", e)}
              style={{ width: "100%" }}
              dropdownStyle={{ zIndex: 1111 }}
              options={templateList}
              size="large"
            />
          </div>

          <div className="col-12">
            <label className="required fw-semibold fs-6 mb-2">
              Review Message
            </label>
            <textarea
              className="form-control"
              rows={6}
              placeholder="Type here..."
              value={messageData.message}
              onChange={(e) => setMessageDataValue("message", e.target.value)}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-light fs-7" onClick={onHide}>
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary fs-7"
          onClick={onSubmit}
          disabled={sending}
        >
          Send Now
          {sending && (
            <span className="spinner-border spinner-border-sm align-middle ms-2" />
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
