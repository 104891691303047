import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { fetchData } from "../../../../../config/service";
import { formatNumber, LIMITS } from "../../../../../config";
import { Loader, Pagination } from "../../../../../components";
import dayjs from "dayjs";

const tab4Data = [
  {
    user: {
      name: "Khushbu Khetan",
      avatar: "/media/img.jpg",
    },
    paidOn: "-",
    edition: "-",
    totalPayments: "-",
    dueDate: "-",
    amountPaid: "-",
    comments: "-",
  },
  // Add more row objects as needed
];

const SecondPayments = ({ editionData, setCurrentCount, setAvgPrice }) => {
  const [activePage, setActivePage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [limit, setLimit] = useState(LIMITS[0]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData(editionData?.id);
  }, [editionData?.id]);

  const getData = (id) => {
    let param = "";

    let offset = (activePage - 1) * limit;
    param += `?limit=${limit}&offset=${offset}&edition_id=${id}`;

    setLoading(true);
    fetchData(`api/editions/statistic/second-payments/${param}`, "get").then(
      (res) => {
        setLoading(false);
        if (res.success) {
          console.log(res, "res");
          setCurrentCount(res.current_count);
          setAvgPrice(res.average_price);
          setData(res?.data);
          setTotalRecords(res.count ? res.count - 1 : 0);
        } else {
          setData([]);
          toast.error(res.message);
        }
      }
    );
  };

  return (
    <div>
      <div className="table-responsive tbl-sales">
        {loading ? (
          <div className="table-responsive">
            <Loader cols={7} width={250} />
          </div>
        ) : (
          <div>
            {data?.length > 0 ? (
              <>
                <div className="table-responsive tbl-sales">
                  {/*begin::Table*/}

                  <table
                    className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 border-top-d"
                    id
                  >
                    <thead>
                      <tr className="fw-bolder text-gray-900 bg-light">
                        <th className="min-w-100px">User</th>
                        <th className="min-w-90px">Paid On</th>
                        <th className="min-w-90px">Edition</th>
                        <th className="min-w-150px">Total Payments</th>
                        <th className="min-w-100px">Due Date</th>
                        <th className="min-w-100px">Amount Paid</th>
                        <th className="min-w-100px">Comments</th>
                      </tr>
                    </thead>
                    <tbody className=" fw-semibold fs-7">
                      {data.map((row, index) => {
                        return (
                          <tr key={index}>
                            <td className="bdr-left-user">
                              <div className="d-flex align-items-center">
                                <div className="symbol symbol-30px overflow-hidden me-3">
                                  <img
                                    src={
                                      row.user.photo ||
                                      "/media/default-avatar.png"
                                    }
                                    alt={row.user.name || "Unknown User"}
                                  />
                                </div>
                                <div className="d-flex flex-column text-start">
                                  <a
                                    href="#"
                                    className="text-primary mb-1 fw-bolder fs-6 min-w-150px"
                                  >
                                    {row.user.first_name +
                                      " " +
                                      row.user.last_name || "Unknown User"}
                                  </a>
                                </div>
                              </div>
                            </td>
                            <td>
                              {dayjs(row.created_at).format("DD MMM YYYY")}
                            </td>
                            <td>
                              <div>
                                {row.edition?.name || "Unknown Edition"}
                              </div>
                              <small>
                                {dayjs(row.edition?.start_date).format(
                                  "DD MMM YYYY"
                                )}{" "}
                                -{" "}
                                {dayjs(row.edition?.end_date).format(
                                  "DD MMM YYYY"
                                )}
                              </small>
                            </td>
                            <td>{formatNumber(row.amount)}</td>
                            <td>-</td>{" "}
                            {/* Due Date - If available in API, replace with correct field */}
                            <td>{formatNumber(row.amount)}</td>
                            <td>{row.comment || "No Comments"}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  {/*end::Table*/}
                </div>

                <div className="separator separator-dashed border-gray-200" />
                <Pagination
                  setLimit={setLimit}
                  limit={limit}
                  data={data}
                  activePage={activePage}
                  totalRecords={totalRecords}
                  onChange={(pageNumber) => {
                    setLoading(true);
                    setActivePage(pageNumber);
                  }}
                />
              </>
            ) : (
              <div className="row g-5 mb-0 gx-md-10">
                <div className="col-md-12 text-center">
                  <div className="mt-0">
                    <img
                      src="/media/no-data-found.png"
                      className="mw-25"
                      alt="No Data Found"
                    />
                  </div>
                  <h1 className="mt-4">No Data Found</h1>
                </div>
              </div>
            )}
          </div>
        )}

        {/*begin::Table*/}
      </div>
    </div>
  );
};

export default SecondPayments;
