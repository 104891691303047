import { Divider } from "antd";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { fetchData } from "../../../../config/service";
import toast from "react-hot-toast";
import { validateFields } from "../../../../components/validation";

export default function MaxSpots({ show, onHide, data }) {
  const [sending, setSending] = useState(false);
  const [maxSpotsData, setGetMaxSpotsData] = useState([]);
  const [maxSpotsFormData, setMaxSpotsFormData] = useState({
    hotel_inventory: null,
    flights: null,
    experiences: null,
    comment: "",
  });
  const [keys, setKeys] = useState(false);

  useEffect(() => {
    if (data?.id) {
      getMaxSpots();
    } else {
      console.error("Invalid data passed");
    }
  }, [data?.id]);

  const getMaxSpots = () => {
    fetchData(`api/editions/edition-max-spot-details/?edition_id=${data?.id}`)
      .then((res) => {
        if (res.data?.length) {
          setGetMaxSpotsData(res.data[0]); // Assuming first object is needed
          setMaxSpotsFormData({
            hotel_inventory: res.data[0].hotel_inventory || null,
            flights: res.data[0].flights || null,
            experiences: res.data[0].experiences || null,
            comment: res.data[0].comment || "",
          });
          setKeys(true);
        } else {
          setGetMaxSpotsData({});
          setMaxSpotsFormData({
            hotel_inventory: "",
            flights: "",
            experiences: "",
            comment: "",
          });
          setKeys(false);
        }
      })
      .catch(() => toast.error("Failed to fetch data"));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMaxSpotsFormData({ ...maxSpotsFormData, [name]: value });
  };

  const validationRules = {
    hotel_inventory: {
      required: true,
    },
    flights: {
      required: true,
    },
    experiences: {
      required: true,
    },
    comment: {
      required: true,
    },
  };

  const onSubmitMaxSpots = () => {
    const { isValid } = validateFields(maxSpotsFormData, validationRules);
    if (!isValid) {
      return;
    }

    const body = {
      edition: data.id,
      hotel_inventory: maxSpotsFormData.hotel_inventory,
      flights: maxSpotsFormData.flights,
      experiences: maxSpotsFormData.experiences,
      comment: maxSpotsFormData.comment,
    };
    setSending(true);
    console.log("body", body);

    let api = keys
      ? `api/editions/edition-max-spot-details-data/${maxSpotsData?.id}/`
      : "api/editions/edition-max-spot-details/";
    let method = keys ? "put" : "post";

    fetchData(api, method, body, "", true).then((res) => {
      setSending(false);
      toast.remove();
      if (res) {
        toast.success(res.message ? res.message : "add successfully");
        getMaxSpots();
        onHide();
      } else {
        toast.error(res.message);
      }
    });
  };

  return (
    // <Modal centered show={show} onHide={onHide} size="lg">
    //   <Modal.Header>
    //     <h3 className="modal-title">Max Spots (Editions)</h3>
    //     {/*begin::Close*/}
    //     <div>
    //       {/*begin::Destination*/}
    //       <div className="d-flex align-items-center fw-bold ">
    //         <div className="min-w-200px">
    //           <div className="form-check">
    //             <input
    //               className="form-check-input"
    //               type="checkbox"
    //               defaultValue
    //               id="flexCheckDefault"
    //             />
    //             <label
    //               className="form-check-label text-gray-900 fw-bold"
    //               htmlFor="flexCheckDefault"
    //             >
    //               Show Old Projects
    //             </label>
    //           </div>
    //         </div>
    //         {/*begin::Label*/}
    //         <div className="text-gray-500 fs-7 me-2 min-w-90px ">
    //           Select Project
    //         </div>
    //         {/*end::Label*/}
    //         {/*begin::Select*/}
    //         <select className="form-select form-select-transparent text-graY-800 fs-base lh-1 fw-bold py-0 ps-3 ">
    //           <option value="Show All" selected>
    //             Show All
    //           </option>
    //           <option value="a">BHX</option>
    //         </select>
    //         {/*end::Select*/}
    //         <div
    //           className="btn btn-icon btn-sm btn-active-light-primary ms-2 w-70px"
    //           onClick={onHide}
    //         >
    //           <i className="ki-outline ki-cross fs-1" />
    //         </div>
    //       </div>
    //       {/*end::Destination*/}
    //     </div>
    //   </Modal.Header>
    //   <div>
    //     <div className="modal-body pb-7 pt-0 ps-lg-7 pe-0">
    //       <div className="scroll-y pe-7 ps-3">
    //         <div className="row g-5 mb-5 align-items-center">
    //           <div className="col-md-12">
    //             <div className="main-box">
    //               {/*begin::Table container*/}
    //               <div className="table-responsive tbl-sales">
    //                 {/*begin::Table*/}
    //                 <table
    //                   className="table align-middle table-row-dashed  table-row-gray-300 gy-4 gs-4 border-top-d"
    //                   id
    //                 >
    //                   <thead>
    //                     <tr className="fw-bolder text-gray-900 bg-white">
    //                       <th className="min-w-100px p-0" />
    //                       <th className="min-w-100px p-0" />
    //                       <th className="min-w-100px p-0" />
    //                       <th className="min-w-100px p-0" />
    //                     </tr>
    //                   </thead>
    //                   <tbody className=" fw-semibold fs-7">
    //                     <tr className="bg-light">
    //                       <td className="bdr-left-user ">
    //                         <div className="d-flex align-items-center">
    //                           <div className="d-flex flex-column text-start">
    //                             <a
    //                               href
    //                               className="text-primary mb-1 fw-bolder fs-6 min-w-200px"
    //                             >
    //                               BHX Prague &amp; Berlin 4
    //                             </a>
    //                             <div className="text-gray-800">
    //                               <span>M:13</span>
    //                               <span>F:9</span> <span>O:0</span> /{" "}
    //                               <span>Ratio: 1.4</span>
    //                             </div>
    //                             <div className="text-gray-500">
    //                               Apr 19, 2024
    //                             </div>
    //                           </div>
    //                         </div>
    //                       </td>
    //                       <td>
    //                         <div className="text-gray-800 fw-bold mt-1">
    //                           Max Spots
    //                         </div>
    //                         <div className="position-relative w-150px">
    //                           <input
    //                             type="text"
    //                             className="form-control form-control-sm border-0 w-150px"
    //                             defaultValue={20}
    //                           />
    //                           <i
    //                             className="ki-outline ki-information-3 fs-2 text-dark position-absolute top-50 translate-middle-y me-5 end-0"
    //                             data-bs-toggle="tooltip"
    //                             title="Last Updated:"
    //                           />
    //                         </div>
    //                       </td>
    //                       <td>
    //                         <div>
    //                           <span className="badge badge-outline badge-primary mt-0">
    //                             Overspill:
    //                             <span className="fw-bolder text-dark ms-1">
    //                               20/20
    //                             </span>
    //                           </span>
    //                         </div>
    //                       </td>
    //                       <td>
    //                         <span className="btn btn-primary btn-sm fs-7 fw-bold">
    //                           <i className="ki-outline ki-notepad-edit fs-2" />{" "}
    //                           Update
    //                         </span>
    //                       </td>
    //                     </tr>
    //                     <tr className="bg-light">
    //                       <td className="bdr-left-user ">
    //                         <div className="d-flex align-items-center">
    //                           <div className="d-flex flex-column text-start">
    //                             <a
    //                               href
    //                               className="text-primary mb-1 fw-bolder fs-6 min-w-200px"
    //                             >
    //                               BHX Prague &amp; Berlin 4
    //                             </a>
    //                             <div className="text-gray-800">
    //                               <span>M:13</span>
    //                               <span>F:9</span> <span>O:0</span> /{" "}
    //                               <span>Ratio: 1.4</span>
    //                             </div>
    //                             <div className="text-gray-500">
    //                               Apr 19, 2024
    //                             </div>
    //                           </div>
    //                         </div>
    //                       </td>
    //                       <td>
    //                         <div className="text-gray-800 fw-bold mt-1">
    //                           Max Spots
    //                         </div>
    //                         <div className="position-relative w-150px">
    //                           <input
    //                             type="text"
    //                             className="form-control form-control-sm border-0 w-150px"
    //                             defaultValue={20}
    //                           />
    //                           <i
    //                             className="ki-outline ki-information-3 fs-2 text-dark position-absolute top-50 translate-middle-y me-5 end-0"
    //                             data-bs-toggle="tooltip"
    //                             title="Last Updated:"
    //                           />
    //                         </div>
    //                       </td>
    //                       <td>
    //                         <div>
    //                           <span className="badge badge-outline badge-primary mt-0">
    //                             Overspill:
    //                             <span className="fw-bolder text-dark ms-1">
    //                               20/20
    //                             </span>
    //                           </span>
    //                         </div>
    //                       </td>
    //                       <td>
    //                         <span className="btn btn-primary btn-sm fs-7 fw-bold">
    //                           <i className="ki-outline ki-notepad-edit fs-2" />{" "}
    //                           Update
    //                         </span>
    //                       </td>
    //                     </tr>
    //                     <tr className="bg-light">
    //                       <td className="bdr-left-user ">
    //                         <div className="d-flex align-items-center">
    //                           <div className="d-flex flex-column text-start">
    //                             <a
    //                               href
    //                               className="text-primary mb-1 fw-bolder fs-6 min-w-200px"
    //                             >
    //                               BHX Prague &amp; Berlin 4
    //                             </a>
    //                             <div className="text-gray-800">
    //                               <span>M:13</span>
    //                               <span>F:9</span> <span>O:0</span> /{" "}
    //                               <span>Ratio: 1.4</span>
    //                             </div>
    //                             <div className="text-gray-500">
    //                               Apr 19, 2024
    //                             </div>
    //                           </div>
    //                         </div>
    //                       </td>
    //                       <td>
    //                         <div className="text-gray-800 fw-bold mt-1">
    //                           Max Spots
    //                         </div>
    //                         <div className="position-relative w-150px">
    //                           <input
    //                             type="text"
    //                             className="form-control form-control-sm border-0 w-150px"
    //                             defaultValue={20}
    //                           />
    //                           <i
    //                             className="ki-outline ki-information-3 fs-2 text-dark position-absolute top-50 translate-middle-y me-5 end-0"
    //                             data-bs-toggle="tooltip"
    //                             title="Last Updated:"
    //                           />
    //                         </div>
    //                       </td>
    //                       <td>
    //                         <div>
    //                           <span className="badge badge-outline badge-primary mt-0">
    //                             Overspill:
    //                             <span className="fw-bolder text-dark ms-1">
    //                               20/20
    //                             </span>
    //                           </span>
    //                         </div>
    //                       </td>
    //                       <td>
    //                         <span className="btn btn-primary btn-sm fs-7 fw-bold">
    //                           <i className="ki-outline ki-notepad-edit fs-2" />{" "}
    //                           Update
    //                         </span>
    //                       </td>
    //                     </tr>
    //                   </tbody>
    //                 </table>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="modal-footer">
    //       {/*begin::Pagination*/}
    //       <div className="d-flex flex-stack flex-wrap pt-0 w-100">
    //         <div className="fs-6 fw-semibold text-gray-700">
    //           Showing 1 to 10 of 50 entries
    //         </div>
    //         {/*begin::Pages*/}
    //         <ul className="pagination">
    //           <li className="page-item previous">
    //             <a href="#" className="page-link">
    //               <i className="previous" />
    //             </a>
    //           </li>
    //           <li className="page-item active">
    //             <a href="#" className="page-link">
    //               1
    //             </a>
    //           </li>
    //           <li className="page-item">
    //             <a href="#" className="page-link">
    //               2
    //             </a>
    //           </li>
    //           <li className="page-item">
    //             <a href="#" className="page-link">
    //               3
    //             </a>
    //           </li>
    //           <li className="page-item">
    //             <a href="#" className="page-link">
    //               4
    //             </a>
    //           </li>
    //           <li className="page-item">
    //             <a href="#" className="page-link">
    //               5
    //             </a>
    //           </li>
    //           <li className="page-item">
    //             <a href="#" className="page-link">
    //               6
    //             </a>
    //           </li>
    //           <li className="page-item next">
    //             <a href="#" className="page-link">
    //               <i className="next" />
    //             </a>
    //           </li>
    //         </ul>
    //         {/*end::Pages*/}
    //       </div>
    //       {/*end::Pagination*/}
    //       {/* <button type="button" class="btn btn-light fs-7" data-bs-dismiss="modal">Close</button> */}
    //       {/* <button type="button" class="btn btn-primary fs-7">Submit</button> */}
    //     </div>
    //   </div>
    // </Modal>

    <Modal centered show={show} onHide={onHide} size="md">
      <Modal.Header closeButton>
        <div className="d-flex flex-column">
          <h3 className="modal-title pb-3">
            Max Spots for
            {/* Bucketlist Philippines */}
            <span> {data?.ip}</span>
          </h3>
          {/* <p className="m-0 fs-7" style={{color:"#1515eb"}}>Last Update on : November 11, 2024</p> */}
        </div>
        {/*begin::Close*/}
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="row g-5 mb-2 align-items-center">
            <h3 className="modal-title pb-3" style={{ fontSize: "14px" }}>
              Edit Max Inventory (pax)
            </h3>
          </div>
          <div>
            <ul className="p-0">
              <li style={{ listStyle: "none" }} className="pe-13 pb-3">
                <div className="d-flex justify-content-between align-items-center">
                  <label htmlFor="max-spots-1">Hotel Inventory</label>
                  <input
                    name="hotel_inventory"
                    type="number"
                    value={maxSpotsFormData.hotel_inventory}
                    className="form-control form-control-sm"
                    id="max-spots-1"
                    style={{ width: "90px" }}
                    // defaultValue={20}
                    onChange={handleChange}
                  />
                </div>
              </li>
              <li style={{ listStyle: "none" }} className="pe-13 pb-3">
                <div className="d-flex justify-content-between align-items-center">
                  <label htmlFor="max-spots-1">Flights</label>
                  <input
                    type="number"
                    name="flights"
                    value={maxSpotsFormData.flights}
                    className="form-control form-control-sm"
                    id="max-spots-1"
                    style={{ width: "90px" }}
                    // defaultValue={20}
                    onChange={handleChange}
                  />
                </div>
              </li>
              <li style={{ listStyle: "none" }} className="pe-13">
                <div className="d-flex justify-content-between align-items-center">
                  <label htmlFor="max-spots-1">Experiences</label>
                  <input
                    type="number"
                    name="experiences"
                    value={maxSpotsFormData.experiences}
                    className="form-control form-control-sm"
                    id="max-spots-1"
                    style={{ width: "90px" }}
                    // defaultValue={20}
                    onChange={handleChange}
                  />
                </div>
              </li>
            </ul>
          </div>
          <div>
            <label className="my-3">
              <span
                className="required fw-semibold fs-6 "
                style={{ color: "red" }}
              >
                {" "}
                Add comment
              </span>
            </label>
            <textarea
              className="form-control form-control-sm p-3 mb-5 bg-blue "
              rows="4"
              name="comment"
              value={maxSpotsFormData.comment}
              placeholder="Why is this changing?"
              style={{ borderRadius: "5px" }}
              onChange={handleChange}
            ></textarea>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-light-danger fs-7"
          onClick={onHide}
        >
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary fs-7"
          onClick={() => {
            onSubmitMaxSpots();
          }}
          disabled={sending}
        >
          Submit
          {sending && (
            <span className="spinner-border spinner-border-sm align-middle ms-2" />
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
