import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import { fetchData } from "../config/service";
import { useSelector } from "react-redux";
import { Select } from "antd";

export default function MakeCallModal({ show, onHide, data }) {
  const user = useSelector((state) => state.user);

  const [sending, setSending] = useState(false);
  const [numbers, setNumbers] = useState({
    call_type: null,
    fromNumber: "08069457959",
    toNumber: "",
  });

  const extractLastTenDigits = (phone) => {
    if (!phone) return "";
    return phone.replace(/[^\d]/g, "").slice(-10);
  };

  useEffect(() => {
    if (data) {
      setNumbers({
        fromNumber: extractLastTenDigits(user?.phone || "08069457959"),
        toNumber: extractLastTenDigits(data?.to_number),
      });
    }
  }, [data]);

  const handleChange = (field, value) => {
    setNumbers((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const validateNumbers = () => {
    if (numbers.fromNumber.length !== 10) {
      toast.error("From Number must be exactly 10 digits.");
      return false;
    }
    if (numbers.toNumber.length !== 10) {
      toast.error("To Number must be exactly 10 digits.");
      return false;
    }
    if (
      numbers.call_type === null ||
      numbers.call_type === "" ||
      numbers.call_type === undefined
    ) {
      toast.error("Please select call type.");
      return false;
    }
    return true;
  };

  const handleMakeCall = async () => {
    if (!validateNumbers()) {
      return;
    }

    const requestData = {
      call_type: numbers.call_type,
      from_number: extractLastTenDigits(numbers.fromNumber),
      to_number: extractLastTenDigits(numbers.toNumber),
    };

    // console.log(requestData, "requestData");
    setSending(true);
    fetchData(`api/call/records/`, "post", requestData)
      .then((res) => {
        setSending(false);
        if (res.success) {
          onHide();
          toast.success("Call initiated successfully");
        } else {
          toast.error(res.message);
        }
      })
      .catch(() => {
        setSending(false);
        toast.error("An error occurred. Please try again.");
      });
  };

  return (
    <Modal show={show} onHide={onHide} size="md" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <p className="fw-bold fs-6 mb-n3">Make a Call</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row g-3">
          <div className="col-12">
            <label>
              <span className="required fw-semibold fs-5">Call Type</span>
            </label>
            <Select
              value={numbers.call_type}
              dropdownStyle={{ zIndex: 1111 }}
              onChange={(value) => handleChange("call_type", value)}
              placeholder="Select Call Type"
              style={{ width: "100%", marginTop: "10px" }}
              size="large"
              options={[
                {
                  label: "Curation Call",
                  value: 1,
                },
                {
                  label: "Visa Call",
                  value: 2,
                },
                {
                  label: "Support Call",
                  value: 3,
                },
                {
                  label: "Onboarding Call",
                  value: 4,
                },
                {
                  label: "ES Call",
                  value: 5,
                },
              ]}
            />
          </div>
          <div className="col-12 mt-5">
            <label>
              <span className="required fw-semibold fs-5">From Number</span>
            </label>
            <input
              type="text"
              className="form-control"
              value={numbers.fromNumber}
              onChange={(e) => handleChange("fromNumber", e.target.value)}
              placeholder="Enter from number"
            />
          </div>
          <div className="col-12 mt-5">
            <label>
              <span className="required fw-semibold fs-5">To Number</span>
            </label>
            <input
              type="text"
              className="max-w-200px form-control"
              value={numbers.toNumber}
              onChange={(e) => handleChange("toNumber", e.target.value)}
              placeholder="Enter to number"
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-light-danger fs-7"
          onClick={onHide}
        >
          Close
        </button>
        <button
          type="button"
          className="btn btn-primary fs-7"
          onClick={handleMakeCall}
          disabled={sending}
        >
          Make Call
          {sending && (
            <span className="spinner-border spinner-border-sm align-middle ms-2" />
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
